import { forwardRef, useEffect, useRef, useState } from "react"
import {
  IonLabel,
  IonSelect,
  IonSelectOption,
  SelectChangeEventDetail,
} from "@ionic/react"
import { BodyJointEnum } from "../../../../generated/graphql"
import { formatBodyPart } from "../../../../utils/format"
import { IonSelectCustomEvent } from "@ionic/core"
import { MOVEMENT, NAME_SPACES } from "../../../../locales/constants"
import { useTranslation } from "react-i18next"
import { MovementWizardQuestion } from "../MovementWizardQuestion"
import {
  MovementWizardSlide,
  MovementWizardSlideProps,
} from "../MovementWizardSlide"

const PhysicalDiscomfortsSelect = forwardRef(
  ({ isOpen, onSelect }: any, ref: any) => {
    const { t } = useTranslation(NAME_SPACES.MOVEMENT)
    const TEXT = t(MOVEMENT.WIZARD, { returnObjects: true })

    const areasOfDiscomfort = [
      BodyJointEnum.CervicalSpine,
      BodyJointEnum.ShoulderComplex,
      BodyJointEnum.ThoracicSpine,
      BodyJointEnum.LumbarSpine,
      BodyJointEnum.Hips,
      BodyJointEnum.Knees,
      BodyJointEnum.Ankles,
    ]

    const options = areasOfDiscomfort.map((key) => ({
      value: key,
      label: formatBodyPart(key),
    }))

    const [selectedOptions, setSelectedOptions] = useState<string[]>([])

    const handleChange = (
      e: IonSelectCustomEvent<SelectChangeEventDetail<BodyJointEnum[]>>
    ) => {
      const { value } = e.detail

      onSelect?.(value)

      setSelectedOptions(value)
    }

    useEffect(() => {
      console.debug("selectedOptions", selectedOptions)
    }, [selectedOptions])

    useEffect(() => {
      if (isOpen) {
        ref.current?.open()
      }
    }, [isOpen])

    return (
      <div className="flex flex-col items-center justify-center w-full h-full px-4">
        <IonSelect
          className="w-full rounded-lg bg-neutral-100/50"
          color="primary"
          multiple={true}
          ref={ref}
          onIonChange={handleChange}
          style={{
            "--placeholder-color": "var(--ion-color-neutral-shade)",
            "--placeholder-opacity": "1",
          }}
          placeholder={
            TEXT.QUESTIONS[MovementWizardQuestion.PhyiscalDiscomforts].SELECT
              .PLACEHOLDER
          }
          title={
            TEXT.QUESTIONS[MovementWizardQuestion.PhyiscalDiscomforts].SELECT
              .TITLE
          }
        >
          <IonLabel>
            {
              TEXT.QUESTIONS[MovementWizardQuestion.PhyiscalDiscomforts].SELECT
                .TITLE
            }
          </IonLabel>
          {options.map((option, index) => (
            <IonSelectOption value={option.value} key={index}>
              {option.label}
            </IonSelectOption>
          ))}
        </IonSelect>
        {/* </IonItem> */}
      </div>
    )
  }
)
export const PhysicalDiscomfortsQuestion: React.FC<
  Partial<MovementWizardSlideProps<BodyJointEnum[] | undefined>>
> = ({ onSubmit: onClick, onSelect }) => {
  const ref = useRef<HTMLIonSelectElement>(null)

  return (
    <MovementWizardSlide
      question={MovementWizardQuestion.PhyiscalDiscomforts}
      onSubmit={onClick}
    >
      <PhysicalDiscomfortsSelect ref={ref} onSelect={onSelect} />
    </MovementWizardSlide>
  )
}

import classNames from "clsx"
import get from "lodash/get"
import isNil from "lodash/isNil"
import { useTranslation } from "react-i18next"
import {
  IoBarbellOutline,
  IoFootballOutline,
  IoSyncOutline,
  IoTimerOutline,
} from "react-icons/io5"
import { IconBaseProps, IconType } from "react-icons/lib"
import {
  MdDownhillSkiing,
  MdOutlineRowing,
  MdOutlineSportsRugby,
} from "react-icons/md"
import {
  RiArticleLine,
  RiBoxingLine,
  RiDropFill,
  RiEarthFill,
  RiFireFill,
  RiFireLine,
  RiFocus2Line,
  RiGlobeLine,
  RiHeartPulseLine,
  RiInformationLine,
  RiMentalHealthLine,
  RiMoreFill,
  RiPinDistanceLine,
  RiPingPongLine,
  RiRunLine,
  RiSeedlingLine,
  RiTimeLine,
  RiVideoChatLine,
  RiWindyLine,
} from "react-icons/ri"

import {
  TbBallBasketball,
  TbBallTennis,
  TbBallVolleyball,
  TbBike,
  TbGolf,
  TbKarate,
  TbMountain,
  TbRun,
  TbSkateboard,
  TbSwimming,
  TbWalk,
  TbWind,
  TbYinYang,
  TbYoga,
} from "react-icons/tb"

import { FaHorseHead } from "react-icons/fa"

import {
  AssessmentRatingOfPerceivedExertion,
  Maybe,
  MovementModality,
  MovementStyle,
  SqualoAdventureElement,
} from "../../generated/graphql"
import { MOVEMENT, NAME_SPACES } from "../../locales/constants"
import { getModalityTextColor } from "../../utils/movementUtils"
import { YinBehaleIcon } from "../BehaleIcon"
import { getElementContrastColor } from "../../utils/elementUtils"

export const TimeIcon = RiTimeLine
export const DurationIcon = IoTimerOutline
export const RecurrenceIcon = IoSyncOutline
export const TargetBodyPartIcon = RiFocus2Line
export const LanguageIcon = RiGlobeLine
export const IntensityIcon = RiFireLine
export const DistanceIcon = RiPinDistanceLine
export const EquipmentIcon = IoBarbellOutline

export const ElementIcon = ({
  element,
  className,
  color,
  contrast = false,
}: {
  element: SqualoAdventureElement
  className?: string
  color?: string
  contrast?: boolean
}) => {
  if (contrast) {
    color = getElementContrastColor(element)
  } else {
    color = color || element
  }

  let Icon: IconType

  switch (element) {
    case SqualoAdventureElement.Air:
      Icon = RiWindyLine
      break

    case SqualoAdventureElement.Earth:
      Icon = RiEarthFill
      break

    case SqualoAdventureElement.Fire:
      Icon = RiFireFill
      break

    case SqualoAdventureElement.Water:
      Icon = RiDropFill
      break

    default:
      Icon = YinBehaleIcon
      break
  }

  return <Icon className={classNames(className, `text-${color}`)} />
}

export const styleIcons = {
  [MovementStyle.HorseRiding]: FaHorseHead,
  [MovementStyle.Walking]: TbWalk,
  [MovementStyle.Swimming]: TbSwimming,
  [MovementStyle.Cycling]: TbBike,
  [MovementStyle.Skateboarding]: TbSkateboard,
  [MovementStyle.Basketball]: TbBallBasketball,
  [MovementStyle.Handball]: IoFootballOutline,
  [MovementStyle.Volleyball]: TbBallVolleyball,
  [MovementStyle.Golf]: TbGolf,
  [MovementStyle.Football]: IoFootballOutline,
  [MovementStyle.Tennis]: TbBallTennis,
  [MovementStyle.TableTennis]: RiPingPongLine,
  [MovementStyle.Rugby]: MdOutlineSportsRugby,
  [MovementStyle.Boxing]: RiBoxingLine,
  [MovementStyle.Climbing]: TbMountain,
  [MovementStyle.Running]: TbRun,
  [MovementStyle.Rowing]: MdOutlineRowing,
  // martial arts
  [MovementStyle.Bjj]: TbKarate,
  [MovementStyle.Karate]: TbKarate,
  [MovementStyle.Judo]: TbKarate,
  // mindful
  [MovementStyle.TaiChi]: TbYinYang,
  [MovementStyle.FunctionalStretching]: TbYoga,
  [MovementStyle.Yoga]: TbYoga,
  [MovementStyle.Skiing]: MdDownhillSkiing,
  // breathing
  [MovementStyle.Breathwork]: TbWind,
  // knowledge
  [MovementStyle.Article]: RiArticleLine,
  [MovementStyle.Consultation]: RiVideoChatLine,
}

export const getStyleIcon = (style: MovementStyle | null | undefined) => {
  return get(styleIcons, style || "")
}

export const CardioIcon = RiHeartPulseLine
export const MobilityIcon = RiSeedlingLine
export const StrengthIcon = IoBarbellOutline
export const BreathingIcon = RiMentalHealthLine
export const SportIcon = RiRunLine
export const KnowledgeIcon = RiInformationLine
export const OtherIcon = RiMoreFill

export const modalityIcons: Record<MovementModality, IconType> = {
  [MovementModality.Cardio]: CardioIcon,
  [MovementModality.Mobility]: MobilityIcon,
  [MovementModality.Strength]: StrengthIcon,
  [MovementModality.Breathing]: BreathingIcon,
  [MovementModality.Sport]: SportIcon,
  [MovementModality.Knowledge]: KnowledgeIcon,
  [MovementModality.Other]: OtherIcon,
}

export const getModalityIcon = (
  modality: MovementModality | null | undefined
) => {
  return get(modalityIcons, modality || "")
}

export const getIcon = (element: {
  movementStyle?: Maybe<MovementStyle>
  movementModality?: Maybe<MovementModality>
}): IconType => {
  return (
    getStyleIcon(element.movementStyle) ||
    getModalityIcon(element.movementModality) ||
    SportIcon
  )
}

export const MovementIcon: React.FC<
  {
    element: {
      movementStyle?: Maybe<MovementStyle>
      movementModality?: Maybe<MovementModality>
    }
    bgColor?: "modality" | "transparent"
  } & IconBaseProps
> = ({ element, bgColor = "modality", className, ...props }) => {
  const Icon = getIcon(element)

  const textColor = getModalityTextColor(element.movementModality)

  return (
    <Icon
      className={classNames(
        "rounded-sm",
        bgColor === "modality" && `bg-${element.movementModality} ${textColor}`,
        bgColor === "transparent" && "bg-transparent",
        className
      )}
      {...props}
    />
  )
}

export const RPEIcon: React.FC<
  {
    rpe: AssessmentRatingOfPerceivedExertion | undefined | null
  } & IconBaseProps
> = ({ rpe, ...props }) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)

  if (isNil(rpe)) {
    return <IntensityIcon {...props} />
  }

  const TEXT = t(MOVEMENT.RPE, { returnObjects: true })

  return <span className="text-xl">{get(TEXT, `${rpe}.EMOJI`, "")}</span>
}

import clsx from "clsx"
import { RiChatSmile2Line, RiLoader4Line } from "react-icons/ri"
import { useOlibaContext } from "../../contexts/OlibaContext"
import PingContainer from "../Core/Ping"
import Button from "../Forms/Button"

export const OlibaPreview = () => {
  const { openOliba: openOliba, isLoaded, hasNewMessages } = useOlibaContext()

  return (
    <>
      <PingContainer
        className={"top-1 right-1"}
        showPing={hasNewMessages}
        color={"STRENGTH"}
      >
        <Button
          icon={isLoaded ? RiChatSmile2Line : RiLoader4Line}
          iconClassName={clsx(!isLoaded && "animate-spin")}
          onClick={openOliba}
          iconSlot="icon-only"
          fill="clear"
          color="white"
          paddingWidth="thin"
        />
      </PingContainer>

      {/* {hasNewMessages && (
        <div className="w-full h-full max-w-md pr-1 text-right truncate">
          <span className={clsx("text-lg italic font-medium w-full")}>
            &ldquo;{latestMessage?.body}&rdquo;
          </span>
        </div>
      )} */}
    </>
  )
}

import { useLazyQuery, useMutation } from "@apollo/client"
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonRouter,
} from "@ionic/react"
import classNames from "clsx"
import {
  calendarOutline,
  checkmarkDoneOutline,
  chevronBackOutline,
  shareSocialOutline,
  shuffleOutline,
} from "ionicons/icons"
import { get, isNil } from "lodash"
import React, { useEffect, useState, useCallback } from "react"
import { useTranslation } from "react-i18next"

import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import {
  AbandonMovementSessionDocument,
  GetFollowAlongQuery,
  GetMovementAgendaItemQuery,
  GetMovementSessionDocument,
  GetMovementSessionQuery,
  GetMovementSessionQueryVariables,
  SessionExpectedDuration,
  SqualoFollowAlongVideo,
  StartMovementSessionDocument,
} from "../../generated/graphql"
import useToast from "../../hooks/useToast"
import { modalityLabels } from "../../labels"
import { MOVEMENT, NAME_SPACES } from "../../locales/constants"
import Button from "../Forms/Button"
import { VideoDetailsCarousel } from "../Mico/VideoDetailsCarousel"
import { VideoFeedbackModal } from "../Mico/VideoFeedbackModal"
import { ModalityIcon } from "./Modality"
import SessionTitle from "./SessionTitle"
import { Share } from "@capacitor/share"
import { buildURL } from "../../utils"
import Page from "../Core/Page"
import Content from "../Core/Content"
import { MovementAgendaItemFormModal } from "./Modals/MovementAgendaItemFormModal"
import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import VideoPlayer from "../Mico/VideoPlayer"
import PingContainer from "../Core/Ping"
import { AdventureTemplateProductCarousel } from "../Adventure/AdventureTemplateProductCard"

export interface SessionPageProps {
  agendaItem?: GetMovementAgendaItemQuery["movementAgendaItem"]
  followAlong?: GetFollowAlongQuery["getFollowAlong"]
  reportedTimeAvailable?: SessionExpectedDuration
  onCompleted?: () => void
}

const ShuffleVideoButton: React.FC<{
  label: string
  onClick: () => void
}> = ({ onClick, label }) => {
  return (
    <Button
      fill="outline"
      iconSlot="start"
      label={label}
      className="w-full"
      onClick={onClick}
      icon={shuffleOutline}
    />
  )
}

const SessionPage: React.FC<SessionPageProps> = ({
  agendaItem,
  followAlong,
  reportedTimeAvailable,
  onCompleted,
}) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.CURRENT_MOVEMENT_SESSION, {
    returnObjects: true,
  })

  const router = useIonRouter()
  const [showAlert] = useIonAlert()
  const { present, showInfo } = useToast()

  const { captureEvent } = useAnalyticsContext()
  const { isClientAuthenticated } = useAuthenticatedClientContext()
  const { completeSession } = useAgendaSchedulingContext()
  const { openModal, toggleLoading } = useModalOrchestrationContext()

  const [uuid, setUuid] = React.useState<string>()

  const [getSession, { data }] = useLazyQuery<
    GetMovementSessionQuery,
    GetMovementSessionQueryVariables
  >(GetMovementSessionDocument, {
    variables: { uuid },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onCompleted: async (data) => {
      toggleLoading(false)

      if (
        data?.movementSession?.isFollowAlongNeeded &&
        isNil(data.movementSession.followAlong)
      ) {
        setTimeout(() => {
          getSession()
        }, 1000)
      }
    },
  })

  const session = data?.movementSession

  const [videoProgress, setVideoProgress] = useState<number>(0)

  const [startSession] = useMutation(StartMovementSessionDocument)
  const [abandonSession] = useMutation(AbandonMovementSessionDocument)

  const [canShare, setCanShare] = useState(false)

  const onAgendaItemFormClose = (reason?: string, data?: any) => {
    if (reason === "added") {
      setTimeout(() => {
        router.push(`/app/hub/agenda/items/${data.uuid}`, "forward")
      }, 100)
    }
  }

  const goBack = () => {
    if (router.canGoBack()) {
      router.goBack()
    } else {
      router.push("/app/hub/agenda")
    }
  }

  const handleBackButton = async () => {
    const shouldConfirmAbandon = videoProgress > 0.2

    if (shouldConfirmAbandon) {
      showAlert({
        message: TEXT.ABANDON_SESSION_CONFIRMATION_MESSAGE,
        buttons: [
          {
            text: TEXT.ABANDON,
            handler: async () => {
              captureEvent(AnalyticsEvent.SessionAbandoned, {
                followAlongUuid: session?.followAlong?.uuid,
                followAlongProgress: videoProgress,
              })

              abandonSession()

              goBack()
            },
          },
          TEXT.CONTINUE,
        ],
      })
    } else {
      captureEvent(AnalyticsEvent.SessionSkipped, {
        movementSessionUuid: uuid,
        followAlongUuid: session?.followAlong?.uuid,
        followAlongUrl: session?.followAlong?.url,
        followAlongProgress: videoProgress,
      })

      abandonSession()

      goBack()
    }
  }

  const handleProgress = (progress: number) => {
    setVideoProgress(progress)
  }

  const handleComplete = async () => {
    await completeSession({}, () => {
      captureEvent(AnalyticsEvent.SessionCompleted, {
        movementStyle: session?.movementStyle,
        movementModality: session?.movementModality,
        adventureName: agendaItem?.adventure?.squaloAdventureName,
        adventureElement: agendaItem?.adventure?.squaloAdventureElement,
      })

      onCompleted?.()
    })
  }

  const handleStart = useCallback(async () => {
    toggleLoading(true)

    await startSession({
      variables: {
        movementAgendaItemUuid: agendaItem?.uuid,
        squaloVideoUuid: followAlong?.uuid,
        reportedTimeAvailable,
      },
      onCompleted: async (data) => {
        setUuid(data.startMovementSession.uuid)

        captureEvent(AnalyticsEvent.SessionStarted, {
          sessionType: isNil(agendaItem) ? "ad_hoc" : "agenda_item",
          squaloVideoUrl: followAlong?.url,
          reportedTimeAvailable,
          adventureUuid: agendaItem?.adventure?.squaloAdventureId,
          adventureElement: agendaItem?.adventure?.squaloAdventureElement,
        })

        await getSession()
      },
      onError: (error) => {
        console.error(error)

        present({
          message: `${TEXT.START_SEESION_ERROR_MESSAGE}: ${error.message}`,
          status: "danger",
        })
      },
      refetchQueries: [],
    })
  }, [agendaItem, captureEvent, followAlong])

  const handleShuffleVideo = async () => {
    captureEvent(AnalyticsEvent.SessionVideoShuffled, {
      movementSessionUuid: session?.uuid,
      agendaItemUuid: agendaItem?.uuid,
      followAlongUuid: session?.followAlong?.uuid,
    })

    handleStart()
  }

  const handleShareVideo = async () => {
    if (session?.followAlong && (await Share.canShare())) {
      await Share.share({
        url: buildURL(`/app/hub/discover/videos/${session.followAlong.uuid}`),
      })
    }
  }

  const handleContributeCorrectionFlag = () => {
    if (session && session.followAlong) {
      openModal(ModalOrchestrationName.VideoFeedback, {
        video: session.followAlong as SqualoFollowAlongVideo,
        onClose: () => {
          showInfo("Thank you for your feedback!")
        },
      })
    }
  }

  // effect - check if we can share the video
  useEffect(() => {
    Share.canShare()
      .then(({ value }) => {
        setCanShare(value)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  // effect - throw error if no follow along or no agenda item
  useEffect(() => {
    if (!isNil(followAlong) && !isNil(agendaItem)) {
      throw new Error(
        "Cannot start session with both follow-along and agenda item"
      )
    }
  }, [followAlong, agendaItem])

  // effect - start a session after 10 seconds if one is not already started
  useEffect(() => {
    if (isNil(uuid) && isClientAuthenticated) {
      if (agendaItem || followAlong) {
        handleStart()
      }
    }
  }, [
    uuid,
    isClientAuthenticated,
    agendaItem,
    followAlong,
    router.routeInfo.pathname,
  ])

  const movementModality =
    session?.movementModality ||
    followAlong?.movementModality ||
    agendaItem?.movementModality

  const videoUrl = session?.followAlong?.url || followAlong?.url

  return (
    <Page backButtonRoot="/app/hub/agenda">
      <IonHeader>
        <IonToolbar color={movementModality}>
          <IonTitle>
            <div className="flex flex-row items-center justify-center gap-y-2">
              <ModalityIcon modality={movementModality} />
              <h1>{get(modalityLabels, movementModality || "")}</h1>
            </div>
          </IonTitle>
          <IonButtons slot="start" color="white">
            <IonButton onClick={handleBackButton} color="white" fill="clear">
              <IonIcon slot="icon-only" icon={chevronBackOutline} />
            </IonButton>
          </IonButtons>
          {canShare && (
            <IonButtons slot="end" color="white">
              <IonButton onClick={handleShareVideo} color="white" fill="clear">
                <IonIcon slot="icon-only" icon={shareSocialOutline} />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <Content fullscreen scrollY={true}>
        <div className="flex flex-col h-full">
          <div className="flex flex-col justify-around flex-grow">
            <div
              className={classNames(
                "flex flex-col p-4 lg:p-8 gap-y-4",
                "bg-" + movementModality
              )}
            >
              <div className="flex flex-row items-start justify-start gap-x-4">
                <div className="flex flex-col w-full gap-y-4">
                  <SessionTitle session={session} />
                  <div className="flex flex-col items-center justify-between lg:items-start lg:grid lg:grid-cols-3 gap-x-4">
                    <div className="z-50 flex items-center w-full h-full col-span-2 lg:items-start">
                      <div className="flex flex-col items-center justify-center w-full">
                        <div className="flex flex-col items-center justify-center w-full max-w-3xl gap-y-4 lg:items-start">
                          <div className="w-full overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow-lg">
                            {!isNil(videoUrl) ? (
                              <VideoPlayer
                                light={false}
                                src={videoUrl}
                                onProgress={handleProgress}
                                onComplete={handleComplete}
                              />
                            ) : (
                              <IonSkeletonText />
                            )}
                          </div>
                          <span className="hidden -mt-2 text-sm text-center text-neutral-600 lg:block">
                            {TEXT.MISLABELED_VIDEO_MESSAGE}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow hidden lg:block">
                      {session && (
                        <VideoDetailsCarousel
                          grid
                          scrollX
                          openModal={handleContributeCorrectionFlag}
                          video={session.followAlong as SqualoFollowAlongVideo}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-grow py-2 pt-32 pb-12 -mt-32 lg:py-4 lg:px-8 bg-neutral-100 gap-y-4">
              <div className="flex flex-col h-full lg:hidden gap-y-8">
                {session && (
                  <VideoDetailsCarousel
                    openModal={handleContributeCorrectionFlag}
                    video={session.followAlong as SqualoFollowAlongVideo}
                  />
                )}
                <span className="px-2 pb-4 text-sm text-center text-neutral-600">
                  {TEXT.MISLABELED_VIDEO_MESSAGE}
                </span>

                {!isNil(agendaItem) && !isNil(agendaItem.adventure) && (
                  <div className="flex flex-col gap-y-4">
                    <AdventureTemplateProductCarousel
                      products={agendaItem.adventure.squaloAdventure.products}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          {session && (
            <div
              className={classNames(
                "sticky bottom-0 flex items-stretch p-4",
                !isNil(agendaItem) && "pb-safe"
              )}
            >
              <div className="flex flex-col justify-around w-full h-full lg:flex-row">
                {isNil(agendaItem) && (
                  <Button
                    color="dark"
                    onClick={() =>
                      openModal(ModalOrchestrationName.MovementAgendaItemForm, {
                        followAlong: session.followAlong,
                      })
                    }
                    label={TEXT.SCHEDULE_VIDEO_FOR_LATER}
                    expand="block"
                    className="w-full"
                    icon={calendarOutline}
                    iconSlot="start"
                  />
                )}

                {agendaItem && isNil(agendaItem.followAlong) && (
                  <ShuffleVideoButton
                    onClick={handleShuffleVideo}
                    label={TEXT.SHUFFLE_VIDEO}
                  />
                )}

                <PingContainer
                  showPing={videoProgress > 0.8}
                  containerClassName="flex-grow w-full"
                  className="top-0 -right-2"
                  color={movementModality}
                >
                  <Button
                    color="primary"
                    onClick={handleComplete}
                    icon={checkmarkDoneOutline}
                    iconSlot="start"
                    label={TEXT.COMPLETE_SESSION}
                    expand="block"
                    className="flex-grow w-full"
                  />
                </PingContainer>
              </div>
            </div>
          )}
        </div>
      </Content>

      <MovementAgendaItemFormModal onClose={onAgendaItemFormClose} />
      <VideoFeedbackModal />
    </Page>
  )
}

export default SessionPage

import { Redirect, Route, Switch } from "react-router"
import ElementSelectionPage from "../pages/Welcome/ElementSelectionPage"
import AppDownloadPage from "../pages/Welcome/AppDownloadPage"
import FavoriteActivitySelectionPage from "../pages/Welcome/FavoriteActivitySelectionPage"
import WelcomeEnd from "../pages/Welcome/WelcomeEnd"

const WelcomeRouter: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/app/welcome">
        <Redirect to="/app/welcome/activities" />
      </Route>

      <Route
        exact
        path="/app/welcome/activities"
        component={FavoriteActivitySelectionPage}
      />

      <Route
        exact
        path="/app/welcome/elements"
        component={ElementSelectionPage}
      />

      <Route exact path="/app/welcome/download" component={AppDownloadPage} />

      <Route exact path="/app/welcome/end" component={WelcomeEnd} />

      <Route>
        <Redirect to="/app/welcome" />
      </Route>
    </Switch>
  )
}

export default WelcomeRouter

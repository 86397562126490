import { useMutation, useQuery } from "@apollo/client"
import classNames, { clsx } from "clsx"
import isNil from "lodash/isNil"
import React, { useCallback, useContext } from "react"
import { useTranslation } from "react-i18next"
import { RiAddLine, RiFeedbackLine, RiThumbDownLine } from "react-icons/ri"

import { useAgendaSchedulingContext } from "../../../contexts/AgendaSchedulingContext"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../../contexts/AnalyticsContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../../contexts/ModalOrchestrationContext"
import {
  AnswerMovementAgendaSuggestionDocument,
  GetMovementAgendaItemDocument,
  GetMovementAgendaItemQuery,
  GetMovementAgendaItemQueryVariables,
  ListSuggestionInboxDocument,
  MovementAgendaItem,
  MovementAgendaItemSummaryFragment,
  SqualoFollowAlongVideo,
} from "../../../generated/graphql"
import useToast from "../../../hooks/useToast"
import { MOVEMENT, NAME_SPACES } from "../../../locales/constants"
import Modal, { ModalContext, ModalProps, ModalSize } from "../../Core/Modal"
import Button, { ButtonProps } from "../../Forms/Button"
import { AgendaSuggestionHeader } from "../AgendaSuggestionHeader"
import MovementAgendaItemFormModal from "./MovementAgendaItemFormModal"
import { FollowAlongPreview } from "../FollowAlongPreview"

export interface SugggestionModalBodyProps {
  agendaItemFragment: MovementAgendaItemSummaryFragment
}

export const AgendaSuggestionForm: React.FC<SugggestionModalBodyProps> = ({
  agendaItemFragment,
}) => {
  const { name } = useContext(ModalContext)

  const { showError } = useToast()

  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.AGENDA_SUGGESTION, { returnObjects: true })

  const { captureEvent } = useAnalyticsContext()
  const { refetchQueries } = useAgendaSchedulingContext()
  const { openModal, closeModal } = useModalOrchestrationContext()

  const [answerSuggestion, { loading: answering }] = useMutation(
    AnswerMovementAgendaSuggestionDocument
  )

  const { data } = useQuery<
    GetMovementAgendaItemQuery,
    GetMovementAgendaItemQueryVariables
  >(GetMovementAgendaItemDocument, {
    variables: {
      uuid: agendaItemFragment.uuid,
    },
  })

  const agendaItem = {
    ...agendaItemFragment,
    ...(data?.movementAgendaItem || {}),
  } as Exclude<
    GetMovementAgendaItemQuery["movementAgendaItem"],
    null | undefined
  >

  const handleAnswer = useCallback(
    (isAccepted: boolean, handleCompleted: () => void) => {
      answerSuggestion({
        variables: {
          isAccepted,
          movementAgendaItemUuid: agendaItem.uuid,
        },
        onCompleted: () => {
          handleCompleted()
        },
        onError: (error) => {
          showError(error)
        },
        refetchQueries: [...refetchQueries, ListSuggestionInboxDocument],
      })
    },
    [agendaItem, refetchQueries]
  )

  const handleFeedbackResponse = useCallback(
    (reason?: string) => {
      closeModal(name, reason)
    },
    [name, closeModal]
  )

  const handlePlanResponse = useCallback(
    (reason?: string) => {
      if (reason === "modified") {
        handleAnswer(true, () => {
          closeModal(name, "redirect", agendaItem.uuid)
        })
      }
    },
    [name, closeModal, agendaItem, handleAnswer]
  )

  const handlePlan = () => {
    captureEvent(AnalyticsEvent.AgendaSuggestionRescheduled, {
      movementAgendaItemUuid: agendaItem.uuid,
      adventure: agendaItem?.adventure?.squaloAdventureName,
    })

    openModal(
      ModalOrchestrationName.MovementAgendaItemForm,
      {
        agendaItem,
      },
      { onClose: handlePlanResponse }
    )
  }

  const handleSkip = () => {
    captureEvent(AnalyticsEvent.AgendaSuggestionRejected, {
      movementModality: agendaItem?.movementModality,
      movementStyle: agendaItem?.movementStyle,
      expectedDuration: agendaItem?.expectedDuration,
      suggestedBy: agendaItem?.suggestedByType,
    })

    handleAnswer(false, () => {
      openModal(ModalOrchestrationName.RejectedSuggestionFeedbackModal)
    })
  }

  const CommitButton: React.FC<ButtonProps> = ({ ...props }) => {
    return (
      <Button
        type="submit"
        expand="block"
        onClick={handlePlan}
        disabled={answering}
        label={TEXT.CTAS.PLAN}
        icon={RiAddLine}
        iconClassName="absolute left-0"
        className={classNames("w-full m-0")}
        {...props}
      />
    )
  }

  const SkipButton = () => {
    return (
      <Button
        type="button"
        fill="outline"
        expand="block"
        onClick={handleSkip}
        icon={RiThumbDownLine}
        color="neutral"
        disabled={answering}
        label={TEXT.CTAS.SKIP}
        iconClassName="absolute left-0"
        className={classNames("w-full m-0 text-neutral")}
      />
    )
  }

  return (
    <div className={clsx("relative flex flex-col h-full bg-neutral-100")}>
      <AgendaSuggestionHeader agendaItem={agendaItem as MovementAgendaItem} />

      <div className="flex flex-col items-center justify-start w-full h-full px-4 py-4 pb-32 overflow-y-scroll gap-y-4">
        {!isNil(agendaItem.followAlong) && (
          <FollowAlongPreview
            className="shadow-none"
            followAlong={agendaItem.followAlong as SqualoFollowAlongVideo}
          />
        )}
        {!isNil(agendaItem.description) && (
          <div className="flex flex-col w-full h-full p-4 rounded-md shadow-inner bg-neutral/5 gap-y-2">
            <p className="whitespace-pre-wrap text-neutral-700">
              {agendaItem.description}
            </p>
          </div>
        )}
      </div>

      <div className="absolute bottom-0 w-full p-4 bg-transparent">
        <div
          className={classNames(
            "flex flex-col sticky bottom-0 gap-y-2 mb-safe",
            "bg-gradient-to-t from-neutral-100/30 to-neutral-100/100"
          )}
        >
          <CommitButton />
          <SkipButton />
        </div>
      </div>

      <MovementAgendaItemFormModal />
      <RejectedSuggestionFeedbackModal onClose={handleFeedbackResponse} />
    </div>
  )
}

export const AgendaSuggestionModal: React.FC<Partial<ModalProps>> = ({
  name = ModalOrchestrationName.AgendaSuggestionModal,
  ...props
}) => {
  return (
    <Modal name={name} isSheet initialSize={ModalSize.Full} {...props}>
      <Modal.Body scrollY={false}>
        {(props: any) => <AgendaSuggestionForm {...props} />}
      </Modal.Body>
    </Modal>
  )
}

export const RejectedSuggestionFeedbackModal: React.FC<Partial<ModalProps>> = ({
  name = ModalOrchestrationName.RejectedSuggestionFeedbackModal,
  ...props
}) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.AGENDA_SUGGESTION, { returnObjects: true })

  const { closeModal } = useModalOrchestrationContext()

  return (
    <Modal name={name} {...props}>
      <Modal.Header title={TEXT.SUGGESTION_REJECTED.TITLE} />

      <Modal.Body>
        {(_props: any) => (
          <div className="flex flex-col justify-between h-full p-4">
            <div className="flex flex-col justify-center flex-grow w-full">
              <div className="flex flex-col items-center justify-center p-10">
                <RiFeedbackLine className="w-32 h-32 text-primary" />
              </div>

              <div className="text-center text-neutral">
                <span>{TEXT.SUGGESTION_REJECTED.DESCRIPTION}</span>
              </div>
            </div>
            <div className="flex flex-col gap-y-1 mb-safe">
              <Button
                onClick={() => {
                  closeModal(name, "openChat")
                }}
                label={TEXT.SUGGESTION_REJECTED.CHAT_CTA}
              />
              <Button
                label={TEXT.SUGGESTION_REJECTED.SKIP_CTA}
                fill="clear"
                onClick={() => {
                  closeModal(name, "skip")
                }}
                className="text-sm"
              />
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default AgendaSuggestionModal

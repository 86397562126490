import clsx from "clsx"
import Button, { ButtonProps } from "../../Forms/Button"
import isNil from "lodash/isNil"
import { YinBehaleIcon } from "../../BehaleIcon"
import { omit } from "lodash"

export const MovementWizardCard: React.FC<{
  actionButtonProps?: ButtonProps[]
  primaryButtonProps?: ButtonProps
}> = ({ actionButtonProps, primaryButtonProps, children }) => {
  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-center w-full px-4 h-full"
      )}
    >
      <div
        className={clsx(
          "backdrop-blur",
          "bg-white/80 h-full w-full text-neutral-600",
          "flex flex-col items-start",
          "rounded-t-lg",
          (isNil(primaryButtonProps) || primaryButtonProps.hidden) &&
            "rounded-b-lg"
        )}
      >
        <div className="flex flex-row items-center justify-between flex-shrink-0 w-full px-4 py-2 border-b gap-x-1 border-neutral/20">
          <div className="flex flex-row items-center justify-start gap-x-1">
            <YinBehaleIcon
              className="w-3 h-3 fill-current text-primary"
              width={12}
              height={12}
            />
            <span className="text-sm font-bold tracking-tighter text-primary">
              Move AI
            </span>
            <span className="px-1 text-xs font-semibold text-white rounded-full bg-primary/50">
              beta
            </span>
          </div>

          {actionButtonProps?.map((action, index) => (
            <Button key={index} fill="clear" icon={action.icon} {...action} />
          ))}
        </div>

        <div className="flex flex-col items-center justify-start w-full h-full overflow-y-scroll max-h-48">
          {children}
        </div>
      </div>

      {isNil(primaryButtonProps) ? (
        <></>
      ) : (
        <div
          className={clsx(
            "rounded-b-md rounded-t-none flex flex-row items-stretch w-full"
          )}
        >
          <Button
            fill="clear"
            className={clsx(
              "rounded-b-md",
              "flex-grow m-0",
              primaryButtonProps.className
            )}
            iconClassName="absolute left-0"
            labelClassName="no-underline"
            {...omit(primaryButtonProps, ["className"])}
          />
        </div>
      )}
    </div>
  )
}

import isNil from "lodash/isNil"
import take from "lodash/take"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { useMemo } from "react"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import { useQuery } from "@apollo/client"
import {
  ListAdventureTemplatesDocument,
  ListAdventureTemplatesQuery,
  ListAdventureTemplatesQueryVariables,
} from "../../generated/graphql"
import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"
import { useLocaleContext } from "../../contexts/LocaleContext"
import Carousel from "nuka-carousel"
import { AdventurePreviewCard } from "../Adventure/AdventurePreviewCard"
import { MEMBERSHIP, NAME_SPACES } from "../../locales/constants"
import { useTranslation } from "react-i18next"
import { DiscreteCarouselPagination } from "../Core/CarouselPagination"
import { Link } from "react-router-dom"
import { RiArrowRightCircleFill } from "react-icons/ri"
import { useAdventureContext } from "../../contexts/AdventureContext"

export const RecommendedAdventureCarousel: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)
  const TEXT = t(MEMBERSHIP.TODAY, { returnObjects: true })

  const { captureEvent } = useAnalyticsContext()

  const { language, isInitialized } = useLocaleContext()
  const { currentMember } = useAuthenticatedClientContext()
  const { agenda } = useAgendaSchedulingContext()

  const { currentAdventure, isQuerying } = useAdventureContext()

  const { data } = useQuery<
    ListAdventureTemplatesQuery,
    ListAdventureTemplatesQueryVariables
  >(ListAdventureTemplatesDocument, {
    variables: {
      locale:
        language as unknown as ListAdventureTemplatesQueryVariables["locale"],
    },
    skip: !isInitialized || isQuerying || !isNil(currentAdventure),
    fetchPolicy: "cache-first",
  })

  const adventureTemplates = data?.listAdventures || []

  const filteredAdventures = useMemo(
    () =>
      take(
        adventureTemplates.filter(
          (adventure) =>
            currentMember?.elements?.length === 0 ||
            currentMember?.elements?.includes(adventure.element)
        ),
        3
      ),
    [adventureTemplates, agenda]
  )

  const handleAfterSlide = (index: number) => {
    captureEvent(AnalyticsEvent.RecommendedAdventureCarouselSlide, {
      index,
      adventureUuid: filteredAdventures[index]?.id,
    })
  }

  if (isNil(currentAdventure) && !isQuerying && filteredAdventures.length > 0) {
    return (
      <div className="flex flex-col w-full h-full gap-y-4">
        <span className="px-4 text-xl font-medium text-neutral-600">
          {TEXT.RECOMMENDED_ADVENTURES.TITLE}
        </span>

        <Carousel
          className="h-full pb-8"
          renderCenterLeftControls={() => <></>}
          renderCenterRightControls={() => <></>}
          renderBottomCenterControls={DiscreteCarouselPagination}
          autoplay={false}
          wrapAround={false}
          swiping
          afterSlide={handleAfterSlide}
        >
          {filteredAdventures.map((adventure, index) => (
            <div key={index} className="w-full h-full px-4">
              <AdventurePreviewCard adventure={adventure} />
            </div>
          ))}

          <div className="w-full h-full px-4">
            <Link to="/app/hub/discover">
              <button className="flex flex-col items-center justify-center w-full h-full p-4 text-xl font-medium text-center rounded-lg shadow-inner gap-y-2 text-neutral-600 bg-neutral-200">
                <RiArrowRightCircleFill className="w-12 h-12" />

                <span className="text-2xl">
                  {TEXT.RECOMMENDED_ADVENTURES.VIEW_ALL}
                </span>
              </button>
            </Link>
          </div>
        </Carousel>
      </div>
    )
  }

  return null
}

import { useMutation } from "@apollo/client"
import clsx from "clsx"
import { isNil, omit } from "lodash"
import { useState, useMemo, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router"
import Content from "../../components/Core/Content"
import Page from "../../components/Core/Page"
import Button from "../../components/Forms/Button"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import {
  UpdateMovementAgendaItemDescriptionMutation,
  UpdateMovementAgendaPreferencesDocument,
  UpdateMovementAgendaPreferencesMutationVariables,
  MovementActivityInput,
  MovementStyle,
  GetCurrentMovementAgendaDocument,
} from "../../generated/graphql"
import { NAME_SPACES, WELCOME } from "../../locales/constants"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import { FavoriteActivitySelect } from "../../components/Welcome/FavoriteActivitySelect"
import { useIonRouter } from "@ionic/react"

const FavoriteActivitySelectionPage: React.FC<RouteComponentProps> = () => {
  const { t, i18n } = useTranslation(NAME_SPACES.WELCOME)
  const TEXT = t(WELCOME.ACTIVITIES, { returnObjects: true })

  const router = useIonRouter()
  const isInWelcome = router.routeInfo.pathname.includes("welcome")

  const { captureEvent, setPersonProperties } = useAnalyticsContext()
  const { agenda } = useAgendaSchedulingContext()

  const [primaryStyle, setPrimaryStyle] = useState<MovementStyle | null>(null)

  const [favoriteMovementActivities, setFavoriteMovementActivitys] = useState<
    MovementActivityInput[]
  >([])

  const [updateAgendaPreferences] = useMutation<
    UpdateMovementAgendaItemDescriptionMutation,
    UpdateMovementAgendaPreferencesMutationVariables
  >(UpdateMovementAgendaPreferencesDocument, {
    refetchQueries: [GetCurrentMovementAgendaDocument],
  })

  const routerLink = useMemo(() => {
    if (isInWelcome) {
      return "/app/welcome/elements"
    } else {
      return "/app/hub/home"
    }
  }, [isInWelcome])

  const handleNextClick = () => {
    captureEvent(AnalyticsEvent.WelcomeFavoriteActivitiesSubmitted, {
      nActivities: favoriteMovementActivities.length,
    })

    setPersonProperties({
      primaryStyle: primaryStyle,
      nFavoriteActivities: favoriteMovementActivities.length,
    })

    // set isPrimary to true for the primary style
    const activities = favoriteMovementActivities.map((activity) => {
      if (!isNil(primaryStyle) && activity.movementStyle === primaryStyle) {
        return {
          ...activity,
          isPrimary: true,
        }
      }

      return activity
    })

    updateAgendaPreferences({
      variables: { favoriteMovementActivities: activities },
    })
  }

  const defaultFavoriteMovementActivity = useMemo(() => {
    if (isNil(agenda) || isNil(agenda.favoriteMovementActivities)) {
      return []
    }

    return
  }, [agenda])

  useEffect(() => {
    if (isNil(agenda)) return

    if (
      isNil(agenda.favoriteMovementActivities) ||
      agenda.favoriteMovementActivities.length === 0
    ) {
      return
    }

    setPrimaryStyle(
      agenda.favoriteMovementActivities.find((activity) => activity.isPrimary)
        ?.movementStyle ?? null
    )
    setFavoriteMovementActivitys(
      agenda.favoriteMovementActivities.map((a) =>
        omit(a, ["__typename", "movementModality", "isPrimary"])
      )
    )
  }, [defaultFavoriteMovementActivity])

  return (
    <Page
      className={clsx("h-full bg-primary", isInWelcome && "pt-safe")}
      showBackButton={!isInWelcome}
    >
      <Content
        color="primary"
        scrollY={false}
        className="relative flex flex-col items-center justify-center h-full"
      >
        <div
          className={clsx(
            "flex flex-col items-center justify-start h-full max-w-3xl px-4 py-4 mx-auto gap-y-4",
            "pb-24 overflow-y-scroll"
          )}
        >
          <div className="flex flex-col w-full">
            <div className="flex flex-row items-center justify-start w-full my-4 gap-x-2">
              <span className="w-full text-4xl font-semibold tracking-tighter text-left">
                {TEXT.TITLE}
              </span>
            </div>
            <span className="w-full font-medium text-left">
              {TEXT.DESCRIPTION}
            </span>
          </div>

          <div
            className={clsx(
              "flex flex-col w-full items-center justify-start",
              "bg-primary-600 rounded-lg shadow-inner"
            )}
          >
            <FavoriteActivitySelect
              primaryStyle={primaryStyle}
              setPrimaryStyle={setPrimaryStyle}
              setFavoriteActivities={setFavoriteMovementActivitys}
              favoriteActivities={favoriteMovementActivities}
            />
          </div>
        </div>

        <div
          className={clsx(
            "sticky bottom-0 left-0 right-0 flex flex-col p-4 w-full pb-safe"
          )}
        >
          <Button
            fill="solid"
            className="w-full max-w-xl mx-auto"
            expand="block"
            textColor="primary"
            color="white"
            size="large"
            routerLink={routerLink}
            routerDirection="forward"
            onClick={handleNextClick}
            label={i18n.t("COMMON:FORMS.NEXT")}
          />
        </div>
      </Content>
    </Page>
  )
}

export default FavoriteActivitySelectionPage

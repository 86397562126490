import { useMemo } from "react"
import isNil from "lodash/isNil"
import { RiEdit2Line } from "react-icons/ri"
import { formatBodyParts } from "../../../../utils/format"
import { MOVEMENT, NAME_SPACES } from "../../../../locales/constants"
import { useTranslation } from "react-i18next"
import {
  MovementWizardQuestion,
  QUESTION_ORDER,
} from "../MovementWizardQuestion"
import { MovementWizardState } from "../MovementWizard"
import { MovementWizardCard } from "../MovementWizardCard"

const MovementWizardRecapRow = ({
  state,
  question,
  label,
  formatValue,
  setSelectedIndex,
}: {
  state: MovementWizardState
  question: MovementWizardQuestion
  label?: string
  formatValue?: (value: any) => string
  setSelectedIndex: (index: number) => void
}) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.WIZARD, { returnObjects: true })

  const formattedValue = useMemo(() => {
    const value = state[question]

    if (!isNil(formatValue)) {
      return formatValue(value)
    }

    if (
      TEXT.QUESTIONS[question].OPTIONS.length > 0 &&
      typeof value === "number"
    ) {
      return TEXT.QUESTIONS[question].OPTIONS[value]
    }

    return "-"
  }, [state, question])

  return (
    <div className="flex flex-col items-start justify-between w-full py-2 text-left">
      <span className="text-lg tracking-tight truncate text-neutral-500">
        {label || TEXT.QUESTIONS[question].TITLE}
      </span>
      <button
        className="flex flex-row items-center justify-center gap-x-1"
        onClick={() => setSelectedIndex(QUESTION_ORDER.indexOf(question) + 1)}
      >
        <span className="text-lg font-medium tracking-tight text-neutral-600">
          {isNil(state[question]) ? "-" : formattedValue}
        </span>
        <RiEdit2Line className="text-neutral-600" />
      </button>
    </div>
  )
}
export const MovementWizardRecapSlide: React.FC<{
  onClick: () => void
  state: MovementWizardState
  setSelectedIndex: (index: number) => void
}> = ({ onClick, state, setSelectedIndex }) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.WIZARD, { returnObjects: true })

  return (
    <>
      <MovementWizardCard
        primaryButtonProps={{
          label: TEXT.RECAP.CTA,
          className: "bg-CARDIO text-white",
          onClick,
        }}
      >
        <div className="flex flex-col items-start justify-start w-full h-full px-4 overflow-y-scroll divide-y divide-neutral-300/20">
          <MovementWizardRecapRow
            state={state}
            question={MovementWizardQuestion.LatestMovement}
            setSelectedIndex={setSelectedIndex}
          />

          <MovementWizardRecapRow
            state={state}
            question={MovementWizardQuestion.CurrentMovementVibe}
            setSelectedIndex={setSelectedIndex}
          />

          <MovementWizardRecapRow
            state={state}
            question={MovementWizardQuestion.PhyiscalDiscomforts}
            formatValue={(value) => formatBodyParts(value)}
            setSelectedIndex={setSelectedIndex}
          />
        </div>
      </MovementWizardCard>
    </>
  )
}

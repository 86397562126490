import { MovementWizardQuestion } from "../../components/Movement/Wizard/MovementWizardQuestion"
import {
  AssessmentRatingOfPerceivedExertion,
  BodyJointEnum,
  BodyMuscleEnum,
  BodyMuscleGroupEnum,
  BodyPartEnum,
  BodyRegionEnum,
  EnvironmentalEquipment,
  MovementModality,
  MovementSessionSatisfaction,
  MovementStyle,
  TrainingZone,
} from "../../generated/graphql"
import { MOVEMENT } from "../constants"

export default {
  [MOVEMENT.SESSION_LOGGING]: {
    ERROR_MESSAGE: "Impossible d'inscrire séance. Essaye une autre fois.",
    SUCCESS_MESSAGE: "Bravo !! Plus rien ne t’arrête",
    STYLE_REQUIRED: "Le style est requis",
    WEEKDAY_REQUIRED: "Le jour de la semaine est requis",
    DURATION_REQUIRED: "La durée est requise",
    REGISTER_SESSION: "Inscrire séance",
    SUBTITLE: "Tous tes mouvements, un seul agenda",
    SECTIONS: {
      MOVEMENT: "Quoi ?",
      SCHEDULE: "Quand ?",
    },
  },
  [MOVEMENT.ITEM_PLANNING]: {
    SESSION_ADDED_SUCCESS_MESSAGE: "La séance a été ajoutée à l'agenda",
    SESSION_MODIFIED_SUCCESS_MESSAGE: "La séance a été modifiée",
    ADD_TO_AGENDA: "Ajouter à l'agenda",
    SAVE_MODIFICATION: "Enregistrer modifications",
    SESSION_DELETED_SUCCESS_MESSAGE: "Séance supprimée.",
    TITLE: {
      PLAN_NEW: "Planifie une séance",
      ADAPT_EXISTING: "Adapte la séance",
    },
    MODIFY_SESSION: "Modifier séance",
    GET_HELP: "Besoin d'aide ?",
    RECURRING_ITEM_MESSAGE: "Cette séance est une séance récurrente.",
    MODIFY_ITEM_OCCURRENCE_MESSAGE: "Modifier pour la semaine",
    MODIFY_RECURRING_ITEM_MESSAGE: "Modifier toutes les occurrences",
    DELETE_ITEM: "Supprimer séance",
    DELETE_ITEM_OCCURRENCE_MESSAGE: "Sauter séance cette semaine",
    DELETE_RECURRING_ITEM_MESSAGE: "Supprimer séance récurrente",
    CANCEL: "Annuler",
    CLEAR: "Effacer",
    SECTIONS: {
      MOVEMENT: "Activité",
      SCHEDULE: "Planification",
    },
  },
  [MOVEMENT.AGENDA_HOME]: {
    REST_DAY: {
      PAST: "jour de repos",
      FUTURE: "planifier séance",
    },
    CONVERSION_HOOK: {
      TITLE: "Prêt à découvrir ton destin, {{ firstName }} ?",
      DESCRIPTION:
        "Deviens un Héros dès maintenant pour libérer le plein potentiel de ton agenda de mouvement.",
      CTA: "Débloque l'agenda",
    },
    ADD_SESSION: "Ajouter une séance",
    LOG_SESSION: "Inscrire une séance complétée",
    PLAN_SESSION: "Planifier une nouvelle séance",
    CANCEL: "Annuler",
    AGENDA: "Agenda",
    SEE_PREVIOUS_WEEK: "Voir semaine précédente",
    SEE_NEXT_WEEK: "Voir semaine suivante",
    WARNING_PLAN_SESSION_IN_PAST:
      "Impossible de planifier une séance pour un jour dans le passé",
    NEXT_UP: "Prochaine séance",
    LETS_GO: "C'est parti !",
    AVAILABLE_TIME: "Combien de temps as-tu ?",
  },
  [MOVEMENT.AGENDA_ITEM]: {
    CTA: {
      START_SESSION: "Commencer séance",
      LOG_SESSION: "C'est fait !",
    },
    SESSION_NOT_FOUND: "Cette séance n'existe pas",
    SUCCESS_MESSAGE: "Bravo ! Plus rien ne t’arrête",
    AN_ERROR_HAS_OCCURED: "Une erreur est survenue. Essaye une autre fois.",
    PLANNED_SESSION: "Séance planifiée",
    NO_VIDEO_MESSAGE:
      "Si tu souhaites bénéficier d’une vidéo pour ce type de séance, contacte l’équipe Behale.",
    VALIDATE_SESSION: "Valider séance",
    DETAILS: "Détails",
    DURATION: "Durée",
    STYLE: "Style",
    TARGET_AREA: "Zone cible",
    RECURRENCE: "Récurrence",
    INTENSITY: "Intensité",
    EQUIPMENT: "Équipement",
    PLANNED_START_TIME: "Début prévu",
    DESCRIPTION: {
      PLACEHOLDER: "Ajoute des notes à ta séance !",
    },
    SUGGESTED_BY_COACH: "Séance proposée par ton coach",
    SUGGESTED_FOLLOW_ALONG: "Vidéo suggérée",
    SUGGESTED_EXTERNAL_LINK: "Ouvrir lien",
  },
  [MOVEMENT.INTENSITY]: {
    [TrainingZone.Recovery]: "Récup.",
    [TrainingZone.Light]: "Basse",
    [TrainingZone.Moderate]: "Modérée",
    [TrainingZone.Vigorous]: "Haute",
    [TrainingZone.Maximal]: "Maximale",
  },
  [MOVEMENT.AGENDA_SUGGESTION]: {
    MESSAGE_FROM_COACH: "Un message de ton coach",
    CTAS: {
      PLAN: "Ajouter à l'agenda",
      SKIP: "Passer",
    },
    SUGGESTION_REJECTED: {
      TITLE: "Donnes ton feedback",
      DESCRIPTION:
        "Qu'est-ce qui n'allait pas avec cette suggestion ? Plus tu me donnes d'informations, meilleures sont mes suggestions.",
      CHAT_CTA: "Donner du feedback",
      SKIP_CTA: "Passer",
    },
  },
  [MOVEMENT.CURRENT_MOVEMENT_SESSION]: {
    START_SEESION_ERROR_MESSAGE: "Impossible de démarrer séance",
    ABANDON_SESSION_ERROR_MESSAGE: "Impossible d'abandonner séance",
    COMPLETE_SESSION_ERROR_MESSAGE: "Impossible de completer séance",
    ABANDON_SESSION_CONFIRMATION_MESSAGE:
      "Es-tu sûr de vouloir abandonner la séance ?",
    ABANDON: "J'abandonne",
    CONTINUE: "Je continue",
    SUCCESS_MESSAGE: "Bien joué.e 🍃 Ta séance est complétée.",
    BEST_SUGGESTION: "On trouve la meilleure vidéo pour toi...",
    COMPLETE_SESSION: "Compléter séance",
    TITLE: "Ta séance de",
    SHUFFLE_VIDEO: "Trouver une autre vidéo",
    SCHEDULE_VIDEO_FOR_LATER: "Planifier pour après",
    MISLABELED_VIDEO_MESSAGE:
      "Cette vidéo YouTube a été sélectionnée pour notre librairie gratuite Open Movement. Toute suggestion pour améliorer l'étiquetage est la bienvenue.",
  },
  [MOVEMENT.PAST_MOVEMENT_SESSION]: {
    TITLE: "Séance - {{ date }}",
    ACTIONS: {
      DELETE: {
        LABEL: "Supprimer séance",
        RESULT: {
          SUCCESS: "Séance supprimée",
          ERROR: "Impossible de supprimer la séance",
        },
      },
      MODIFY: {
        LABEL: "Modifier détails",
        RESULT: {
          SUCCESS: "Séance modifiée",
          ERROR: "Impossible de modifier la séance",
        },
      },
      CANCEL: "Annuler",
    },
    DESCRIPTION: {
      PLACEHOLDER: "Cette séance était...",
    },
  },
  [MOVEMENT.FORM]: {
    TITLE: {
      PLACEHOLDER: "Un titre pour ta séance...",
    },
    SCHEDULE: {
      LABEL: "Date",
      FUTURE: {
        TITLE: "Date de la séance",
        // SUBTITLE: "Planifie ta séance en avance pour ne rien oublier.",
      },
      PAST: {
        TITLE: "Date de la séance",
        // SUBTITLE: "Ajoute ta séance à ton agenda pour la retrouver facilement.",
      },
      TITLE: "Programme ta séance",
      SUBTITLE: "Quand veux-tu faire ta séance ?",
      EVERY_WEEK: "Toutes les semaines",
      ANY_DAY: "N'importe quel jour",
    },
    STYLE: {
      LABEL: "Style",
      TITLE: "",
      PLACEHOLDER: "Choisi l'activité",
    },
    BODY_PART: {
      LABEL: "Zone cible",
      TITLE: "Cible une zone du corps",
      // SUBTITLE: "Quelle zone du corps veux-tu travailler ?",
    },
    DURATION: {
      LABEL: "Durée",
      TITLE: "Choisis la durée",
      // SUBTITLE: "Combien de temps as-tu pour faire ta séance ?",
    },
    FOLLOW_ALONG: {
      LABEL: "Vidéo",
    },
    RPE: {
      LABEL: "Intensité",
      TITLE: "Quel est ton ressenti de la séance ?",
      // SUBTITLE: "Choisis l'effort aperçu sur l'échelle ci-dessous.",
    },
    DISTANCE: {
      LABEL: "Distance",
      TITLE: "Quelle distance as-tu parcouru ?",
      // SUBTITLE: "Indique la distance parcourue en kilomètres.",
      EMPTY: "Pas de distance",
    },
    START_TIME: {
      LABEL: "Début",
      PLACEHOLDER: "N'importe quand",
      PAST: {
        TITLE: "Heure de début",
        // SUBTITLE: "Choisis l'heure de début de ta séance.",
      },
      FUTURE: {
        TITLE: "Heure de début",
        // SUBTITLE: "Choisis l'heure de début de ta séance.",
      },
    },
    // todo - delete below
    TARGET_BODY_PARTS: "Zone cible",
    CHOOSE_TARGET_BODY_PARTS: "Choisir zone",
    DATE: "Date",
    CONFIRM: "Confirmer",
    CANCEL: "Annuler",
    CHOOSE: "Choisir",
    MOVEMENT_STYLE: "Style",
    MOVEMENT_STYLE_SEARCH_PLACEHOLDER: "Rechercher un style...",
    NO_MOVEMENT_STYLE_SELECTED: "Tous les styles",
    SELECT_MOVEMENT_STYLE: "Choisis un style",
    CLEAR: "Effacer",
    SESSION_STYLE_CHOOSE: "Choisi un style pour ta séance.",
    WEEKDAY: "Jour de la semaine",
  },
  [MOVEMENT.POST_SESSION_FEEDBACK]: {
    SESSION_COMPLETED: "Séance complétée !",
    RATING_LABEL: "Comment s'est passée la séance ?",
    DESCRIPTION_PLACEHOLDER: "Rajoute des notes...",
    CONTINUE_BUTTON: "Continuer",
    SATISFACTION: {
      LABELS: {
        [MovementSessionSatisfaction.Love]: "Trop bien",
        [MovementSessionSatisfaction.Like]: "Bien",
        [MovementSessionSatisfaction.Dislike]: "Pas bien",
      },
      SUPPORT: {
        LABEL: "Un problème ? Besoin d'aide ?",
        BUTTON: "Contacte-nous",
      },
    },

    SHARE_BUTTON: "Partager",
    TITLE: "behale",
    WEEK_SESSION: "Séances cette semaine",
    GOAL_INPROGRESS_MESSAGE: "Bravo! On y est presque, ",
    GOAL_COMPLETED_MESSAGE:
      "Excellent ! T'as accomplis ton objectif de la semaine",
    SHARE_MESSAGE:
      "J’ai déjà fait {{completedSession}} séance(s) avec Behale cette semaine !",
    SHARE_TITLE: "J’ai fait ma séance de {{movementStyle}} avec Behale",
  },
  [MOVEMENT.TARGET_BODY_PARTS]: {
    NONE: "Aucune cible",
    [BodyPartEnum.FullBody]: "Tout le corps",
    // regions
    [BodyRegionEnum.UpperBody]: "Haut du corps",
    [BodyRegionEnum.Core]: "Tronc",
    [BodyRegionEnum.LowerBody]: "Bas du corps",
    // muscle groups
    [BodyMuscleGroupEnum.NeckMuscles]: "Cou",
    [BodyMuscleGroupEnum.ChestMuscles]: "Pectoraux",
    [BodyMuscleGroupEnum.ShoulderMuscles]: "Epaules",
    [BodyMuscleGroupEnum.ArmMuscles]: "Bras",
    [BodyMuscleGroupEnum.BackMuscles]: "Dos",
    [BodyMuscleGroupEnum.TrunkMuscles]: "Tronc",
    [BodyMuscleGroupEnum.HipMuscles]: "Fessiers",
    [BodyMuscleGroupEnum.ThighMuscles]: "Cuisses",
    [BodyMuscleGroupEnum.LegMuscles]: "Jambes",
    // muscles
    [BodyMuscleEnum.Abdominals]: "Abdos",
    [BodyMuscleEnum.Adductors]: "Adducteurs",
    [BodyMuscleEnum.Biceps]: "Biceps",
    [BodyMuscleEnum.Calves]: "Mollets",
    [BodyMuscleEnum.Forearms]: "Avant-bras",
    [BodyMuscleEnum.Glutes]: "Fessiers",
    [BodyMuscleEnum.Hamstrings]: "Ischios",
    [BodyMuscleEnum.Iliopsoas]: "Iliopsoas",
    [BodyMuscleEnum.LowerBack]: "Lombaire",
    [BodyMuscleEnum.MiddleBack]: "Dorsales",
    [BodyMuscleEnum.Obliques]: "Obliques",
    [BodyMuscleEnum.Quadriceps]: "Quadriceps",
    [BodyMuscleEnum.Tibias]: "Tibias",
    [BodyMuscleEnum.Triceps]: "Triceps",
    [BodyMuscleEnum.UpperBack]: "Haut du dos",
    // joints
    [BodyJointEnum.Ankles]: "Chevilles",
    [BodyJointEnum.Elbows]: "Coude",
    [BodyJointEnum.Wrists]: "Poignets",
    [BodyJointEnum.Hips]: "Hanches",
    [BodyJointEnum.Knees]: "Genoux",
    [BodyJointEnum.ShoulderComplex]: "Épaules",
    [BodyJointEnum.CervicalSpine]: "Cervicales",
    [BodyJointEnum.LumbarSpine]: "Lombaires",
    [BodyJointEnum.ThoracicSpine]: "Dos",
    [BodyJointEnum.Sacroiliac]: "Sacro-iliaque",
  },
  [MOVEMENT.STYLE]: {
    ALL_STYLES: "Tous les styles",
    [MovementStyle.Yoga]: "Yoga",
    [MovementStyle.Pilates]: "Pilates",
    [MovementStyle.Running]: "Course à pied",
    [MovementStyle.Cycling]: "Velo",
    [MovementStyle.Swimming]: "Natation",
    [MovementStyle.CircuitTraining]: "Renfo. Fonctionnel",
    [MovementStyle.Hiit]: "HIIT",
    [MovementStyle.Dancing]: "Danse",
    [MovementStyle.FunctionalStretching]: "Stretching",
    [MovementStyle.Rowing]: "Aviron",
    [MovementStyle.Walking]: "Marche",
    [MovementStyle.Weightlifting]: "Musculation",
    [MovementStyle.Basketball]: "Basket",
    [MovementStyle.Football]: "Football",
    [MovementStyle.Rugby]: "Rugby",
    [MovementStyle.Tennis]: "Tennis",
    [MovementStyle.Golf]: "Golf",
    [MovementStyle.Bjj]: "Jiu Jitsu Brésilien",
    [MovementStyle.Judo]: "Judo",
    [MovementStyle.Boxing]: "Boxe",
    [MovementStyle.Volleyball]: "Volley",
    [MovementStyle.TaiChi]: "Tai Chi",
    [MovementStyle.Karate]: "Karaté",
    [MovementStyle.Surfing]: "Surf",
    [MovementStyle.Skiing]: "Ski",
    [MovementStyle.Climbing]: "Escalade",
    [MovementStyle.Dancing]: "Danse",
    [MovementStyle.HorseRiding]: "Equitation",
    [MovementStyle.Hiking]: "Randonnée",
    [MovementStyle.TableTennis]: "Ping Pong",
    [MovementStyle.Handball]: "Handball",
    [MovementStyle.Consultation]: "Consultation",
    [MovementStyle.Article]: "Article",
    [MovementStyle.Breathwork]: "Respiration Guidée",
    [MovementStyle.Meditation]: "Meditation",
    [MovementStyle.Calisthenics]: "Calisthénies",
    [MovementStyle.Skateboarding]: "Skateboard",
    [MovementStyle.Crossfit]: "Crossfit",
    [MovementStyle.Other]: "Autre",
  } as Record<MovementStyle | "ALL_STYLES", string>,
  [MOVEMENT.MODALITY]: {
    [MovementModality.Cardio]: "Cardio",
    [MovementModality.Strength]: "Renfo",
    [MovementModality.Mobility]: "Mobilité",
    [MovementModality.Breathing]: "Respiration",
    [MovementModality.Sport]: "Sport",
    [MovementModality.Knowledge]: "Connaissance",
    [MovementModality.Other]: "Autre",
  } as Record<MovementModality, string>,
  [MOVEMENT.RPE]: {
    NONE: {
      LABEL: "Pas de ressenti",
    },
    [AssessmentRatingOfPerceivedExertion.NoEffort]: {
      LABEL: "Aucun effort",
      DESCRIPTION: "Comme si j'étais assis sur mon canap' ",
      EMOJI: "😎",
    },
    [AssessmentRatingOfPerceivedExertion.ExtremelyEasy]: {
      LABEL: "Extrêmement facile",
      DESCRIPTION: "Je pourrais faire ça toute la journée sans transpirer",
      EMOJI: "😄",
    },
    [AssessmentRatingOfPerceivedExertion.VeryEasy]: {
      LABEL: "Très facile",
      DESCRIPTION: "Ca me chatouille à peine",
      EMOJI: "😁",
    },
    [AssessmentRatingOfPerceivedExertion.Easy]: {
      LABEL: "Facile",
      DESCRIPTION: "Je peux facilement discuter et je respire normalement",
      EMOJI: "😊",
    },
    [AssessmentRatingOfPerceivedExertion.SomewhatEasy]: {
      LABEL: "Un peu facile",
      DESCRIPTION:
        "Je fais un effort léger qui ne m'empêche pas de pouvoir parler",
      EMOJI: "🙂",
    },
    [AssessmentRatingOfPerceivedExertion.Moderate]: {
      LABEL: "Moyenne",
      DESCRIPTION:
        "Je fais un effort pour suivre le rythme, mais je ne le trouve pas trop intense",
      EMOJI: "😐",
    },
    [AssessmentRatingOfPerceivedExertion.SomewhatHard]: {
      LABEL: "Un peu dur",
      DESCRIPTION:
        "Ma respiration s'accélère, c'est challengeant mais je peux gérer",
      EMOJI: "😟",
    },
    [AssessmentRatingOfPerceivedExertion.Hard]: {
      LABEL: "Dur",
      DESCRIPTION:
        "L'effort commence à être important, si je parle ça devient vite inconfortable",
      EMOJI: "😰",
    },
    [AssessmentRatingOfPerceivedExertion.VeryHard]: {
      LABEL: "Très dur",
      DESCRIPTION:
        "La situation se complique, je dois me concentrer et me motiver pour terminer la session",
      EMOJI: "😵‍💫",
    },
    [AssessmentRatingOfPerceivedExertion.ExtremelyHard]: {
      LABEL: "Extrêmement dur",
      DESCRIPTION:
        "Quasi-impossible de parler, je dois tout donner pour être capable de finir",
      EMOJI: "🥵",
    },
    [AssessmentRatingOfPerceivedExertion.MaxEffort]: {
      LABEL: "Effort maximal",
      DESCRIPTION:
        "Impossible de faire plus, je dois mettre toute mon énergie pour ce cours",
      EMOJI: "😵",
    },
  } as Record<
    AssessmentRatingOfPerceivedExertion,
    { LABEL: string; DESCRIPTION: string; EMOJI: string }
  >,
  [MOVEMENT.EQUIPMENT]: {
    NONE: "Aucun",
    [EnvironmentalEquipment.ElasticBand]: "Bandes élastiques",
    [EnvironmentalEquipment.Barbell]: "Barre",
    [EnvironmentalEquipment.Dumbbells]: "Haltère",
    [EnvironmentalEquipment.Kettlebell]: "Kettlebell",
    [EnvironmentalEquipment.Chair]: "Chaise",
    [EnvironmentalEquipment.FoamRoller]: "Foam roller",
    [EnvironmentalEquipment.JumpRope]: "Corde à sauter",
    [EnvironmentalEquipment.Block]: "Bloc",
    [EnvironmentalEquipment.Mat]: "Tapis",
    [EnvironmentalEquipment.PullUpBar]: "Barre de traction",
    [EnvironmentalEquipment.Stepper]: "Stepper",
  } as Record<EnvironmentalEquipment & "NONE", string>,
  [MOVEMENT.DISTANCE]: {
    NONE: {
      LABEL: "Non spécifiée",
    },
  },
  [MOVEMENT.WIZARD]: {
    INTRODUCTION: {
      TITLE: "Pas sûr.e de quoi faire ?",
      DESCRIPTION: "Planifie ta séance à l'aide de MoveAI.",
      CTAS: {
        TRY: "Essaye MoveAI",
      },
    },
    QUESTIONS: {
      CTAS: {
        SKIP: "Passer",
        NEXT: "Suivant",
      },
      [MovementWizardQuestion.CurrentMovementVibe]: {
        TITLE: "Ta vibe du moment",
        PROMPT: "Ton état d'esprit",
        OPTIONS: [
          "Grosse flemme...",
          "Je cherche un truc chill",
          "Je suis prêt.e à bouger",
          "Chaud pour un challenge !",
        ],
      },
      [MovementWizardQuestion.LatestMovement]: {
        TITLE: "Ton mouvement aujourd'hui",
        PROMPT: "As-tu beaucoup bougé récemment ?",
        OPTIONS: [
          "Assis.e toute la journée",
          "Un peu bougé",
          "Beaucoup bougé",
          "Déjà fait mon sport",
        ],
      },
      [MovementWizardQuestion.PhyiscalDiscomforts]: {
        TITLE: "Une limite à ton mouvement",
        PROMPT: "As-tu mal quelque part ?",
        OPTIONS: [] as string[],
        SELECT: {
          LABEL: "Zone du corps",
          TITLE: "Select an area",
          PLACEHOLDER: "Aucune zone",
        },
      },
    },
    RECAP: {
      CTA: "Voir recos",
    },
  },
  [MOVEMENT.LOW_IMPACT]: {
    true: "Faible impact",
    false: "",
  },
}

import isNil from "lodash/isNil"
import { ModalOrchestrationName } from "../../../contexts/ModalOrchestrationContext"
import {
  GetFollowAlongDocument,
  GetFollowAlongQuery,
  GetFollowAlongQueryVariables,
  MovementModality,
  SqualoAdventureActivity,
  SqualoFollowAlongVideo,
} from "../../../generated/graphql"
import Modal, { ModalSize } from "../../Core/Modal"
import clsx from "clsx"
import { getModalityTextColor } from "../../../utils/movementUtils"
import { MovementIcon } from "../../Core/Icons"
import { useQuery } from "@apollo/client"
import { FollowAlongPreview } from "../../Movement/FollowAlongPreview"
import { SuggestedExternalLinkPreview } from "../../Movement/SuggestedExternalLinkPreview"

interface AdventureActivityModalBodyProps {
  activity: SqualoAdventureActivity
}

const AdventureActivityModalBody: React.FC<AdventureActivityModalBodyProps> = ({
  activity,
}) => {
  const { data } = useQuery<GetFollowAlongQuery, GetFollowAlongQueryVariables>(
    GetFollowAlongDocument,
    {
      skip: isNil(activity.squaloFollowAlongUuid),
      variables: {
        uuid: activity.squaloFollowAlongUuid,
      },
    }
  )

  return (
    <div className="flex flex-col items-center justify-between w-full h-full p-0 bg-neutral-100">
      <div
        className={clsx(
          `bg-${activity.movementModality}`,
          getModalityTextColor(activity.movementModality),
          "w-full"
        )}
      >
        <div className="relative flex flex-col self-start justify-between w-full p-4 mx-auto justify-self-start gap-y-4">
          <div className="flex flex-col justify-between w-full gap-y-1">
            <div
              className={clsx(
                "flex flex-row items-center justify-between gap-x-4 w-full"
              )}
            >
              <div
                className={clsx("flex flex-row items-center gap-x-2 w-full")}
              >
                <span
                  className={clsx(
                    "text-2xl font-semibold w-full",
                    `text-white`
                  )}
                >
                  {activity.title}
                </span>
              </div>

              <MovementIcon
                element={activity}
                className="flex-shrink-0 w-10 h-10 p-1"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-start w-full h-full px-4 py-4 gap-y-4">
        {!isNil(data) && (
          <FollowAlongPreview
            className="shadow-none"
            followAlong={data.getFollowAlong as SqualoFollowAlongVideo}
          />
        )}

        {!isNil(activity.suggestedExternalLink) && (
          <div className="flex flex-col w-full gap-y-2">
            <SuggestedExternalLinkPreview
              movementModality={MovementModality.Knowledge}
              suggestedExternalLink={activity.suggestedExternalLink}
              className="h-32"
            />
          </div>
        )}

        {!isNil(activity.description) && (
          <div className="flex flex-col w-full h-full p-4 rounded-md shadow-inner bg-neutral/5 gap-y-2">
            <p className="whitespace-pre-wrap text-neutral-700">
              {activity.description}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
export const AdventureActivityModal: React.FC<{
  movementModality?: MovementModality
}> = () => {
  return (
    <Modal
      name={ModalOrchestrationName.AdventureActivity}
      isSheet
      initialSize={ModalSize.Full}
    >
      <Modal.Body>
        {(props: any) => <AdventureActivityModalBody {...props} />}
      </Modal.Body>
    </Modal>
  )
}

import clsx from "clsx"
import { isNil } from "lodash"
import * as React from "react"
import {
  MovementAgendaItem,
  MovementAgendaItemSummaryFragment,
} from "../../generated/graphql"
import { MovementIcon } from "../Core/Icons"
import { isBodyTreeEmpty } from "../../utils/movementUtils"
import { getElementTextColor } from "../../utils/elementUtils"
import { MovementExtraInformationDetails } from "./MovementInformationDetails"

export interface AgendaItemPageHeaderProps {
  agendaItem: MovementAgendaItem
}

export const AgendaSuggestionHeader: React.FC<AgendaItemPageHeaderProps> = ({
  agendaItem,
}) => {
  const hasTargetBodyParts = !isBodyTreeEmpty(
    agendaItem.movementTargetBodyParts
  )

  const isAdventureActivity = !isNil(agendaItem) && !isNil(agendaItem.adventure)

  return (
    <div className="relative flex flex-col w-full">
      <div className={clsx(`bg-${agendaItem.movementModality}`, "text-white")}>
        {isAdventureActivity && (
          <div
            className={clsx(
              "flex flex-row items-center justify-between w-full",
              getElementTextColor(agendaItem.adventure?.squaloAdventureElement),
              `bg-${agendaItem.adventure?.squaloAdventureElement}-500`
            )}
          >
            <span className="w-full h-full px-4 py-2 font-bold uppercase bg-white/20">
              {agendaItem.adventure?.squaloAdventureName}
            </span>
          </div>
        )}
        <div className="relative flex flex-col justify-between w-full p-4 mx-auto overflow-hidden gap-y-4">
          <div className="flex flex-col justify-between w-full gap-y-1">
            <div
              className={clsx(
                "flex flex-row items-center justify-between gap-x-2 w-full"
              )}
            >
              <div
                className={clsx(
                  "flex flex-row items-center gap-x-2 w-full truncate"
                )}
              >
                <span
                  className={clsx(
                    "text-2xl font-semibold truncate w-full",
                    `text-white`
                  )}
                >
                  {agendaItem.title}
                </span>
              </div>

              <MovementIcon
                element={agendaItem}
                className="flex-shrink-0 w-10 h-10 p-1"
              />
            </div>
          </div>
        </div>
        <MovementExtraInformationDetails
          item={agendaItem as MovementAgendaItemSummaryFragment}
          isTargetBodyPartAvailable={hasTargetBodyParts}
        />
      </div>
    </div>
  )
}

import clsx from "clsx"
import { helpCircleOutline } from "ionicons/icons"
import * as React from "react"
import Button from "../../Forms/Button"
import { MovementIcon } from "../../Core/Icons"
import { useOlibaContext } from "../../../contexts/OlibaContext"
import {
  MovementAgendaItem,
  MovementAgendaItemSummaryFragment,
} from "../../../generated/graphql"
import { formatWeekday } from "../../../utils/format"
import isNil from "lodash/isNil"
import { useLocaleContext } from "../../../contexts/LocaleContext"
import { useAgendaSchedulingContext } from "../../../contexts/AgendaSchedulingContext"
import {
  getModalityContrastColor,
  getModalityTextColor,
  isBodyTreeEmpty,
} from "../../../utils/movementUtils"
import useActivityStyle from "../../../hooks/useActivityStyle"
import { getElementTextColor } from "../../../utils/elementUtils"
import {
  MovementInformationTargetBodyPartsSpan,
  MovementInformationTimingSpan,
} from "../MovementInformationDetails"

export interface AgendaItemPageHeaderProps {
  agendaItem: MovementAgendaItem
  canEdit: boolean
  handleOpenForm?: () => void
  isCoachSuggestion: boolean
}

export const AgendaItemPageHeader: React.FC<AgendaItemPageHeaderProps> = ({
  agendaItem,
  canEdit,
  handleOpenForm,
}) => {
  const { openOliba: openOliba } = useOlibaContext()
  const { formatRelativeDate, parseISODate } = useLocaleContext()
  const { today } = useAgendaSchedulingContext()

  const activityStyle = useActivityStyle(agendaItem.movementStyle)

  const hasTargetBodyParts = !isBodyTreeEmpty(
    agendaItem.movementTargetBodyParts
  )

  const isExtraInfoAvailable =
    activityStyle?.isTargetBodyPartAvailable && hasTargetBodyParts

  const date = parseISODate(agendaItem.instanceDate)

  const instanceLabel = isNil(date)
    ? formatWeekday(agendaItem.weekday, agendaItem.isRecurring)
    : formatRelativeDate(date, today.date)

  const isAdventureActivity = !isNil(agendaItem) && !isNil(agendaItem.adventure)

  const textColor = getModalityTextColor(agendaItem.movementModality)

  return (
    <div className={clsx(`bg-${agendaItem.movementModality}`, textColor)}>
      {isAdventureActivity && (
        <div
          className={clsx(
            "flex flex-row items-center justify-between w-full",
            `bg-${agendaItem.adventure?.squaloAdventureElement}-500`,
            getElementTextColor(agendaItem.adventure?.squaloAdventureElement)
          )}
        >
          <span className="w-full h-full px-4 py-2 font-bold bg-opacity-20">
            {agendaItem.adventure?.squaloAdventureName}
          </span>
        </div>
      )}
      <div className="relative flex flex-col justify-between w-full p-4 mx-auto overflow-hidden gap-y-8">
        <div className="flex flex-row items-start justify-between w-full">
          <div className="flex flex-row items-center text-sm font-semibold tracking-tighter text-opacity-90 gap-x-1">
            <span className="font-bold uppercase">{instanceLabel}</span>
          </div>

          <div className="flex flex-col items-center justify-between h-full -mt-2 -mr-2 text-opacity-80 gap-x-2">
            <Button
              icon={helpCircleOutline}
              fill="clear"
              color={getModalityContrastColor(agendaItem.movementModality)}
              iconClassName="opacity-40"
              className="absolute top-0 right-0"
              iconSlot="icon-only"
              onClick={openOliba}
            />
          </div>
        </div>

        <div className="flex flex-col justify-between w-full gap-y-1">
          <MovementInformationTimingSpan
            item={agendaItem as MovementAgendaItemSummaryFragment}
            className="-mb-1"
          />
          <div
            className={clsx(
              "flex flex-row items-center justify-between gap-x-2 w-full",
              canEdit && "cursor-pointer"
            )}
            onClick={canEdit ? () => handleOpenForm?.() : undefined}
          >
            <div className={clsx("flex flex-row items-center gap-x-2 w-full")}>
              <span className={clsx("text-3xl font-semibold w-full")}>
                {agendaItem.title}
              </span>
            </div>

            <MovementIcon
              element={agendaItem}
              className="flex-shrink-0 w-12 h-12 p-1"
            />
          </div>
        </div>
      </div>
      {isExtraInfoAvailable && (
        <div
          className={clsx(
            "flex flex-row items-center justify-between w-full border-opacity-50 border-t-[0.55px] px-4 py-2"
          )}
        >
          <MovementInformationTargetBodyPartsSpan
            className="text-lg text-opacity-90"
            isTargetBodyPartAvailable={activityStyle?.isTargetBodyPartAvailable}
            item={agendaItem as MovementAgendaItemSummaryFragment}
          />
        </div>
      )}
    </div>
  )
}

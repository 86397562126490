import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ServerError,
  createHttpLink,
  useLazyQuery,
  useMutation,
  from,
  NormalizedCacheObject,
  LazyQueryExecFunction,
  OperationVariables,
  useQuery,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import {
  graphQLResultHasError,
  offsetLimitPagination,
} from "@apollo/client/utilities"
import { Preferences } from "@capacitor/preferences"
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin"
import isNil from "lodash/isNil"
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react"

import {
  DeleteSessionDocument,
  DevicePlatform,
  GetCurrentMemberDocument,
  GetCurrentMemberQuery,
  GetCurrentUserDocument,
  GetCurrentUserQuery,
  RefreshSessionDocument,
  RefreshSessionMutation,
  RefreshSessionMutationVariables,
  UpdateMemberProfileDocument,
  UpdateMemberProfileMutation,
  UpdateMemberProfileMutationVariables,
} from "../generated/graphql"
import { getDevicePlatform } from "../hooks/useDevicePlatform"

export interface AuthenticatedClientContextState {
  platform: DevicePlatform
  isPlatformInitialized: boolean

  authToken: string | undefined

  client: ApolloClient<NormalizedCacheObject>
  isInitialized: boolean
  isClientAuthenticated: boolean

  isSessionActive: boolean
  isSessionExpired: boolean

  currentUser: GetCurrentUserQuery["currentUser"] | undefined
  currentMember: GetCurrentMemberQuery["currentMember"] | undefined

  isLoadingUser: boolean
}

export interface AuthenticatedClientContextActions {
  // actions
  login: (authToken: string, refreshToken?: string | null) => void
  logout: () => void

  updateMemberProfile: (
    memberProfile: UpdateMemberProfileMutationVariables
  ) => Promise<void>

  // queries
  refreshCurrentUser: LazyQueryExecFunction<
    GetCurrentUserQuery,
    OperationVariables
  >

  refetchActiveQueries: () => Promise<void>
}

export const AuthenticatedClientContext = createContext<
  | (AuthenticatedClientContextState & AuthenticatedClientContextActions)
  | undefined
>(undefined)

const loadLegacyUnsecureAuthToken = async () => {
  return await Preferences.get({ key: "authToken" }).then((result) => {
    if (result.value) {
      return result.value
    }
    return undefined
  })
}

const loadAuthToken = async () => {
  return await SecureStoragePlugin.get({ key: "authToken" })
    .then((result) => {
      if (result.value) {
        return result.value
      }
    })
    .catch(() => loadLegacyUnsecureAuthToken())
}

const loadRefreshToken = async () => {
  return SecureStoragePlugin.get({ key: "refreshToken" })
    .then((result) => {
      if (result.value) {
        return result.value
      }
    })
    .catch(() => undefined)
}

const saveAuthToken = async (authToken: string) => {
  await SecureStoragePlugin.set({ key: "authToken", value: authToken })
}

const saveRefreshToken = async (refreshToken: string) => {
  const platform = await getDevicePlatform()

  if (platform === DevicePlatform.Web) {
    return
  }

  await SecureStoragePlugin.set({ key: "refreshToken", value: refreshToken })
}

const deleteAuthToken = async () => {
  // remove legacy storage
  await Preferences.remove({ key: "authToken" })

  // remove secure storage
  await SecureStoragePlugin.remove({ key: "authToken" }).catch((error) => {
    console.debug("error removing auth token", error)
  })
}

const deleteRefreshToken = async () => {
  const platform = await getDevicePlatform()

  if (platform === DevicePlatform.Web) {
    return
  }

  await SecureStoragePlugin.remove({ key: "refreshToken" })
}

const buildHttpClientLink = (platform?: DevicePlatform) => {
  return createHttpLink({
    uri: process.env.REACT_APP_HIVE_API_URL,
    credentials: platform === DevicePlatform.Web ? "include" : undefined,
  })
}

const buildClientCache = () => {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          followAlongs: {
            ...offsetLimitPagination([
              "search",
              "modality",
              "styles",
              "language",
            ]),
          },
        },
      },
    },
  })
}

const buildBaseClient = (platform?: DevicePlatform) =>
  new ApolloClient({
    link: buildHttpClientLink(platform),
    cache: buildClientCache(),
  })

interface AuthenticatedClientContextReducerActions {
  type:
    | "setPlatform"
    | "loadCurrentUser"
    | "loadCurrentMember"
    | "expireAuthToken"
    | "authenticateClient"
    | "resetClient"
    | "deleteSession"
  payload?: any
}

const clientReducer = (
  state: AuthenticatedClientContextState,
  action: AuthenticatedClientContextReducerActions
): AuthenticatedClientContextState => {
  const { type, payload } = action

  switch (type) {
    case "setPlatform":
      return {
        ...state,
        platform: payload,
        isPlatformInitialized: true,
      }

    case "loadCurrentUser":
      return {
        ...state,
        isSessionActive: true,
        isLoadingUser: false,
        currentUser: payload,
      }

    case "loadCurrentMember":
      return {
        ...state,
        currentMember: payload,
      }

    case "expireAuthToken":
      return {
        ...state,
        isSessionExpired: true,
        authToken: undefined,
      }

    case "authenticateClient":
      return {
        ...state,
        authToken: payload.authToken,
        isInitialized: true,
        isSessionActive: true,
        isSessionExpired: false,
        client: payload.client,
        isClientAuthenticated: true,
      }

    case "resetClient":
      return {
        ...state,
        authToken: undefined,
        client: payload,
        isInitialized: true,
        isClientAuthenticated: false,
      }

    case "deleteSession":
      return {
        ...state,
        isSessionActive: false,
        isSessionExpired: false,
        isLoadingUser: false,
        authToken: undefined,
        currentUser: undefined,
      }

    default:
      return state
  }
}

export const useAuthenticatedClientContext = () => {
  const context = useContext(AuthenticatedClientContext)

  if (context === undefined) {
    throw new Error(
      "useAuthenticatedClientContext must be used within a AuthenticatedClientContext provider"
    )
  }

  return context
}

const initialState: AuthenticatedClientContextState = {
  platform: DevicePlatform.Web,
  isPlatformInitialized: false,
  client: buildBaseClient(DevicePlatform.Web),
  authToken: undefined,
  isInitialized: false,
  isSessionActive: false,
  isSessionExpired: false,
  isLoadingUser: true,
  isClientAuthenticated: false,
  currentUser: undefined,
  currentMember: undefined,
}

export const AuthenticatedClientProvider: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(clientReducer, initialState)

  const [refreshSession, { loading: isRefreshSessionLoading }] = useMutation<
    RefreshSessionMutation,
    RefreshSessionMutationVariables
  >(RefreshSessionDocument, {
    client: state.client,
    onCompleted: async (data) => {
      if (data.refreshSession) {
        updateClient(data.refreshSession)
        saveAuthToken(data.refreshSession)
      } else {
        dispatch({ type: "deleteSession" })
      }
    },
    onError: async () => {
      await handleLogout()

      window.location.reload()
    },
  })

  const [deleteSession] = useMutation(DeleteSessionDocument, {
    client: state.client,
  })

  const [getUser] = useLazyQuery<GetCurrentUserQuery>(GetCurrentUserDocument, {
    client: state.client,
    onCompleted: (data) => {
      if (data.currentUser) {
        dispatch({ type: "loadCurrentUser", payload: data.currentUser })
      }
    },
  })

  useQuery<GetCurrentMemberQuery>(GetCurrentMemberDocument, {
    client: state.client,
    skip: !state.isSessionActive || isNil(state.currentUser),
    onCompleted: (data) => {
      if (data.currentMember) {
        dispatch({ type: "loadCurrentMember", payload: data.currentMember })
      }
    },
  })

  const [updateMemberProfileMutation] = useMutation<
    UpdateMemberProfileMutation,
    UpdateMemberProfileMutationVariables
  >(UpdateMemberProfileDocument, {
    client: state.client,
    refetchQueries: [GetCurrentMemberDocument],
  })

  const resetClient = () => {
    dispatch({
      type: "resetClient",
      payload: buildBaseClient(state.platform),
    })
  }

  const updateClient = (authToken: string) => {
    const httpLink = buildHttpClientLink(state.platform)

    const authLink = setContext((_, { headers }) => {
      const result = {
        headers: {
          ...headers,
          Authorization: authToken ? `Bearer ${authToken}` : "",
        },
      }

      return result
    })

    const errorHandlerLink = onError(
      ({ networkError, graphQLErrors, response, ...rest }) => {
        if (response && graphQLResultHasError(response)) {
          console.warn("GraphQL error: ", response.errors?.[0]?.message || "", {
            graphQLErrors,
            response,
            ...rest,
          })

          if (
            graphQLErrors?.some(
              (error) => error.message === "not_authenticated"
            )
          ) {
            dispatch({ type: "expireAuthToken" })
          }
        }

        if (networkError && (networkError as ServerError).statusCode === 401) {
          dispatch({ type: "expireAuthToken" })
        }
      }
    )

    dispatch({
      type: "authenticateClient",
      payload: {
        authToken,
        client: new ApolloClient({
          cache: state.client.cache,
          link: from([errorHandlerLink, authLink, httpLink]),
        }),
      },
    })
  }

  const refreshCurrentUser: AuthenticatedClientContextActions["refreshCurrentUser"] =
    async (props: any) => {
      return getUser({ fetchPolicy: "cache-and-network", ...props })
    }

  const refreshClient = useCallback(async () => {
    const authToken = await loadAuthToken()

    if (isNil(authToken)) {
      console.debug("no auth token found, resetting client")

      resetClient()

      if (state.isSessionActive) {
        dispatch({ type: "deleteSession" })
      }
    } else {
      console.debug("auth token found, updating client")

      updateClient(authToken)
    }
  }, [state.platform])

  const refetchActiveQueries = async () => {
    await state.client.refetchQueries({ include: "active" }).catch((error) => {
      console.warn("error refetching queries", error)
    })
  }

  const handleExpiredSession = async () => {
    if (isRefreshSessionLoading) {
      return
    }

    if (state.platform === DevicePlatform.Web) {
      await refreshSession()
    } else {
      const refreshToken = await loadRefreshToken()

      if (refreshToken) {
        await refreshSession({ variables: { refreshToken } })
      } else {
        await handleLogout()
        window.location.reload()
      }
    }
  }

  const updateMemberProfile = async (
    input: Partial<UpdateMemberProfileMutationVariables>
  ) => {
    const variables = {
      ...state.currentMember,
      ...input,
    }

    await updateMemberProfileMutation({ variables })
  }

  const handleLogin = async (
    newAuthToken: string,
    refreshToken?: string | null
  ) => {
    updateClient(newAuthToken)

    await saveAuthToken(newAuthToken)

    if (refreshToken) {
      await saveRefreshToken(refreshToken)
    }
  }

  const handleLogout = async () => {
    dispatch({ type: "deleteSession" })

    // delete session on server
    await deleteSession()

    // delete auth token
    await deleteAuthToken()

    // delete refresh token
    await deleteRefreshToken()

    // reset client
    await state.client.resetStore()

    resetClient()
  }

  useEffect(() => {
    if (state.isSessionExpired) {
      if (state.isClientAuthenticated) {
        resetClient()
      } else if (!isRefreshSessionLoading) {
        handleExpiredSession()
      }
    }
  }, [
    state.isSessionExpired,
    state.authToken,
    state.isClientAuthenticated,
    isRefreshSessionLoading,
  ])

  useEffect(() => {
    if (
      !state.isSessionExpired &&
      state.isClientAuthenticated &&
      state.isLoadingUser
    ) {
      getUser({ fetchPolicy: "network-only", client: state.client })
    }
  }, [state.isSessionExpired, state.isClientAuthenticated, state.isLoadingUser])

  useEffect(() => {
    // reinitialize client on app open
    if (state.isPlatformInitialized) {
      refreshClient()
    }
  }, [state.isPlatformInitialized, refreshClient])

  useEffect(() => {
    // initialize client with platform
    getDevicePlatform().then((platform) => {
      console.debug("initialize client with platform", platform)
      dispatch({ type: "setPlatform", payload: platform })
    })
  }, [])

  const value = {
    ...state,
    platform: state.platform || DevicePlatform.Web,
    login: handleLogin,
    logout: handleLogout,
    refreshCurrentUser,
    refetchActiveQueries,
    updateMemberProfile,
  }

  return (
    <AuthenticatedClientContext.Provider value={value}>
      <ApolloProvider client={state.client}>{children}</ApolloProvider>
    </AuthenticatedClientContext.Provider>
  )
}

import { IonButton, IonIcon } from "@ionic/react"
import classNames from "clsx"
import isNil from "lodash/isNil"
import React from "react"
import { IconType } from "react-icons/lib"

import { MovementModality } from "../../generated/graphql"
import { ModalityIcon } from "../Movement/Modality"

export interface ButtonProps extends React.ComponentProps<typeof IonButton> {
  label?: string
  textColor?: string
  icon?: string | IconType
  iconSlot?: "start" | "end" | "icon-only"
  iconProps?: any
  paddingWidth?: "default" | "thin"
  labelClassName?: string
  iconClassName?: string
  onClick?: () => void
  Component?: any
}

export const ButtonIcon = ({
  iconClassName,
  icon,
  iconSlot,
  iconProps,
}: ButtonProps) => {
  if (!icon) {
    return null
  }

  if (Object.values(MovementModality).includes(icon as MovementModality)) {
    return (
      <ModalityIcon
        modality={icon as MovementModality}
        withBackground={false}
        color="white"
        className={classNames("mr-2", iconClassName)}
        side={4}
      />
    )
  }

  if (typeof icon === "string") {
    return (
      <IonIcon
        icon={icon}
        slot={iconSlot}
        className={iconClassName}
        {...iconProps}
      />
    )
  }

  const Icon = icon as IconType

  return (
    <Icon
      className={classNames("min-w-6 min-h-6", iconClassName)}
      {...iconProps}
    />
  )
}

const Button: React.FC<ButtonProps> = ({
  label = "",
  className,
  children,
  icon,
  iconSlot = "start",
  iconProps,
  iconClassName,
  labelClassName,
  textColor,
  fill = "solid",
  paddingWidth = "default",
  color,
  Component,
  ...rest
}) => {
  if (!isNil(Component)) {
    return <Component {...rest} />
  }

  return (
    <IonButton
      className={classNames(
        "font-bold",
        textColor && `text-${textColor} fill-${textColor}`,
        className
      )}
      aria-label={label || "button"}
      fill={fill}
      color={color}
      style={{
        ...(!isNil(textColor) ? { "--color": "inherit" } : {}),
        ...(fill === "clear"
          ? {
              "--background": "transparent",
              "--background-activated": "transparent",
              "--background-hover": "transparent",
              "--background-focus": "transparent",
            }
          : {}),
        ...(paddingWidth === "thin"
          ? { "--padding-start": "0.625rem", "--padding-end": "0.625rem" }
          : {}),
      }}
      {...rest}
    >
      {icon && iconSlot !== "end" && (
        <ButtonIcon
          icon={icon}
          iconSlot={iconSlot}
          iconProps={iconProps}
          iconClassName={iconClassName}
        />
      )}
      {children ||
        (label ? (
          <span
            className={classNames(
              "font-semibold tracking-tight flex-grow",
              fill === "clear" && "underline",
              textColor && `text-${textColor}`,
              labelClassName
            )}
          >
            {label}
          </span>
        ) : null)}
      {icon && iconSlot === "end" && (
        <ButtonIcon
          icon={icon}
          iconSlot={iconSlot}
          iconProps={iconProps}
          iconClassName={iconClassName}
          color={textColor}
        />
      )}
    </IonButton>
  )
}

export default Button

import {
  Language,
  SqualoAdventureElement,
  Weekday,
} from "../../generated/graphql"
import { COMMON } from "../constants"

export default {
  EVERY: "Every {{value}}",
  ANY: "Any {{value}}",
  WITH_TIME: "{{value}} at {{time}}",
  TERMS_AND_CONDITIONS: "Terms and conditions",
  PRIVACY_POLICY: "Privacy policy",
  WEEK_COUNT: "{{count}} weeks",
  RECOMMENDED: "Recommended",
  POPULAR: "Popular",
  FAVORITES: "Favorites",
  [COMMON.WEEKDAYS]: {
    [Weekday.Monday]: "Monday",
    [Weekday.Tuesday]: "Tuesday",
    [Weekday.Wednesday]: "Wednesday",
    [Weekday.Thursday]: "Thursday",
    [Weekday.Friday]: "Friday",
    [Weekday.Saturday]: "Saturday",
    [Weekday.Sunday]: "Sunday",
  },
  [COMMON.FORMS]: {
    SUBMIT: "Submit",
    NEXT: "Next",
    CONTINUE: "Continue",
    CONFIRM: "Confirm",
    CANCEL: "Cancel",
    SAVE: "Save",
    CLEAR: "Clear",
    SELECT: "Select",
    OKAY: "OK",
  },
  [COMMON.ELEMENTS]: {
    [SqualoAdventureElement.Air]: "Air",
    [SqualoAdventureElement.Earth]: "Earth",
    [SqualoAdventureElement.Fire]: "Fire",
    [SqualoAdventureElement.Water]: "Water",
  } as Record<SqualoAdventureElement, string>,
  [COMMON.TABS]: {
    HOME: "Today",
    AGENDA: "Agenda",
    DISCOVER: "Discover",
    PROFILE: "Profile",
  },
  [COMMON.LANGUAGES]: {
    ALL: "All languages",
    [Language.Fr]: "🇫🇷 Français",
    [Language.En]: "🇬🇧 English",
  },
}

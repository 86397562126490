import { MovementWizardQuestion } from "../MovementWizardQuestion"
import {
  MovementWizardSlide,
  MovementWizardSlideProps,
} from "../MovementWizardSlide"

export const CurrentMovementVibeQuestion: React.FC<
  Partial<MovementWizardSlideProps<number>>
> = ({ ...props }) => {
  return (
    <MovementWizardSlide
      question={MovementWizardQuestion.CurrentMovementVibe}
      {...props}
    />
  )
}

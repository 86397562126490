import Carousel from "nuka-carousel/lib/carousel"
import {
  BsEmojiLaughing,
  BsEmojiExpressionless,
  BsEmojiSunglasses,
  BsEmojiSmile,
} from "react-icons/bs"
import { BiRun } from "react-icons/bi"
import { useCallback, useState } from "react"
import { IconType } from "react-icons/lib"
import { MdChair } from "react-icons/md"
import { RiRunLine } from "react-icons/ri"
import { IoWalk } from "react-icons/io5"
import {
  BodyJointEnum,
  GetMovementRecommendationDocument,
  GetMovementRecommendationQuery,
  GetMovementRecommendationQueryVariables,
} from "../../../generated/graphql"
import { YinBehaleIcon } from "../../BehaleIcon"
import VideoExplorerModal from "../../Mico/Modals/VideoExplorerModal"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../../contexts/ModalOrchestrationContext"
import {
  MovementWizardQuestion,
  QUESTION_ORDER,
} from "./MovementWizardQuestion"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../../contexts/AnalyticsContext"
import { useQuery } from "@apollo/client"
import { PhysicalDiscomfortsQuestion } from "./Slides/PhysicalDiscomfortsQuestion"
import { CurrentMovementVibeQuestion } from "./Slides/CurrentMovementVibeQuestion"
import { LatestMovementQuestion } from "./Slides/LatestMovementQuestion"
import { MovementWizardRecapSlide } from "./Slides/MovementWizardRecapSlide"
import { MoveRecommendationStartSlide } from "./Slides/MovementWizardStartSlide"

interface MovementWizardQuestionOption {
  value: number
  Icon: IconType
  color?: string
  className?: string
  onClick?: () => void
}

export const QUESTION_OPTIONS = {
  [MovementWizardQuestion.CurrentMovementVibe]: [
    {
      value: 0,
      Icon: BsEmojiExpressionless,
      color: "neutral",
    },
    {
      value: 1,
      Icon: BsEmojiSmile,
      color: "MOBILITY",
    },
    {
      value: 2,
      Icon: BsEmojiLaughing,
      color: "STRENGTH",
    },
    {
      value: 3,
      Icon: BsEmojiSunglasses,
      color: "CARDIO",
    },
  ],
  [MovementWizardQuestion.LatestMovement]: [
    {
      value: 0,
      Icon: MdChair,
      color: "neutral",
    },
    {
      value: 1,
      Icon: IoWalk,
      color: "MOBILITY",
    },
    {
      value: 2,
      Icon: RiRunLine,
      color: "STRENGTH",
    },
    {
      value: 3,
      Icon: BiRun,
      color: "CARDIO",
    },
  ],
} as { [key in MovementWizardQuestion]: MovementWizardQuestionOption[] }

export interface MovementWizardState {
  status: "INTRO" | "QUESTIONS" | "RECAP"

  [MovementWizardQuestion.LatestMovement]: number | undefined
  [MovementWizardQuestion.CurrentMovementVibe]: number | undefined
  [MovementWizardQuestion.PhyiscalDiscomforts]: BodyJointEnum[] | undefined
}

export const MovementWizard: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const { openModal } = useModalOrchestrationContext()

  const { captureEvent } = useAnalyticsContext()

  const [state, setState] = useState<MovementWizardState>({
    status: "INTRO",
    [MovementWizardQuestion.LatestMovement]: undefined,
    [MovementWizardQuestion.CurrentMovementVibe]: undefined,
    [MovementWizardQuestion.PhyiscalDiscomforts]: undefined,
  })

  const [sliding, setSliding] = useState<boolean>(false)

  const { data } = useQuery<
    GetMovementRecommendationQuery,
    GetMovementRecommendationQueryVariables
  >(GetMovementRecommendationDocument, {
    skip: state.status === "RECAP",
    variables: {
      input: {
        currentMovementVibeLevel:
          state[MovementWizardQuestion.CurrentMovementVibe],
        currentAreasOfPhysicalDiscomfort:
          state[MovementWizardQuestion.PhyiscalDiscomforts],
        recentMovementLevel: state[MovementWizardQuestion.LatestMovement],
      },
    },
  })

  const handleStartClick = () => {
    setState((state) => ({ ...state, status: "QUESTIONS" }))

    captureEvent(AnalyticsEvent.MovementWizardStartClicked, {})

    setSelectedIndex(selectedIndex + 1)
  }

  const handleNextClick = (isSkip?: boolean) => {
    if (sliding) {
      return
    }

    captureEvent(AnalyticsEvent.MovementWizardNextClicked, {
      question: QUESTION_ORDER[selectedIndex - 1],
      answer: state[QUESTION_ORDER[selectedIndex - 1]],
      isSkip,
    })

    setSliding(true)

    if (selectedIndex <= QUESTION_ORDER.length) {
      setSelectedIndex(selectedIndex + 1)
    } else {
      setState((state) => ({ ...state, status: "RECAP" }))
    }
  }

  const handleGenerateClick = useCallback(() => {
    captureEvent(AnalyticsEvent.MovementWizardGenerateClicked, {})

    const recommendations = data?.getMovementRecommendation || []

    openModal(ModalOrchestrationName.VideoExplorer, {
      videos: recommendations,
    })
  }, [data, state, openModal, captureEvent])

  const handleSelect = (question: MovementWizardQuestion, value: any) => {
    setState((state) => ({ ...state, [question]: value }))
  }

  const handleBeforeSlide = (currentIndex: number, newIndex: number) => {
    setSliding(true)

    setSelectedIndex(newIndex)

    setState((state) => ({ ...state, status: "QUESTIONS" }))
  }

  return (
    <div className="py-4">
      <Carousel
        renderCenterLeftControls={() => <></>}
        renderCenterRightControls={() => <></>}
        renderBottomCenterControls={() => <></>}
        slideIndex={selectedIndex}
        autoplay={false}
        swiping={false}
        wrapAround={false}
        beforeSlide={handleBeforeSlide}
        afterSlide={() => {
          setSliding(false)
        }}
      >
        <MoveRecommendationStartSlide onClick={handleStartClick} />
        <LatestMovementQuestion
          defaultValue={state[MovementWizardQuestion.LatestMovement]}
          onSubmit={handleNextClick}
          onSelect={handleSelect}
        />
        <CurrentMovementVibeQuestion
          defaultValue={state[MovementWizardQuestion.CurrentMovementVibe]}
          onSubmit={handleNextClick}
          onSelect={handleSelect}
        />
        <PhysicalDiscomfortsQuestion
          defaultValue={state[MovementWizardQuestion.PhyiscalDiscomforts]}
          onSubmit={handleNextClick}
          onSelect={handleSelect}
        />
        <MovementWizardRecapSlide
          onClick={handleGenerateClick}
          state={state}
          setSelectedIndex={setSelectedIndex}
        />
      </Carousel>

      <VideoExplorerModal
        title={
          <div className="flex flex-row items-center justify-center gap-x-1">
            <YinBehaleIcon
              className="w-5 h-5 fill-current text-primary"
              width={20}
              height={20}
            />
            <span className="text-lg font-bold tracking-tighter text-primary">
              Move AI
            </span>
            <span className="px-2 mx-2 text-base font-semibold text-white rounded-full bg-primary/50">
              beta
            </span>
          </div>
        }
      />
    </div>
  )
}

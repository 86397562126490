import { useIonRouter } from "@ionic/react"
import React, { useContext } from "react"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../../contexts/ModalOrchestrationContext"

import {
  SearchFollowAlongsQueryVariables,
  SqualoFollowAlongVideo,
} from "../../../generated/graphql"
import Modal, { ModalContext, ModalProps } from "../../Core/Modal"
import { MicoVideoGalleryContent } from "../MicoVideoGalleryTab"
import FollowAlongPreviewModal from "./FollowAlongPreviewModal"

export interface VideoExplorerModalBodyProps {
  movementModality?: string
  videos: SqualoFollowAlongVideo[]
  filters?: SearchFollowAlongsQueryVariables
}

export const VideoExplorerModalBody: React.FC<VideoExplorerModalBodyProps> = ({
  videos,
}) => {
  const router = useIonRouter()
  const { name } = useContext(ModalContext)

  const { closeModal, openModal } = useModalOrchestrationContext()

  const handleSelect = (followAlong: SqualoFollowAlongVideo) => {
    openModal(
      ModalOrchestrationName.FollowAlongPreview,
      {
        followAlong,
      },
      {
        onClose: handlePreviewClose,
      }
    )
  }

  const handlePreviewClose = (reason?: string, data?: any) => {
    if (reason === "started") {
      closeModal(name, "start", data)

      const videoPath = `/app/hub/discover/videos/${data.uuid}`

      router.push(videoPath, "forward")
    } else if (reason === "scheduled") {
      closeModal(name, "schedule", data)

      const itemPath = `/app/hub/agenda/items/${data.uuid}`

      router.push(itemPath, "forward")
    }
  }

  return (
    <>
      <MicoVideoGalleryContent videos={videos} onSelect={handleSelect} />
      <FollowAlongPreviewModal />
    </>
  )
}

export type VideoExplorerModalProps = Partial<ModalProps>

export const VideoExplorerModal: React.FC<VideoExplorerModalProps> = ({
  name = ModalOrchestrationName.VideoExplorer,
  ...props
}) => {
  return (
    <Modal name={name} fullScreen {...props}>
      <Modal.Header showCloseButton />

      <Modal.Body>
        {(props: any) => <VideoExplorerModalBody {...props} />}
      </Modal.Body>
    </Modal>
  )
}

export default VideoExplorerModal

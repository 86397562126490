import { useIonActionSheet, useIonRouter } from "@ionic/react"
import clsx from "clsx"
import { isSameDay } from "date-fns"
import { syncOutline } from "ionicons/icons"
import { isNil } from "lodash"
import capitalize from "lodash/capitalize"
import React, { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Content from "../../components/Core/Content"

import Page from "../../components/Core/Page"
import { AgendaSuggestionModal } from "../../components/Movement/Modals/AgendaSuggestionModal"
import AgendaTimeline from "../../components/Movement/AgendaManagement/AgendaTimeline"
import { AgendaTimelineIndex } from "../../components/Movement/AgendaManagement/AgendaTimelineIndex"
import { MovementAgendaItemFormModal } from "../../components/Movement/Modals/MovementAgendaItemFormModal"
import { MovementSessionFormModal } from "../../components/Movement/Modals/MovementSessionFormModal"
import { AgendaDay } from "../../contexts/AgendaSchedulingContext"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { useLocaleContext } from "../../contexts/LocaleContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import { useOlibaContext } from "../../contexts/OlibaContext"
import {
  MovementAgendaItemSummaryFragment,
  Weekday,
} from "../../generated/graphql"
import { MOVEMENT, NAME_SPACES } from "../../locales/constants"
import { getCurrentEnvironment, getWeekdayDate } from "../../utils"
import useRouterQueryParams from "../../hooks/useRouterQueryParams"
import { ButtonProps } from "../../components/Forms/Button"
import { RiTimeFill } from "react-icons/ri"
import { AgendaHomeConversionHook } from "../../components/Conversion/AgendaHomeConversionHook"
import { AgendaSuggestionInboxToggleButton } from "../../components/Movement/AgendaManagement/AgendaSuggestionInbox"

const AgendaHomePage: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.AGENDA_HOME, { returnObjects: true })

  const query = useRouterQueryParams()

  const router = useIonRouter()
  const [present] = useIonActionSheet()

  const { formatDate } = useLocaleContext()
  const { captureEvent } = useAnalyticsContext()
  const { openOliba: openChat } = useOlibaContext()

  const {
    today,
    isReady,
    isToday,
    isPast,
    isFuture,
    selectedWeekStartDate,
    selectWeekDate,
    selectedWeekUuid,
    selectWeekday,
    selectToday,
    canAccessSelectedWeek,
    modifyAgendaItem,
    refetchAgenda,
    suggestionInboxItems,
  } = useAgendaSchedulingContext()

  const { openModal } = useModalOrchestrationContext()

  const [refreshing, setRefreshing] = React.useState(false)
  const [selectingToday, setSelectingToday] = React.useState(false)
  const [inboxOpen, setInboxOpen] = React.useState(false)

  const openSessionForm = (props: any) => {
    openModal(ModalOrchestrationName.MovementSessionForm, props)
  }

  const openAgendaItemForm = (props: any) => {
    openModal(ModalOrchestrationName.MovementAgendaItemForm, props)
  }

  const handleSuggestionModalClose = (reason?: string, data?: any) => {
    if (reason === "openChat") {
      openChat()
    } else if (reason === "redirect") {
      router.push(`/app/hub/agenda/items/${data}`, "forward")
    }
  }

  const handleAddClick = (day: AgendaDay) => {
    captureEvent(AnalyticsEvent.AgendaAddElementButtonClicked, {
      day: day.weekday,
      isToday: isToday(day),
      isPast: isPast(day),
      isFuture: isFuture(day),
    })

    if (isSameDay(day.date, today.date)) {
      // day is today
      present({
        header: TEXT.ADD_SESSION,
        buttons: [
          {
            text: TEXT.LOG_SESSION,
            handler: () => openSessionForm({ day }),
          },
          {
            text: TEXT.PLAN_SESSION,
            handler: () => openAgendaItemForm({ day }),
          },
          {
            text: TEXT.CANCEL,
            role: "cancel",
          },
        ],
      })
    } else if (isPast(day)) {
      // day is in the past
      openSessionForm({ day })
    } else if (isFuture(day)) {
      // day is in the future
      openAgendaItemForm({ day })
    }
  }

  const handleSelectTodayClick = () => {
    captureEvent(AnalyticsEvent.AgendaHomeTodayButtonClicked)

    selectToday()

    setSelectingToday(true)

    setTimeout(() => {
      setSelectingToday(false)
    }, 1000)
  }

  const handleInboxClick = useCallback(() => {
    captureEvent(AnalyticsEvent.AgendaHomeInboxButtonClicked)

    setInboxOpen(!inboxOpen)
  }, [captureEvent, inboxOpen])

  const handleRefresh = () => {
    setRefreshing(true)

    refetchAgenda(() => {
      setTimeout(() => setRefreshing(false), 5_000)
    })
  }

  const handleAddScheduleElement = (day: AgendaDay) => {
    if (!canAccessSelectedWeek) return

    selectWeekday(day.weekday)
    handleAddClick(day)
  }

  const changeItemWeekday = useCallback(
    async (
      item: MovementAgendaItemSummaryFragment,
      weekday: Weekday,
      onlyThisWeek: boolean
    ) => {
      if (!canAccessSelectedWeek) return

      const instanceDate = formatDate(
        getWeekdayDate(selectedWeekStartDate, weekday),
        "yyyy-MM-dd"
      )

      const variables = {
        uuid: item.uuid,
        weekday,
        instanceDate: onlyThisWeek ? instanceDate : undefined,
        movementWeekUuid: onlyThisWeek ? selectedWeekUuid : undefined,
      }

      await modifyAgendaItem(variables, false)
    },
    [selectedWeekUuid, selectedWeekStartDate, canAccessSelectedWeek]
  )

  useEffect(() => {
    if (suggestionInboxItems.length === 0 && inboxOpen) {
      setInboxOpen(false)
    }
  }, [suggestionInboxItems, inboxOpen])

  // effect - set selected week start date from query param
  useEffect(() => {
    const week = query.get("week")

    if (isNil(week)) return
    if (!isReady) return

    const date = new Date(week)

    selectWeekDate(date)
  }, [query, isReady])

  useEffect(() => {
    const timer = setInterval(() => {
      refetchAgenda()
    }, 4_000)

    return () => clearInterval(timer)
  }, [])

  const toolbarButtons = [
    {
      Component: () => (
        <AgendaSuggestionInboxToggleButton onClick={handleInboxClick} />
      ),
    },
    {
      icon: RiTimeFill,
      iconSlot: "icon-only",
      className: "fill-current",
      textColor: "white",
      fill: "clear",
      onClick: handleSelectTodayClick,
      iconClassName: clsx(selectingToday ? "animate-spin" : ""),
      hidden: true,
    },
  ] as ButtonProps[]

  if (getCurrentEnvironment() !== "production") {
    toolbarButtons.push({
      icon: syncOutline,
      iconSlot: "icon-only",
      textColor: "white",
      fill: "clear",
      hidden: true,
      onClick: handleRefresh,
      iconClassName: clsx(refreshing ? "animate-spin" : ""),
    })
  }

  const title = capitalize(
    formatDate(selectedWeekStartDate, "MMMM yyyy")
  ).split(" ")

  return (
    <Page>
      <Page.Header
        title={title[0]}
        subtitle={title[1]}
        toolbarButtons={toolbarButtons}
        color="primary"
        // className="h-36"
      >
        <AgendaTimelineIndex />
      </Page.Header>
      <Content
        color="neutral-100"
        slot="fixed"
        scrollX={false}
        scrollY={false}
        className="bg-neutral-100"
      >
        <div className="flex w-full h-full max-h-full bg-neutral-100">
          <div className="w-full h-full max-w-lg mx-auto">
            <AgendaTimeline
              addScheduleElement={handleAddScheduleElement}
              changeItemWeekday={changeItemWeekday}
              showSuggestionInbox={inboxOpen}
            />
            {!canAccessSelectedWeek && <AgendaHomeConversionHook />}
          </div>
        </div>
      </Content>

      <MovementSessionFormModal />
      <MovementAgendaItemFormModal />
      <AgendaSuggestionModal onClose={handleSuggestionModalClose} />
    </Page>
  )
}

export default AgendaHomePage

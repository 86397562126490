import classNames from "clsx"
import isNil from "lodash/isNil"
import React from "react"
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd"
import { RiMenuLine } from "react-icons/ri"

import {
  MovementAgendaItemStatus,
  MovementAgendaItemSummaryFragment,
} from "../../../generated/graphql"
import PingContainer from "../../Core/Ping"
import { RecurrenceIcon } from "../../Core/Icons"
import { MovementStyleSpan } from "../Style"
import { BaseWeekdayComponent } from "./BaseWeekdayComponent"
import {
  MovementInformationTargetBodyPartsSpan,
  MovementInformationTimingSpan,
} from "../MovementInformationDetails"

export const AgendaTimelineDayItem: React.FC<{
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  item: MovementAgendaItemSummaryFragment
  onClick?: () => void
}> = ({ item, provided, snapshot, onClick }) => {
  const isSuggestion = item.status === MovementAgendaItemStatus.Suggested

  const routerLink = isSuggestion
    ? undefined
    : `/app/hub/agenda/items/${item.uuid}`

  return (
    <PingContainer
      containerClassName={classNames(
        "relative flex flex-row items-center h-10 select-none",
        "z-10 bg-white shadow-md rounded-sm",
        snapshot.isDragging && ""
      )}
      className={"-top-1 -right-1"}
      showPing={isSuggestion}
      color={item.adventure?.squaloAdventureElement || item.movementModality}
      style={provided.draggableProps.style}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <BaseWeekdayComponent
        className={classNames(
          "flex flex-row items-center w-full",
          "z-10 bg-white rounded-sm",
          isSuggestion && `border-2 border-${item.movementModality}`,
          !isNil(item.adventure) &&
            `border-l-8 border-${item.adventure.squaloAdventureElement}-500`
        )}
        onClick={isSuggestion ? onClick : undefined}
        routerLink={routerLink}
        routerDirection="forward"
      >
        <div className="flex flex-row items-center flex-grow p-2 overflow-hidden truncate gap-x-1">
          <div className="flex flex-row items-center text-sm gap-x-1 text-neutral">
            <MovementStyleSpan element={item} className="truncate" />
          </div>

          <MovementInformationTargetBodyPartsSpan
            item={item}
            isTargetBodyPartAvailable={!isNil(item.movementTargetBodyParts)}
            className="text-sm gap-x-1 text-neutral-500/80"
          />
        </div>
        <div className="flex flex-row items-center flex-shrink-0 px-2 gap-x-2 text-neutral-500">
          <MovementInformationTimingSpan item={item} className="text-sm" />

          {item.isRecurring && (
            <RecurrenceIcon
              className={classNames("w-6 h-6", "text-neutral-500")}
            />
          )}

          <RiMenuLine className="w-6 h-6 text-neutral-400" />
        </div>
      </BaseWeekdayComponent>
    </PingContainer>
  )
}

import { useTranslation } from "react-i18next"
import { MEMBERSHIP, NAME_SPACES } from "../../locales/constants"
import Button from "../Forms/Button"
import clsx from "clsx"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import { usePurchaseContext } from "../../contexts/PurchaseContext"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"

export const GetPremiumButton = () => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)

  const TEXT = t(MEMBERSHIP.PREMIUM, { returnObjects: true })

  const { captureEvent } = useAnalyticsContext()
  const { isPremium, isOfferingLoading, isInitialized } = usePurchaseContext()

  const { openModal } = useModalOrchestrationContext()

  const handleGetPremiumSubscription = () => {
    captureEvent(AnalyticsEvent.InAppPurchaseModalOpened)

    openModal(ModalOrchestrationName.GetPremium)
  }

  const shouldHideHook = !isInitialized || isPremium

  return (
    <Button
      fill="solid"
      color="CARDIO"
      textColor="white"
      paddingWidth="thin"
      className={clsx(isOfferingLoading && "animate-pulse")}
      disabled={isOfferingLoading}
      hidden={shouldHideHook}
      onClick={handleGetPremiumSubscription}
      label={TEXT.CTA}
    />
  )
}

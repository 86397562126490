import { InternalRefetchQueryDescriptor } from "@apollo/client"
import {
  AddMovementAgendaItemMutation,
  AddMovementAgendaItemMutationVariables,
  CompleteMovementSessionMutation,
  CompleteMovementSessionMutationVariables,
  EditMovementSessionReportedDataMutation,
  EditMovementSessionReportedDataMutationVariables,
  GetCurrentMovementAgendaQuery,
  GetMovementWeekQuery,
  LogMovementSessionMutation,
  LogMovementSessionMutationVariables,
  Maybe,
  ModifyMovementAgendaItemMutation,
  ModifyMovementAgendaItemMutationVariables,
  MovementAgendaItemSummaryFragment,
  MovementSessionSummaryFragment,
  RemoveMovementAgendaItemMutationVariables,
  Weekday,
} from "../../generated/graphql"

export enum AgendaSchedulingReducerActionType {
  ContextEnabled = "enable",
  ContextDisabled = "disable",
  AgendaLoaded = "setAgenda",
  SelectedWeekScheduleUpdated = "setWeekSchedule",
  SetSelectedWeekday = "setSelectedWeekday",
  SelectedWeekLoaded = "setSelectedWeek",
  NextWeekSelected = "NextWeekSelected",
  PreviousWeekSelected = "PreviousWeekSelected",
  TimezoneChanged = "setTimeZone",
  WeekdaySelected = "selectWeekday",
  WeekSelectedByStartDate = "WeekSelectedByStartDate",
}

export interface AgendaSchedulingReducerAction {
  type: AgendaSchedulingReducerActionType
  payload?: any
}

export type AgendaDay = {
  date: Date
  weekday: Weekday
  sessions?: MovementSessionSummaryFragment[]
  items?: MovementAgendaItemSummaryFragment[]
  hasSession?: boolean
  nextItem?: MovementAgendaItemSummaryFragment
}

export type AgendaDaySchedule = {
  index: number
  day: AgendaDay
  weekScheduleOffset: number
  items: MovementAgendaItemSummaryFragment[]
  sessions: MovementSessionSummaryFragment[]
}

export type AgendaWeekSchedule = AgendaDaySchedule[]

export interface AgendaSchedulingContextState {
  // state & getters
  isEnabled: boolean
  isReady: boolean

  agenda: GetCurrentMovementAgendaQuery["currentMovementAgenda"] | null
  recurringItemsPerWeekday: Partial<{
    [key in Weekday]: MovementAgendaItemSummaryFragment[]
  }>

  suggestionInboxItems: MovementAgendaItemSummaryFragment[]

  today: AgendaDay
  timezone: string

  currentWeek:
    | GetCurrentMovementAgendaQuery["currentMovementAgenda"]["currentWeek"]
    | null
  currentWeekStartDate: Date
  currentWeekUuid: string | undefined
  currentWeekSchedule: AgendaWeekSchedule

  // navigation
  selectedWeekUuid: string | undefined
  selectedWeekStartDate: Date

  isSelectedWeekStale: boolean
  isSelectedWeekCurrent: boolean

  // selected week state
  selectedWeek: GetMovementWeekQuery["movementWeek"]
  selectedWeekSchedule: AgendaWeekSchedule

  canAccessSelectedWeek: boolean

  // agenda timeline
  selectedWeekday?: Weekday

  agendaItemActionLoading: boolean
  sessionActionLoading: boolean
}

export interface AgendaSchedulingContextActions {
  refetchAgenda: (onCompleted?: () => void) => Promise<void>

  isToday: (day: AgendaDay) => boolean
  isPast: (day: AgendaDay) => boolean
  isFuture: (day: AgendaDay) => boolean

  getDay: (weekday?: Maybe<Weekday>) => AgendaDay
  getDate: (weekday?: Maybe<Weekday>) => Date

  setSelectedWeekSchedule: (schedule: AgendaWeekSchedule) => void

  // navigation
  selectNextWeek: () => void
  selectPreviousWeek: () => void
  selectWeekDate: (startDate: Date) => void

  selectToday: () => void
  selectWeekday: (weekday?: Weekday) => void

  // sessions
  // startSession: (
  //   variables: StartMovementSessionMutationVariables
  // ) => Promise<void>
  // completeSession: () => Promise<void>

  logSession: (
    variables: LogMovementSessionMutationVariables,
    onSuccess?: (data: LogMovementSessionMutation) => void
  ) => Promise<void>

  completeSession: (
    variables: CompleteMovementSessionMutationVariables,
    onSuccess?: (data: CompleteMovementSessionMutation) => void
  ) => Promise<void>

  editSessionReportedData: (
    variables: EditMovementSessionReportedDataMutationVariables,
    onSuccess?: (data: EditMovementSessionReportedDataMutation) => void
  ) => Promise<void>

  // agenda item scheduler
  addAgendaItem: (
    variables: AddMovementAgendaItemMutationVariables,
    onSuccess?: (data: AddMovementAgendaItemMutation) => void
  ) => Promise<void>
  modifyAgendaItem: (
    variables: ModifyMovementAgendaItemMutationVariables,
    showSuccessMessage?: boolean,
    onSuccess?: (data: ModifyMovementAgendaItemMutation) => void
  ) => Promise<void>
  removeAgendaItem: (
    variables: RemoveMovementAgendaItemMutationVariables,
    onSuccess?: () => void
  ) => Promise<void>

  refetchQueries: InternalRefetchQueryDescriptor[]
}

import React from "react"
import { useTranslation } from "react-i18next"
import { RiStarFill } from "react-icons/ri"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { MEMBERSHIP, NAME_SPACES } from "../../locales/constants"
import { DailyMoveCollectionType } from "./DailyMoveCollectionType"
import { Link } from "react-router-dom"

export const DailyMoveEmptyState: React.FC<{
  collection: DailyMoveCollectionType
}> = ({ collection }) => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)
  const TEXT = t(MEMBERSHIP.TODAY, { returnObjects: true })

  const { captureEvent } = useAnalyticsContext()

  const handleClick = () => {
    captureEvent(AnalyticsEvent.DailyMoveCollectionEmptyStateClicked, {
      collection,
    })
  }

  if (collection !== DailyMoveCollectionType.Favorite) return null

  return (
    <div className="w-full h-full px-4">
      <Link
        to="/app/profile/activities"
        onClick={handleClick}
        className="flex flex-col items-center justify-center text-center gap-y-2"
      >
        <button className="flex flex-col items-center justify-center w-full p-4 text-xl font-medium text-center rounded-lg shadow-inner gap-y-2 text-neutral-600 bg-neutral-200 h-52">
          <RiStarFill className="w-12 h-12" />

          <span className="text-2xl">
            {TEXT.DAILY_MOVE.EMPTY_STATE[collection]}
          </span>
        </button>
      </Link>
    </div>
  )
}

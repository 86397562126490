import {
  Language,
  SqualoAdventureElement,
  Weekday,
} from "../../generated/graphql"
import { COMMON } from "../constants"

export default {
  EVERY: "Tous les {{value}}s",
  ANY: "N'importe quel {{value}}",
  WITH_TIME: "{{value}} à {{time}}",
  TERMS_AND_CONDITIONS: "Conditions générales d'utilisation",
  PRIVACY_POLICY: "Politique de confidentialité",
  WEEK_COUNT: "{{count}} semaines",
  RECOMMENDED: "Recommandé",
  POPULAR: "Populaire",
  FAVORITES: "Favoris",
  [COMMON.WEEKDAYS]: {
    [Weekday.Monday]: "Lundi",
    [Weekday.Tuesday]: "Mardi",
    [Weekday.Wednesday]: "Mercredi",
    [Weekday.Thursday]: "Jeudi",
    [Weekday.Friday]: "Vendredi",
    [Weekday.Saturday]: "Samedi",
    [Weekday.Sunday]: "Dimanche",
  },
  [COMMON.FORMS]: {
    SUBMIT: "Envoyer",
    NEXT: "Suivant",
    CONTINUE: "Continuer",
    CANCEL: "Annuler",
    SAVE: "Sauvegarder",
    CLEAR: "Effacer",
    SELECT: "Sélectionner",
    CONFIRM: "Confirmer",
    OKAY: "OK",
  },
  TEST: {
    TEST: "Test",
  },
  [COMMON.TABS]: {
    HOME: "Today",
    AGENDA: "Agenda",
    DISCOVER: "Découvre",
    PROFILE: "Profil",
  },
  [COMMON.ELEMENTS]: {
    [SqualoAdventureElement.Air]: "Air",
    [SqualoAdventureElement.Earth]: "Terre",
    [SqualoAdventureElement.Fire]: "Feu",
    [SqualoAdventureElement.Water]: "Eau",
  } as Record<SqualoAdventureElement, string>,
  [COMMON.LANGUAGES]: {
    ALL: "Toutes les langues",
    [Language.Fr]: "🇫🇷 Français",
    [Language.En]: "🇬🇧 English",
  },
}

import { useQuery } from "@apollo/client"
import { useEffect, useMemo } from "react"
import {
  AdventureStatus,
  GetAdventureDocument,
  GetAdventureQuery,
  GetAdventureQueryVariables,
} from "../../../generated/graphql"
import Modal, { ModalProps, ModalSize } from "../../Core/Modal"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../../contexts/ModalOrchestrationContext"
import isNil from "lodash/isNil"
import Loading from "../../Loading"
import { useOlibaContext } from "../../../contexts/OlibaContext"
import { IoHelpCircleOutline } from "react-icons/io5"
import { ButtonProps } from "../../Forms/Button"
import { getCurrentEnvironment } from "../../../utils"
import { useAuthenticatedClientContext } from "../../../contexts/AuthenticatedClientContext"

export interface AdventurePreparationModalBodyProps
  extends Partial<ModalProps> {
  url: string
  adventureUuid: string
}

export const AdventurePreparationModalBody: React.FC<
  AdventurePreparationModalBodyProps
> = ({ url, adventureUuid }) => {
  const { closeModal, isOpen } = useModalOrchestrationContext()
  const { currentUser } = useAuthenticatedClientContext()

  const { refetch } = useQuery<GetAdventureQuery, GetAdventureQueryVariables>(
    GetAdventureDocument,
    {
      variables: { adventureUuid },
    }
  )

  useEffect(() => {
    if (isNil(adventureUuid)) return

    if (!isOpen(ModalOrchestrationName.TallyAdventurePreparation)) return

    const interval = setInterval(async () => {
      const { data } = await refetch({ adventureUuid })

      if (data && data.getAdventure) {
        switch (data.getAdventure.status) {
          case AdventureStatus.Chosen:
            break
          case AdventureStatus.Prepared:
            break
          case AdventureStatus.Started:
            closeModal(
              ModalOrchestrationName.TallyAdventurePreparation,
              "started",
              data.getAdventure
            )

            break
        }
      }
    }, 2000)

    return () => clearInterval(interval)
  }, [refetch, closeModal, adventureUuid])

  const urlWithHiddenFields = useMemo(() => {
    if (!currentUser || !url) return

    const urlObject = new URL(url)

    const fields = {
      member_uuid: currentUser.userUuid,
      adventure_uuid: adventureUuid,
      hideTitle: "1",
      environment: getCurrentEnvironment(),
    }

    if (!isNil(fields)) {
      Object.entries(fields).forEach(([key, value]) => {
        urlObject.searchParams.append(key, value)
      })
    }

    return urlObject.toString()
  }, [url, adventureUuid, currentUser])

  return (
    <div className="relative flex flex-col items-center justify-center w-full h-full bg-primary-500">
      <Loading className="absolute inset-0 z-10" />

      {urlWithHiddenFields && (
        <iframe
          title="tally-form"
          src={urlWithHiddenFields}
          width="100%"
          height={"100%"}
          className="absolute inset-0 z-20"
        />
      )}
    </div>
  )
}

export const AdventurePreparationModal: React.FC<Partial<ModalProps>> = ({
  ...props
}) => {
  const { openOliba } = useOlibaContext()

  const toolbarActions = [
    {
      icon: IoHelpCircleOutline,
      fill: "clear",
      color: "white",
      onClick: () => {
        close()
        openOliba()
      },
    },
  ] as ButtonProps[]

  return (
    <Modal
      name={ModalOrchestrationName.TallyAdventurePreparation}
      initialSize={ModalSize.Full}
      isSheet
      background="primary"
      {...props}
    >
      <Modal.Header actions={toolbarActions} />
      <Modal.Body>
        {(props: any) => <AdventurePreparationModalBody {...props} />}
      </Modal.Body>
    </Modal>
  )
}

export default AdventurePreparationModal

import clsx from "clsx"
import { useCallback } from "react"

import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { SqualoFollowAlongVideo } from "../../generated/graphql"
import { ThumbnailButton } from "../Core/ThumbnailButton"
import isNil from "lodash/isNil"
import { useTranslation } from "react-i18next"
import { MICO, NAME_SPACES } from "../../locales/constants"
import {
  DurationIcon,
  EquipmentIcon,
  MobilityIcon,
  MovementIcon,
  TargetBodyPartIcon,
} from "../Core/Icons"
import {
  formatBodyTree,
  formatEquipment,
  formatLowImpact,
  formatSessionDuration,
  formatStyle,
} from "../../utils/format"
import { getModalityTextColor } from "../../utils/movementUtils"

export const MicoResourceCard: React.FC<{
  video: SqualoFollowAlongVideo
  isSelected?: boolean
  onSelect?: (video: SqualoFollowAlongVideo) => void
}> = ({ video, onSelect }) => {
  const { t } = useTranslation(NAME_SPACES.MICO)
  const TEXT = t(MICO.FOLLOW_ALONG, { returnObjects: true })

  const { captureEvent } = useAnalyticsContext()

  const handleCardClick = useCallback(() => {
    captureEvent(AnalyticsEvent.MicoResourceCardClicked, {
      squaloVideoUuid: video.uuid,
    })

    if (onSelect) {
      onSelect(video)
    }
  }, [video, onSelect])

  if (isNil(video)) {
    return <></>
  }

  if (isNil(video.thumbnailUrl)) {
    return <></>
  }

  return (
    <div
      className={clsx(
        "flex flex-col w-full h-52 justify-around",
        "rounded-lg shadow-md"
      )}
    >
      <div
        className={clsx(
          "flex flex-row items-center justify-between flex-shrink-0 w-full px-2 py-2 border-b gap-x-1 border-neutral/20",
          "rounded-t-lg",
          // "text-neutral bg-white",
          `bg-${video.movementModality}-500`,
          getModalityTextColor(video.movementModality)
        )}
      >
        <div className="flex flex-row items-center justify-between w-full gap-x-1 ">
          <span className="text-sm font-semibold tracking-tight truncate">
            {TEXT.OPEN_MOVE}
            <span className="font-medium opacity-80">
              {" > "}
              {TEXT.RESOURCE_TYPE}
            </span>
          </span>
          <div className="flex flex-row items-center gap-x-1">
            <span className="text-sm font-semibold">
              {formatStyle(video.movementStyle)}
            </span>
            <MovementIcon
              element={video}
              className={`w-6 h-6`}
              bgColor="transparent"
            />
          </div>
        </div>
      </div>
      <a
        className="flex flex-row w-full h-32 p-2 bg-white gap-x-2"
        onClick={handleCardClick}
      >
        <ThumbnailButton
          url={video.thumbnailUrl}
          background={video.movementModality}
          className="flex-shrink-0 rounded-lg shadow-sm w-28 h-28"
          overlayOpacity={20}
          backdropBlur="none"
        >
          <div className="flex flex-col items-center justify-center w-full h-full">
            {/* <RiVideoLine
              className={clsx(
                "h-20 w-20 opacity-50",
                `text-${video.movementModality}`
              )}
            /> */}
          </div>
        </ThumbnailButton>
        <div className="flex flex-col items-start justify-between flex-grow w-full h-full">
          <div className="flex flex-row items-center justify-between w-full gap-x-1">
            <span className="flex-shrink-0 font-semibold tracking-tight text-left text-neutral-600">
              {video.creatorName}
            </span>
          </div>
          <span className="text-lg font-semibold tracking-tighter text-left line-clamp-3 text-neutral-700">
            {video.title}
          </span>
        </div>
      </a>
      <div
        className={clsx(
          "flex flex-row items-center justify-start w-full p-2 border-t border-neutral/30",
          "rounded-b-lg",
          "text-neutral-600 bg-white"
        )}
      >
        <div
          className={clsx(
            "flex flex-row items-center justify-start w-full gap-3 overflow-x-scroll font-medium"
          )}
        >
          <div
            className={clsx("flex flex-row items-center gap-x-1 flex-shrink-0")}
          >
            <DurationIcon className="w-4 h-4" />
            <span>{formatSessionDuration(video?.duration)}</span>
          </div>

          <div
            className={clsx("flex flex-row items-center gap-x-1 flex-shrink-0")}
          >
            <TargetBodyPartIcon className="w-4 h-4" />
            <span>{formatBodyTree(video.movementTargetBodyParts)}</span>
          </div>

          {video.movementLowImpact && (
            <div
              className={clsx(
                "flex flex-row items-center gap-x-1 flex-shrink-0"
              )}
            >
              <MobilityIcon className="w-4 h-4" />
              <span>{formatLowImpact(video.movementLowImpact)}</span>
            </div>
          )}

          {!isNil(video.environmentalEquipmentRequired) &&
            video.environmentalEquipmentRequired.length > 0 && (
              <div
                className={clsx(
                  "flex flex-row items-center gap-x-1  flex-shrink-0"
                )}
              >
                <EquipmentIcon className="w-4 h-4" />
                <span className="font-semibold">
                  {formatEquipment(video.environmentalEquipmentRequired)}
                </span>
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

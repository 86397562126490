import { MICO } from "../constants"

export default {
  [MICO.HOME]: {
    TITLE: "Discover",
    TABS: {
      ADVENTURES: "Adventures",
      VIDEOS: "Videos",
      MOVES: "Moves",
    },
  },
  [MICO.FORM]: {
    LANGUAGE: "Language",
    WITHOUT_IMPACT: "Impact-free?",
    MODALITY: "Modality",
    STYLE: "Style",
    FILTERS: "Filters",
    CLEAR: "Clear all",
    OK: "OK",
    SEARCH: "Search",
  },
  [MICO.EXPLORER]: {
    AN_ERROR_HAS_OCCURED: "An error occurred.",
    EXPLORE: "Discover",
    EMPTY_MESSAGE:
      "No videos found. Try to change your filters or search for something else.",
    PLACEHOLDER_SEARCH: "Search",
    FILTERS: {
      TITLE: "Video filters",
    },
  },
  [MICO.VIEWER]: {
    DETAILS: "Details",
    LANGUAGE: "Language",
    DURATION: "Duration",
    TARGET_AREAS: "Target areas",
    EQUIPMENT: "Equipment",
    FLAG_LABEL_ISSUE: {
      LABEL: "Something weird?",
      VALUE: "Suggest a correction",
    },
    VIDEO_NOT_FOUND: "Video not found",
  },
  [MICO.CARD]: {
    START: "See video",
  },
  [MICO.FOLLOW_ALONG]: {
    OPEN_MOVE: "OpenMove",
    RESOURCE_TYPE: "Public Video",
    CTA: {
      START: "Start now",
      SCHEDULE: "Schedule for later",
    },
  },
  [MICO.GAMEPLAN]: {},
}

import classNames from "clsx"
import { closeOutline } from "ionicons/icons"
import isNil from "lodash/isNil"
import React, { useContext, useMemo, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { IconType } from "react-icons/lib"

import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import { Maybe, MovementModality } from "../../generated/graphql"
import { enumValues } from "../../utils"
import { getModalityTextColor } from "../../utils/movementUtils"
import Modal, { ModalContext, ModalProps } from "../Core/Modal"
import Button from "./Button"

export interface FieldModalBodyProps<T> {
  name: string
  state: Maybe<T>
  setState: (value: Maybe<T>) => void
}

export interface RequiredFieldModalBodyProps<T>
  extends Omit<FieldModalBodyProps<T>, "setState"> {
  state: T
  setState: (value: T) => void
}

export interface FieldModalComponentProps<T> {
  Body: React.FC<FieldModalBodyProps<T>>
  initialState: Maybe<T>
  className?: string
  scrollY?: boolean
  showSubmitButton?: boolean
  showClearButton?: boolean
  childrenProps?: FieldModalBodyProps<T>
}

export const GridFormFieldModalComponent = <T,>({
  Body,
  initialState,
  className,
  scrollY = false,
  showSubmitButton = true,
  showClearButton = false,
  childrenProps,
}: FieldModalComponentProps<T>) => {
  const { name } = useContext(ModalContext)
  const { closeModal } = useModalOrchestrationContext()

  const { t } = useTranslation()

  const [state, setState] = useState<Maybe<T>>(initialState)

  const showButtons = showClearButton || showSubmitButton

  return (
    <div className="flex flex-col w-full h-full max-w-full bg-neutral-100">
      <div
        className={classNames(
          "relative",
          "flex items-center justify-center w-full h-full px-4",
          showButtons && "pb-20",
          scrollY ? "overflow-y-auto" : "",
          className
        )}
      >
        {Body && name && (
          <Body
            name={name}
            state={state}
            setState={setState}
            {...childrenProps}
          />
        )}

        {showButtons && (
          <div
            className={classNames(
              "absolute bottom-0 w-full",
              "p-4 mb-safe",
              "flex flex-row justify-between gap-x-4"
            )}
          >
            {showSubmitButton && (
              <Button
                type="submit"
                mode="ios"
                expand="block"
                onClick={() => closeModal(name, "submit", state)}
                className={classNames("w-full m-0 flex-grow")}
                label={t("FORMS.SUBMIT")}
              />
            )}
            {showClearButton && (
              <Button
                type="submit"
                mode="ios"
                expand="block"
                onClick={() => closeModal(name, "clear")}
                fill="outline"
                className={classNames("m-0 bg-white")}
                icon={closeOutline}
                iconSlot="icon-only"
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export interface GridFormFieldModalProps<T> extends ModalProps {
  initialState?: Maybe<T>
  Body: React.FC<FieldModalBodyProps<T>>
  showClearButton?: boolean
  showSubmitButton?: boolean
}

export const GridFormFieldModal: React.FC<GridFormFieldModalProps<any>> = <T,>({
  name,
  title,
  subtitle,
  className,
  onClose,
  Body,
  initialState,
  showClearButton,
  showSubmitButton,
  initialProps,
}: GridFormFieldModalProps<T>) => {
  const props = {
    name,
    Body,
    className,
    initialState,
    showClearButton,
    showSubmitButton,
    childrenProps: initialProps,
  }

  return (
    <Modal
      name={name}
      isSheet={false}
      initialProps={props}
      onClose={onClose}
      fullScreen
      background="neutral-100"
    >
      <Modal.Header title={title} subtitle={subtitle} showShadow={false} />

      <Modal.Body scrollY={false}>
        {(props: any) => <GridFormFieldModalComponent {...props} />}
      </Modal.Body>
    </Modal>
  )
}

export interface GridFormFieldProps<T> {
  name: string
  modalName: ModalOrchestrationName
  modalClassName?: string
  className?: string
  label: string
  Body: React.FC<any>
  required?: boolean
  Icon?: IconType
  title?: string
  subtitle?: string
  background?: string
  hidden?: boolean
  formatValue?: (
    value: T | undefined
  ) => string | { information: string; detail: string | JSX.Element }
  showDismiss?: boolean
  showClearButton?: boolean
  showSubmitButton?: boolean
  disabled?: boolean
}

export const GridFormField = <T,>({
  name,
  className,
  modalName,
  modalClassName,
  label,
  Icon,
  formatValue = (x: any) => x?.toString() || "-",
  background = "white",
  hidden = false,
  disabled = false,
  required = false,
  ...props
}: React.PropsWithChildren<GridFormFieldProps<T>>) => {
  const { register, setValue, setFocus } = useFormContext()

  const { ref } = register(name, { required })
  const value = useWatch({ name })

  const { captureEvent } = useAnalyticsContext()
  const { openModal, isOpen } = useModalOrchestrationContext()

  const onClick = async (e: any) => {
    if (isOpen(modalName)) {
      e.preventDefault()
      return
    }

    captureEvent(AnalyticsEvent.GridFormFieldClicked, { name: name })

    setFocus(name)

    openModal(modalName, {
      initialState: value,
    })
  }

  const onModalClose = (reason?: string, data?: any) => {
    if (reason === "clear") {
      setValue(name, undefined)
    } else if (reason === "submit") {
      setValue(name, data, { shouldDirty: true, shouldValidate: true })
    }
  }

  const formattedValue = formatValue(value)
  let information, detail

  if (isNil(formattedValue)) {
    information = "-"
  } else if (typeof formattedValue !== "string") {
    information = formattedValue.information
    detail = formattedValue.detail
  } else {
    information = formattedValue
  }

  const textColor = useMemo(() => {
    if (enumValues(MovementModality).includes(background as MovementModality)) {
      return getModalityTextColor(background as MovementModality)
    } else if (background === "white") {
      return "text-neutral"
    } else {
      return "text-white"
    }
  }, [background])

  return (
    <div
      className={classNames(
        "flex flex-row items-center justify-center w-full h-full p-2",
        hidden && "hidden"
      )}
    >
      <button
        className={classNames(
          "relative",
          "flex flex-col justify-between",
          "rounded-md shadow-lg",
          "h-24 px-4 py-3 w-full",
          "border border-neutral-400 hover:border-neutral-600",
          `bg-${background}`,
          "disabled:opacity-50 disabled:cursor-not-allowed",
          `focus-within:ring-4 focus-within:ring-primary-500/60`,
          background === "white" ? "text-primary" : textColor,
          isNil(value) && !required && "opacity-75",
          className
        )}
        type="button"
        onClick={onClick}
        disabled={disabled}
        ref={ref}
      >
        <div
          className={classNames(
            "flex flex-row items-center justify-between w-full",
            textColor
          )}
        >
          <span className={classNames("text-sm font-medium")}>{label}</span>
          {Icon && <Icon className={classNames("w-6 h-6")} />}
        </div>
        <div className="flex flex-col items-start w-full gap-y-1">
          {detail && (
            <span className="-mb-2 text-sm text-neutral-600">{detail}</span>
          )}
          <span className="w-full text-base font-semibold truncate sm:text-lg max-w-max">
            {information}
          </span>
        </div>
      </button>
      <GridFormFieldModal
        name={modalName}
        className={modalClassName}
        initialState={value}
        onClose={onModalClose}
        {...props}
      />
    </div>
  )
}

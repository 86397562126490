import { logOutOutline } from "ionicons/icons"
import { useTranslation } from "react-i18next"

import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import { NAME_SPACES } from "../../locales/constants"
import Modal, { ModalProps } from "../Core/Modal"
import Button from "../Forms/Button"
import { GridForm, GridFormSection } from "../Forms/GridForm"
import { AccountSettingsField } from "./AccountSettingsField"
import { CalendarSettingsField } from "./CalendarSettingsField"
import { NotificationSettingsField } from "./NotificationSettingsField"

export const SettingsModalComponent: React.FC = () => {
  const { t } = useTranslation([NAME_SPACES.MEMBERSHIP, NAME_SPACES.AUTH])

  const { logout } = useAuthenticatedClientContext()

  const { closeModal } = useModalOrchestrationContext()

  return (
    <GridForm className="flex-grow">
      <AccountSettingsField />
      <NotificationSettingsField />
      <GridFormSection title={t("MEMBERSHIP:PROFILE.SETTINGS.PRO.TITLE")}>
        <CalendarSettingsField />
      </GridFormSection>
      <div className="absolute flex flex-row items-center justify-center bottom-4 left-4 right-4 mb-safe">
        <Button
          fill="clear"
          type="button"
          onClick={() => {
            closeModal(ModalOrchestrationName.Settings).then()
            logout()
          }}
          label={t("AUTH:LOGOUT")}
          icon={logOutOutline}
        />
      </div>
    </GridForm>
  )
}

export const SettingsModal: React.FC<Partial<ModalProps>> = ({
  name = ModalOrchestrationName.Settings,
}) => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)

  return (
    <Modal name={name} isSheet>
      <Modal.Header title={t("PROFILE.SETTINGS.TITLE")} />

      <Modal.Body>
        {(props: any) => <SettingsModalComponent {...props} />}
      </Modal.Body>
    </Modal>
  )
}

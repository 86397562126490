import { DailyMoveCollectionType } from "../../components/Today/DailyMoveCollectionType"
import { StaminaLevel } from "../../components/Today/StaminaLevel"
import { MEMBERSHIP } from "../constants"

export default {
  [MEMBERSHIP.PREMIUM]: {
    CTA: "S'abonner",
    FREE_TRIALS: {
      ONE_WEEK: "Essai gratuit de 7 jours",
    },
    PERIODS: {
      MONTH: "mois",
      PER_MONTH: "/ mo",
      PER_YEAR: "/ an",
    },
    TIER_1: {
      NOT_AVAILABLE_ON_WEB: "N/A sur le web",
      CTA: "Essaie gratuit",
      NO_THANKS: "Non merci",
      TITLE:
        "Les Behale <0>Heros</0> sont 3.2x plus de chances d’atteindre leurs objectifs chaque semaine.",
      FEATURES: [
        {
          emoji: "🏔",
          key: "adventures",
          title: "Lâche ton Ame d'Aventurier",
          description:
            "Débloque toutes les aventures démarre dès maintenant celle qui te plait.",
        },
        // {
        //   emoji: "🧬",
        //   key: "personalized",
        //   title: "Mouvement Sur Mesure",
        //   description:
        //     "Reçois des suggestions personnalisées basées sur ton activité, tes blessures et tes inconforts.",
        // },
        {
          emoji: "👊",
          key: "community",
          title: "Rejoins la Dream Team",
          description:
            "Rejoins notre communauté exclusive pour obtenir le soutien de nos coachs, spécialistes et autres heros.",
        },
        {
          emoji: "🔮",
          key: "future",
          title: "Vois Ton Avenir",
          description:
            "Débloque le plein potentiel de l'agenda : vois les phases futures de chaque aventure et planifie à l'avance.",
        },
        {
          emoji: "🗓️",
          key: "calendar",
          title: "Maitre de Ton Temps",
          description:
            "Reste maitre de ton emploi du temps avec les intégrations Google Calendrier.",
        },
      ],
    },
    CANCELLATION_POLICY: {
      TITLE: "Annule à tout moment",
      SUBTITLE:
        "Annule ton abonnement à tout moment - sans pénalités ou frais cachés.",
    },
    LEGAL_DISCLAIMER: {
      IOS: "Un achat de {{purchaseAmountAndPeriod}} sera appliqué à votre compte iTunes lors de la confirmation. Les abonnements seront automatiquement renouvelés à moins d'être annulés dans les 24 heures précédant la fin de la période en cours. Vous pouvez annuler à tout moment avec les paramètres de votre compte iTunes. Toute portion non utilisée d'une période d'essai gratuite sera perdue si vous achetez un abonnement. Pour plus d'informations, consultez nos <0>{{termsOfService}}</0> et <1>{{privacyPolicy}}</1>.",
    },
  },
  [MEMBERSHIP.GUIDANCE]: {
    CHAT: {
      TITLE: "Parle à ton coach",
      SUBTITLE:
        "Pose-lui toutes tes questions dans la fenêtre de discussion ci-dessous 😊",
      NEW_MESSAGE_PLACEHOLDER: "Écris ton message ici",
      AGENT_AVAILABLE_TEXT: "I'm all ears",
      AGENT_UNAVAILABLE_TEXT:
        "I'm unavailable right now, but I'll be back soon!",
      INITIAL_MESSAGE:
        "Bienvenue sur Behale 😁 N'hésite pas à me poser des questions pour que je puisse t'aider à avancer.",
      SENDING: "Envoi...",
      SENT_AT: "Envoyé à {{time}}",
      NO_MESSAGES_SENT: "Aucun message envoyé",
    },
  },
  [MEMBERSHIP.PROFILE]: {
    SYNCRO_PERMISSION: "Synchronisation du calendrier iOS",
    TITLE: "Profil",
    SETTINGS: {
      TITLE: "Paramètres",
      FIELDS: {
        ACCOUNT: {
          LABEL: "Compte",
          TITLE: "Paramètres du compte",
          FIELDS: {
            FIRST_NAME: {
              LABEL: "Prénom",
              PLACEHOLDER: "Prénom",
            },
            LAST_NAME: {
              LABEL: "Nom",
              PLACEHOLDER: "Nom",
            },
          },
          DELETION: {
            CTA: "Supprimer mon compte",
            CONFIRMATION: {
              HEADER: "Suppression de compte",
              SUBHEADER:
                "Êtes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible.",
            },
            STATUS: {
              SUCCESS: "Compte supprimé",
              ERROR:
                "Une erreur est survenue lors de la suppression de votre compte",
            },
          },
        },
        NOTIFICATIONS: {
          TITLE: "Paramètres des notifications",
          FIELD: {
            LABEL: "Notifications",
            VALUES: {
              ALL_ENABLED: "Activées",
              ALL_DISABLED: "Désactivées",
            },
            OPTIONS: {
              ALL_NOTIFICATIONS: "Notifications activées",
            },
          },
          REQUEST_PERMISSION: {
            TITLE: "Aidez-nous à vous aider",
            DESCRIPTION:
              "💬 Active les notifications pour recevoir des messages de nos coachs et garder la motivation. On ne te spammera pas, promis !",
            GRANT: "Rester motivé.e",
            DENY: "Non, je prends le risque",
          },
        },
      },
      PRO: {
        TITLE: "Fonctionnalités Pro",
        SUBTITLE: "Découvre les fonctionnalités Pro de Behale",
        CALENDAR: {
          TITLE: "Paramètres du calendrier",
          FIELD: {
            LABEL: "Integration Calendrier",
            INTEGRATIONS: {
              GOOGLE: "Google Calendar",
            },
            VALUES: {
              CONNECTED: "Connecté",
              NOT_CONNECTED: "Pas connecté",
              CONNECTED_TO: "Connecté à {{calendarName}}",
            },
          },
        },
        WHATSAPP: {
          TITLE: "Communauté WhatsApp",
          FIELD: {
            LABEL: "WhatsApp",
            VALUES: {
              CTA: "Rejoindre",
            },
          },
        },
      },
    },
    TABS: {
      ADVENTURE: {
        TITLE: "Aventure",
      },
      PROGRESS: {
        TITLE: "Progression",
      },
    },
    PUBLIC_PROFILE: {
      JOINED_AT: "Membre depuis {{date}}",
      NO_ELEMENTS: "Choisir élément",
    },
    BUDDIES: {
      TITLE: "Buddies",
      SUBTITLE:
        "Connecte-toi avec tes amis pour partager ton activité et rester motivé.e.",
      EMPTY_STATE: {
        TITLE: "Tu n'as pas encore de buddy",
      },
      CARD: {
        PREVIEW: {
          ITEM: "Prochaine",
          SESSION: "Dernière",
          PLACEHOLDER: "Aucune activité",
        },
      },
      SHARE_LINK: {
        CTA: "Invite un buddy",
        TITLE: "Invite ton pote à faire son sport avec toi.",
        TEXT: "Hey, tu veux être mon.e Behale Buddy ? Comme ça on s'organise pour faire du sport ensemble et on reste motivé !",
        SUCCESS: "Lien copié !",
      },
      FEEDBACK: {
        ALREADY_BUDDIES: "You're already buddies!",
        SUCCESS: "Ton buddy a bien été ajouté !",
        ERROR: "Une erreur est survenue lors de l'ajout de ton buddy",
      },
    },
  },
  [MEMBERSHIP.TODAY]: {
    STAMINA_METER: {
      LABEL: "Stamina",
      LEVELS: {
        [StaminaLevel.Inactive]: "Inactif 😴",
        [StaminaLevel.Okay]: "Correct 😐",
        [StaminaLevel.Good]: "Bien 😊",
        [StaminaLevel.Great]: "Super 😁",
        [StaminaLevel.Excellent]: "Excellent 🤩",
      },
      LINK: "https://behale.notion.site/Qu-est-ce-que-le-compteur-de-Stamina-Meter-6d1ddce3a7174cd7942ad6cd77f5e138",
    },
    DAILY_MOVE: {
      TITLE: "Ton mouvement du jour",
      COMPLETED: {
        TITLE: "Bravo 👏",
        SUBTITLE: "Tu as complété.e ton mouvement du jour !",
      },
      EMPTY_STATE: {
        [DailyMoveCollectionType.Favorite]: "Choisir mes favoris",
      },
      TYPES: {
        [DailyMoveCollectionType.Planned]: "Planifiée",
        [DailyMoveCollectionType.Adventure]: "Aventure",
        [DailyMoveCollectionType.Suggestion]: "Recommendée",
        [DailyMoveCollectionType.Favorite]: "Favoris",
      },
      CTAS: {
        START: "C'est parti !",
        LOG: "C'est fait !",
        VIEW: "Ouvrir lien",
        PLAN: "Ajouter à l'agenda",
      },
      PLANNED: {
        INDICATION: {
          PLANNED: "Planifiée",
          ADVENTURE: "Aventure",
          SUGGESTED: "Suggérée",
        },
        CTA: "C'est parti !",
        MODAL: {
          TITLE: "Adapte ta séance",
          SUBTITLE: "Ton emploi du temps est chargé ? Pas de soucis !",
          AVAILABLE_TIME: "Combien de temps as-tu ?",
        },
      },
    },
    RECOMMENDED_ADVENTURES: {
      TITLE: "Aventures recommandées",
      SUBTITLE: "Découvre les aventures qui te correspondent",
      VIEW_ALL: "Voir toutes les aventures",
    },
  },
  [MEMBERSHIP.CALENDAR]: {
    TITLE: "Connecte ton calendrier",
    DESCRIPTION:
      "📆 L'organisation est notre mot d'ordre chez Behale, pour simplifier et allier tes programmes avec ton quotidien, crée ton propre calendrier qui te donnera  <strong>3x plus de chances</strong> d'atteindre tes objectifs !",
    SKIP: "Je le ferai demain (ou jamais)",
    SUCCESS_MESSAGE: "Ton calendrier est connecté !",
  },
  [MEMBERSHIP.PROGRESS]: {
    STATISTICS: "Statistiques",
    WEEKLY_STREAK: "semaines d'affilées",
    TOTAL_ACTIVE_WEEKS: "semaines actives",
    TOTAL_SESSIONS: "séances totales",
    TOTAL_TIME: "temps totale",
    AVERAGE_SESSIONS_PER_WEEK: "séances / sem",
    ACTIVITY_LOG: "Historique d'activité",
    SESSIONS_COMPLETED: "séances",
    SESSIONS_COMPLETED_one: "séance",
    COMPLETE_YOUR_FIRST_SESSION:
      "Retrouve ton bien-être avec la première séance de la semaine",
    NO_SESSIONS_COMPLETED_WEEK: "Aucune séance complétée cette semaine",
  },
}

import { isNil } from "lodash"
import * as React from "react"
import { useContext, useCallback } from "react"
import { useTranslation } from "react-i18next"

import {
  AgendaDay,
  useAgendaSchedulingContext,
} from "../../../contexts/AgendaSchedulingContext"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../../contexts/AnalyticsContext"
import { useLocaleContext } from "../../../contexts/LocaleContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../../contexts/ModalOrchestrationContext"
import {
  AssessmentRatingOfPerceivedExertion,
  BodyPartEnum,
  BodyTree,
  GetMovementSessionQuery,
  MovementSessionStatus,
  MovementStyle,
  Weekday,
} from "../../../generated/graphql"
import useToast from "../../../hooks/useToast"
import { MOVEMENT, NAME_SPACES } from "../../../locales/constants"
import { getWeekdayDate } from "../../../utils"
import Modal, { ModalContext, ModalProps } from "../../Core/Modal"
import { GridForm, GridFormSection } from "../../Forms/GridForm"
import {
  BodyPartGridFormField,
  BodyPartSelectState,
  convertBodyTreeToBodyPartSelectState,
} from "../Forms/Fields/BodyPartSelect"
import { DistanceGridFormField } from "../Forms/Fields/DistanceSlider"
import { DurationGridFormField } from "../Forms/Fields/DurationSlider"
import { MovementStyleGridFormField } from "../Forms/Fields/MovementStyleSelect"
import { SessionTitleInput } from "../Forms/Fields/MovementTitleInput"
import { RPEGridFormField } from "../Forms/Fields/RPESlider"
import { ScheduleGridFormField } from "../Forms/Fields/SchedulePicker"
import { buildSessionLabel } from "../Style"

export type MovementSessionFormData = {
  title: string
  description?: string
  movementStyle: MovementStyle
  movementTargetBodyParts?: BodyPartSelectState
  reportedDuration: number
  reportedDistance?: number
  reportedRatingOfPerceivedExertion?: AssessmentRatingOfPerceivedExertion
  reportedSchedule: {
    weekday: Weekday
  }
}

export const extractMovementTargetBodyParts = (
  data?: BodyPartSelectState,
  multi = false
) => {
  if (isNil(data)) return null

  let movementTargetBodyParts: BodyPartEnum[] = []

  if (data) {
    const bodyPart = (data.muscle ||
      data.muscleGroup ||
      data.region) as unknown as BodyPartEnum

    if (data.isFullBody) {
      movementTargetBodyParts = [BodyPartEnum.FullBody]
    } else if (bodyPart) {
      movementTargetBodyParts = [bodyPart]
    }
  }

  if (multi) {
    return movementTargetBodyParts
  }

  return movementTargetBodyParts[0]
}

export interface MovementSessionFormProps {
  session?: GetMovementSessionQuery["movementSession"]
  day?: AgendaDay
}

const MovementSessionForm: React.FC<MovementSessionFormProps> = ({
  day,
  session,
}) => {
  const { name } = useContext(ModalContext)

  const formAction = session ? "edit" : "log"

  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.SESSION_LOGGING, {
    returnObjects: true,
  })

  const { showSuccess } = useToast()
  const { captureEvent } = useAnalyticsContext()
  const {
    today,
    selectedWeekStartDate,
    selectedWeekUuid,
    getDate,
    editSessionReportedData,
    logSession,
    sessionActionLoading,
  } = useAgendaSchedulingContext()
  const { toggleLoading, closeModal } = useModalOrchestrationContext()

  const { formatDate } = useLocaleContext()

  const mapFormDataToVariables = useCallback(
    (data: MovementSessionFormData) => {
      getDate(data.reportedSchedule.weekday)

      const date = getWeekdayDate(
        selectedWeekStartDate,
        data.reportedSchedule.weekday
      )

      const movementTargetBodyParts = extractMovementTargetBodyParts(
        data.movementTargetBodyParts,
        true
      )

      const title = buildSessionLabel({
        title: data.title,
        movementStyle: data.movementStyle,
      })

      const result = {
        ...data,
        title,
        reportedDistance: data.reportedDistance || undefined,
        reportedRatingOfPerceivedExertion:
          data.reportedRatingOfPerceivedExertion || undefined,
        movementWeekUuid: selectedWeekUuid,
        movementTargetBodyParts,
        reportedDate: formatDate(date, "yyyy-MM-dd"),
      }

      console.debug("mapFormDataToVariables", result)

      return result
    },
    [selectedWeekStartDate]
  )

  const handleSubmit = useCallback(
    async (data: MovementSessionFormData) => {
      const variables = mapFormDataToVariables(data)

      if (formAction === "edit") {
        await editSessionReportedData(
          {
            uuid: session?.uuid,
            ...variables,
          },
          (result) => {
            captureEvent(AnalyticsEvent.PastSessionReportedDataEdited, {
              uuid: session?.uuid,
              reportedData: result.editMovementSessionReportedData,
            })

            closeModal(name, "edit", result.editMovementSessionReportedData)
          }
        )
      } else {
        await logSession(variables, async (data) => {
          await showSuccess(TEXT.SUCCESS_MESSAGE)

          captureEvent(AnalyticsEvent.AdHocMovementSessionLogged, {
            ...data.logMovementSession,
          })

          closeModal(name, "logged", data.logMovementSession)
        })
      }
    },
    [session]
  )

  const isSessionCompletedInApp =
    !isNil(session) && session.status === MovementSessionStatus.Completed

  const duration = isSessionCompletedInApp
    ? session?.sessionActualDuration
    : session?.reportedDuration

  const weekday = session?.weekday

  const defaultValues = {
    title: session?.title || "",
    movementStyle: session?.movementStyle,
    movementTargetBodyParts: convertBodyTreeToBodyPartSelectState(
      session?.movementTargetBodyParts as BodyTree | undefined
    ),
    reportedDuration: duration || 1800, // 30 minutes
    reportedSchedule: {
      weekday: weekday || day?.weekday || today.weekday,
    },
    reportedRatingOfPerceivedExertion:
      session?.reportedRatingOfPerceivedExertion || undefined,
    reportedDistance: session?.reportedDistance || undefined,
  }

  React.useEffect(() => {
    toggleLoading(sessionActionLoading)
  }, [sessionActionLoading])

  return (
    <GridForm<MovementSessionFormData>
      onValidSubmit={handleSubmit}
      submitCTA={TEXT.REGISTER_SESSION}
      disableSubmit={sessionActionLoading}
      defaultValues={defaultValues}
    >
      <div className="col-span-2">
        <SessionTitleInput name="title" className="text-2xl" />
      </div>
      <GridFormSection title={TEXT.SECTIONS.MOVEMENT}>
        <MovementStyleGridFormField
          required
          name="movementStyle"
          disabled={isSessionCompletedInApp}
          recommend="favorite"
        />
        <DistanceGridFormField name="reportedDistance" />
        <BodyPartGridFormField name="movementTargetBodyParts" />
        <RPEGridFormField name="reportedRatingOfPerceivedExertion" />
      </GridFormSection>

      <GridFormSection
        title={TEXT.SECTIONS.SCHEDULE}
        hidden={isSessionCompletedInApp}
      >
        <ScheduleGridFormField name="reportedSchedule" onlyPast />
        <DurationGridFormField name="reportedDuration" type="exact" />
      </GridFormSection>
    </GridForm>
  )
}

export const MovementSessionFormModal: React.FC<Partial<ModalProps>> = ({
  name = ModalOrchestrationName.MovementSessionForm,
  ...props
}) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const SESSION_LOGGING = t(MOVEMENT.SESSION_LOGGING, { returnObjects: true })

  return (
    <Modal name={name} isSheet {...props}>
      <Modal.Header title={SESSION_LOGGING.REGISTER_SESSION} />

      <Modal.Body>
        {(props: MovementSessionFormProps) => (
          <MovementSessionForm {...props} />
        )}
      </Modal.Body>
    </Modal>
  )
}

export default MovementSessionFormModal

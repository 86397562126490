import { useQuery } from "@apollo/client"
import { useAuthenticatedClientContext } from "../contexts/AuthenticatedClientContext"
import {
  GetCurrentPhaseRemainingItemsDocument,
  GetCurrentPhaseRemainingItemsQuery,
} from "../generated/graphql"

const useCurrentPhaseActivities = () => {
  const { isClientAuthenticated } = useAuthenticatedClientContext()

  const { data, loading } = useQuery<GetCurrentPhaseRemainingItemsQuery>(
    GetCurrentPhaseRemainingItemsDocument,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "network-only",
      skip: !isClientAuthenticated,
      onError: (error) => {
        if (error.message === "Adventure not found") {
          console.warn("Adventure not found")
        }
      },
    }
  )

  return {
    activities: data?.currentPhaseRemainingItems,
    loading,
  }
}

export default useCurrentPhaseActivities

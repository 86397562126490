import { NAME_SPACES } from "../constants"
import adventure from "./adventureTranslationsFr"
import auth from "./auth"
import calendar from "./calendar"
import common from "./common"
import membership from "./membership"
import mico from "./micoTranslationsFr"
import movement from "./movementTranslationsFr"
import notification from "./notification"
import welcome from "./welcomeTranslationsFr"

export default {
  [NAME_SPACES.AUTH]: auth,
  [NAME_SPACES.MICO]: mico,
  [NAME_SPACES.MEMBERSHIP]: membership,
  [NAME_SPACES.MOVEMENT]: movement,
  [NAME_SPACES.CALENDAR]: calendar,
  [NAME_SPACES.NOTIFICATIONS]: notification,
  [NAME_SPACES.COMMON]: common,
  [NAME_SPACES.WELCOME]: welcome,
  [NAME_SPACES.ADVENTURE]: adventure,
} as const

import { ADVENTURE } from "../constants"

export default {
  [ADVENTURE.DIFFICULTY]: {
    [0]: "Starter",
    [1]: "Intermediate",
    [2]: "Advanced",
    [3]: "Expert",
    [4]: "Athlete",
  },
  [ADVENTURE.TYPE]: {
    COMMUNITY: "Free",
    PREMIUM: "Premium",
  },
  [ADVENTURE.CURRENT]: {
    TIME_LEFT: "{{ value }} left",
    PHASES_COMPLETED: "stage",
    SESSIONS_COMPLETED: "sessions",
    STATUS: {
      PAUSED: "Paused",
      IN_PROGRESS: "In progress",
    },
    ACTIONS: {
      PAUSE: "Pause Adventure",
      RESUME: "Resume Adventure",
      START_NEXT: "Start Next Adventure",
    },
    CONVERSION: {
      PHASE_LOCKED: {
        TITLE: "Stage locked",
        SUBTITLE: "Complete the previous stage to unlock this one.",
        CTA: "Unlock now",
      },
    },
  },
  MORE_INFO: "See more information",
  LESS_INFO: "See less information",
  START: {
    TITLE: "Start your next adventure",
  },
  [ADVENTURE.GALLERY]: {
    TITLE: "Adventures",
    TITLE_WITH_ELEMENT: "{{ element }} Adventures",
    DESCRIPTION:
      "Adventures are flexible, self-paced training programs that help you achieve your goals. They adapt to your schedule and your progress.",
    ITEM: {
      CTA: "Start Adventure",
      NARRATIVES: {
        TITLE: "Who's it for?",
      },
      SUCCESS: "Your adventure begins!",
    },
    SKIP: "I'll choose later",
  },
  [ADVENTURE.TEMPLATE]: {
    TABS: {
      INFORMATION: {
        LABEL: "Details",
      },
      PHASES: {
        LABEL: "Stages",
        PHASE_N: "Stage {{ index }}",
      },
    },
    EARLY_BIRD: {
      PRESET_MESSAGE:
        "Hello, I'd like to start the early bird adventure: {{ title }}",
    },
    LOCKED: {
      CTA: "Unlock Adventure",
      CARD: {
        TITLE: "Adventure locked!",
        SUBTITLE:
          "Upgrade to premium or complete a lower level adventure to unlock this unique experience.",
      },
    },
    UNLOCKED: {
      CTA: "Start Adventure",
    },
    PRODUCTS: {
      TITLE: "Products",
      CTA: "Learn more",
    },
    PHASES: {
      TITLE: "Journey",
    },
  },
  [ADVENTURE.KICK_OFF]: {
    TITLE: "Preparing your adventure...",
    SUBTITLE:
      "Get ready to discover what personalized wellness can really do for you...",
    TOAST: {
      SUCCESS: "Your personalized adventure awaits!",
    },
  },
}

import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useReducer,
} from "react"
import { useAuthenticatedClientContext } from "./AuthenticatedClientContext"
import {
  GetAdventureTemplateDocument,
  GetAdventureTemplateQuery,
  GetAdventureTemplateQueryVariables,
  GetCurrentAdventureDocument,
  GetCurrentAdventureQuery,
  Language,
  Maybe,
  SqualoAdventureElement,
  usePauseAdventureMutation,
  useResumeAdventureMutation,
} from "../generated/graphql"

import isNil from "lodash/isNil"
import { useQuery } from "@apollo/client"
import { useAgendaSchedulingContext } from "./AgendaSchedulingContext"
import { useLocaleContext } from "./LocaleContext"
import useToast from "../hooks/useToast"

export interface AdventureContextState {
  currentElement: Maybe<SqualoAdventureElement>

  currentAdventure: GetCurrentAdventureQuery["currentAdventure"]
  currentAdventureTemplate: GetAdventureTemplateQuery["getAdventureTemplate"]

  isPaused: boolean

  isQuerying: boolean
  isMutating: boolean
}

export interface AdventureContextActions {
  pauseAdventure: () => void
  resumeAdventure: (resumeDate?: Date) => void
}

export const AdventureContext = createContext<
  (AdventureContextState & AdventureContextActions) | undefined
>(undefined)

export enum AdventureReducerActionType {
  CurrentAdventureLoaded = "CurrentAdventureLoaded",
  CurrentAdventureTemplateLoaded = "CurrentAdventureTemplateLoaded",
  CurrentAdventurePaused = "CurrentAdventurePaused",
  CurrentAdventureResumed = "CurrentAdventureResumed",
}

export enum AdventureContextErrors {}

const adventureReducer = (
  state: AdventureContextState,
  action: {
    type: AdventureReducerActionType
    payload?: any
  }
): AdventureContextState => {
  const { type, payload } = action

  switch (type) {
    case AdventureReducerActionType.CurrentAdventureLoaded:
      return {
        ...state,
        currentAdventure: payload.currentAdventure,
        currentElement: payload.currentAdventure?.squaloAdventureElement,
      }

    case AdventureReducerActionType.CurrentAdventureTemplateLoaded:
      return {
        ...state,
        currentAdventureTemplate: payload.currentAdventureTemplate,
      }

    case AdventureReducerActionType.CurrentAdventurePaused:
      return {
        ...state,
        isPaused: true,
      }

    case AdventureReducerActionType.CurrentAdventureResumed:
      return {
        ...state,
        isPaused: false,
      }

    default:
      return state
  }
}

export const useAdventureContext = () => {
  const context = useContext(AdventureContext)

  if (context === undefined) {
    throw new Error(
      "useAdventureContext must be used within a AdventureContext provider"
    )
  }

  return context
}

const initialAdventureState: AdventureContextState = {
  currentElement: null,
  currentAdventure: null,
  currentAdventureTemplate: null,
  isPaused: false,
  isQuerying: false,
  isMutating: false,
}

export const AdventureProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    adventureReducer,
    initialAdventureState
  ) as [AdventureContextState, Dispatch<{ type: string; payload?: any }>]

  const { showInfo } = useToast()

  const { today, isReady } = useAgendaSchedulingContext()
  const {
    formatDate,
    language,
    isInitialized: isLanguageReady,
  } = useLocaleContext()
  const { isSessionActive } = useAuthenticatedClientContext()

  const { loading: loadingCurrentAdventure } =
    useQuery<GetCurrentAdventureQuery>(GetCurrentAdventureDocument, {
      skip: !isSessionActive || !isReady,
      onCompleted: (data) => {
        dispatch({
          type: AdventureReducerActionType.CurrentAdventureLoaded,
          payload: {
            currentAdventure: data.currentAdventure,
          },
        })
      },
      onError: (error) => {
        if (error.message === "Adventure not found") {
          console.debug("Adventure not found")
        }
      },
    })

  useQuery<GetAdventureTemplateQuery, GetAdventureTemplateQueryVariables>(
    GetAdventureTemplateDocument,
    {
      fetchPolicy: "cache-first",
      skip: !isLanguageReady || isNil(state.currentAdventure),
      onCompleted: (data) => {
        dispatch({
          type: AdventureReducerActionType.CurrentAdventureTemplateLoaded,
          payload: {
            currentAdventureTemplate: data.getAdventureTemplate,
          },
        })
      },
      variables: {
        locale: language as unknown as Language,
        id: state.currentAdventure?.squaloAdventureId,
      },
    }
  )

  const [resumeAdventureMutation, { loading: resumingAdventure }] =
    useResumeAdventureMutation({
      variables: {
        adventureUuid: state.currentAdventure?.adventureUuid,
        resumeDate: formatDate(today.date, "yyyy-MM-dd"),
      },
      onCompleted: () => {
        dispatch({
          type: AdventureReducerActionType.CurrentAdventureResumed,
        })
      },
      refetchQueries: [GetCurrentAdventureDocument],
    })

  const handleResumeAdventure = (resumeDate?: Date) => {
    if (isNil(state.currentAdventure)) {
      showInfo("No current adventure to resume")

      return
    }

    if (isNil(resumeDate)) {
      resumeAdventureMutation()
    } else {
      resumeAdventureMutation({
        variables: {
          adventureUuid: state.currentAdventure?.adventureUuid,
          resumeDate: formatDate(resumeDate, "yyyy-MM-dd"),
        },
      })
    }
  }

  const [pauseAdventure, { loading: pausingAdventure }] =
    usePauseAdventureMutation({
      variables: {
        adventureUuid: state.currentAdventure?.adventureUuid,
      },
      onCompleted: () => {
        dispatch({
          type: AdventureReducerActionType.CurrentAdventurePaused,
        })
      },
      refetchQueries: [GetCurrentAdventureDocument],
    })

  const handlePauseAdventure = useCallback(() => {
    if (isNil(state.currentAdventure)) {
      showInfo("No current adventure to pause")

      return
    }

    pauseAdventure()
  }, [pauseAdventure, state.currentAdventure])

  const value = {
    ...state,
    resumeAdventure: handleResumeAdventure,
    pauseAdventure: handlePauseAdventure,
    isQuerying: loadingCurrentAdventure,
    isMutating: pausingAdventure || resumingAdventure,
  }

  return (
    <AdventureContext.Provider value={value}>
      {children}
    </AdventureContext.Provider>
  )
}

import { useEffect } from "react"
import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"
import { isNil } from "lodash"
import { OnboardMemberDocument } from "../../generated/graphql"
import Loading from "../../components/Loading"
import Page from "../../components/Core/Page"
import { useIonRouter } from "@ionic/react"
import { useMutation } from "@apollo/client"
import { Capacitor } from "@capacitor/core"
import { useRedirectionState } from "../../routers/AuthRouter"
import { useHistory } from "react-router"

const WelcomeEnd: React.FC = () => {
  const router = useIonRouter()
  const history = useHistory()

  const { currentMember } = useAuthenticatedClientContext()

  const [redirectionState] = useRedirectionState()

  const goToHub = () => {
    if (redirectionState?.shouldRedirect) {
      history.push({
        pathname: redirectionState.to,
        search: redirectionState.search,
      })
    } else if (Capacitor.isNativePlatform()) {
      router.push("/app/hub/home", "forward")
    } else {
      router.push("/app/welcome/download", "forward")
    }
  }

  const [onboardMember] = useMutation(OnboardMemberDocument, {
    onCompleted: () => {
      goToHub()
    },
    onError: () => {
      goToHub()
    },
  })

  useEffect(() => {
    if (isNil(currentMember)) {
      return
    }

    onboardMember()
  }, [currentMember])

  useEffect(() => {
    // go to hub after 2 seconds
    const timeout = setTimeout(() => {
      goToHub()
    }, 2000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <Page>
      <Loading />
    </Page>
  )
}

export default WelcomeEnd

import { IonItem, useIonPicker } from "@ionic/react"
import classNames from "clsx"
import get from "lodash/get"
import isNil from "lodash/isNil"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { SessionExpectedDuration } from "../../../generated/graphql"
import { expectedDurationLabels } from "../../../labels"
import { MOVEMENT, NAME_SPACES } from "../../../locales/constants"
import { expectedDurationOrder } from "../../../utils"
import { FormFieldProps } from "../../Forms/Field"
import { DurationIcon } from "../../Core/Icons"

export interface ExpectedDurationPickerProps
  extends FormFieldProps<SessionExpectedDuration> {
  name: string
}

export const ExpectedDurationSpan: React.FC<{
  expectedDuration?: SessionExpectedDuration
  hideLabel?: boolean
  className?: string
}> = ({ expectedDuration, hideLabel, className }) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)

  return (
    <div
      className={classNames(
        hideLabel && "min-w-max w-full",
        "flex flex-row items-center font-semibold tracking-tight",
        className
      )}
    >
      <span className="w-full min-w-max">
        {get(
          expectedDurationLabels,
          expectedDuration || "",
          t(`${MOVEMENT.FORM}.CHOOSE`)
        )}
      </span>
      <DurationIcon className={classNames(!hideLabel && "h-8 w-8")} />
    </div>
  )
}

export const ExpectedDurationPicker: React.FC<ExpectedDurationPickerProps> = ({
  name,
  register,
  setValue,
  value,
  hideLabel,
  className,
}) => {
  const { t } = useTranslation([NAME_SPACES.MOVEMENT, NAME_SPACES.COMMON])

  const label = t(`MOVEMENT:FORM.DURATION.LABEL`)

  const [expectedDuration, setExpectedDuration] = React.useState(value)

  const [present] = useIonPicker()

  if (isNil(register) || isNil(setValue)) {
    return <></>
  }

  register(name)

  useEffect(() => {
    if (expectedDuration) {
      setValue(expectedDuration, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      })
    }
  }, [expectedDuration])

  const expectedDurationOptions = expectedDurationOrder.map((duration) => ({
    text: expectedDurationLabels[duration],
    value: duration,
  }))

  return (
    <IonItem
      lines="none"
      button
      detail={false}
      onClick={() =>
        present({
          buttons: [
            {
              text: t(`COMMON:FORMS.CANCEL`),
              role: "cancel",
            },
            {
              text: t(`COMMON:FORMS.CONFIRM`),
              handler: (selected) => {
                setExpectedDuration(selected.expectedDuration.value)
              },
            },
          ],
          columns: [
            {
              name: "expectedDuration",
              selectedIndex: expectedDurationOrder.indexOf(
                expectedDuration || SessionExpectedDuration.Medium
              ),
              options: expectedDurationOptions,
            },
          ],
        })
      }
    >
      <div className="flex items-center justify-between w-full px-4 py-3 m-0 flew-row min-w-max">
        {!hideLabel && (
          <span className="flex-grow font-light tracking-tighter text-neutral">
            {label}
          </span>
        )}

        <ExpectedDurationSpan
          expectedDuration={expectedDuration}
          hideLabel={hideLabel}
          className={classNames(className, "text-primary")}
        />
      </div>
    </IonItem>
  )
}

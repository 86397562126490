import classNames from "clsx"
import { closeOutline } from "ionicons/icons"
import React, { forwardRef } from "react"
import {
  DeepPartial,
  FieldValues,
  FormProvider,
  UseFormProps,
  useForm,
  useFormContext,
} from "react-hook-form"
import { useTranslation } from "react-i18next"

import { NAME_SPACES } from "../../locales/constants"
import Button, { ButtonProps } from "./Button"

export interface GridFormButtonsProps extends ButtonProps {
  onClear?: () => void
}

export const GridFormButtons: React.FC<GridFormButtonsProps> = ({
  className,
  onClear,
  disabled,
  ...props
}) => {
  const { formState } = useFormContext()

  return (
    <div className={classNames("absolute left-0 z-50 bottom-0 right-0", "p-4")}>
      <div
        className={classNames(
          "w-full",
          "mb-safe",
          "flex flex-row justify-between gap-x-4"
        )}
      >
        <Button
          disabled={disabled || !formState.isValid}
          {...props}
          type="submit"
          expand="block"
          iconSlot="start"
          iconClassName="absolute left-0"
          className={classNames("w-full m-0", className)}
        />
        {onClear && (
          <Button
            type="reset"
            mode="ios"
            onClick={onClear}
            fill="solid"
            color="white"
            className={classNames("m-0 bg-white rounded-sm")}
            icon={closeOutline}
            iconSlot="icon-only"
            iconClassName="text-primary"
          />
        )}
      </div>
    </div>
  )
}

export const Grid: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div
      className={classNames(
        "grid grid-cols-2 gap-3 overflow-x-hidden overflow-y-auto py-2",
        className
      )}
    >
      {children}
    </div>
  )
}

export interface GridFieldValues extends FieldValues {
  defaultField?: keyof GridFieldValues | null
}

export interface GridFormProps<FormData extends GridFieldValues>
  extends UseFormProps<FormData> {
  submitCTA?: string
  disableSubmit?: boolean
  className?: string
  onValidSubmit?: (data: any) => void
  onInvalidSubmit?: (errors: any) => void
  defaultField?: keyof FormData | null
  onClear?: () => void
}

export const GridForm = <FormData extends FieldValues>({
  onValidSubmit,
  onInvalidSubmit,
  defaultValues,
  submitCTA,
  children,
  disableSubmit,
  className,
  onClear,
  ...props
}: React.PropsWithChildren<GridFormProps<FormData>>) => {
  const { t } = useTranslation(NAME_SPACES.COMMON)

  const methods = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      ...(defaultValues as DeepPartial<FormData>),
    },
    ...props,
  })

  const handleValidSubmit = (data: FormData) => {
    onValidSubmit && onValidSubmit(data)
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleValidSubmit, onInvalidSubmit)}
        className={classNames(
          "w-full h-full",
          "flex flex-col",
          "bg-neutral-100",
          className
        )}
      >
        <Grid className={classNames("pb-24 p-4")}>{children}</Grid>
        {onValidSubmit && (
          <GridFormButtons
            onClear={onClear}
            label={submitCTA || t("FORMS.SUBMIT")}
            disabled={disableSubmit}
          />
        )}
      </form>
    </FormProvider>
  )
}

export interface GridFormSubmitProps extends ButtonProps {
  onSubmit: (data: any) => Promise<void>
  onClear?: () => void
}

GridForm.Submit = forwardRef(function GridFormSubmit(
  { className, onSubmit, onClear, ...props }: GridFormSubmitProps,
  ref: React.Ref<HTMLIonButtonElement>
) {
  const methods = useFormContext()

  return (
    <div className={classNames("absolute left-0 bottom-0 right-0", "p-4")}>
      <div
        className={classNames(
          "w-full",
          "mb-safe",
          "flex flex-row justify-between gap-x-4",
          className
        )}
      >
        <Button
          {...props}
          ref={ref}
          type="submit"
          expand="block"
          iconSlot="start"
          iconClassName="absolute left-0"
          className={classNames("w-full m-0")}
          onClick={methods.handleSubmit(onSubmit)}
        />
        {onClear && (
          <Button
            type="reset"
            mode="ios"
            onClick={onClear}
            fill="solid"
            color="white"
            className={classNames("m-0 bg-white rounded-sm")}
            icon={closeOutline}
            iconSlot="icon-only"
            iconClassName="text-primary"
          />
        )}
      </div>
    </div>
  )
})

export const GridFormSection: React.FC<{
  title?: string
  hidden?: boolean
  className?: string
}> = ({ title, children, hidden, className }) => {
  return (
    <div className={classNames("w-full h-full col-span-2", hidden && "hidden")}>
      {title && (
        <div
          className={classNames("text-xl font-normal text-neutral-700", "p-2")}
        >
          {title}
        </div>
      )}
      <Grid className={className}>{children}</Grid>
    </div>
  )
}

import { MICO } from "../constants"

export default {
  [MICO.HOME]: {
    TITLE: "Découvre",
    TABS: {
      ADVENTURES: "Aventures",
      VIDEOS: "Vidéos",
      MOVES: "Moves",
    },
  },
  [MICO.FORM]: {
    LANGUAGE: "Langue",
    WITHOUT_IMPACT: "Sans impact?",
    MODALITY: "Modalité",
    STYLE: "Style",
    FILTERS: "Filtres",
    CLEAR: "Tout effacer",
    OK: "OK",
    SEARCH: "Rechercher",
  },
  [MICO.EXPLORER]: {
    AN_ERROR_HAS_OCCURED: "Une erreur est survenue",
    EXPLORE: "Découvre",
    EMPTY_MESSAGE:
      "Aucune vidéo trouvée. Essayez de changer vos filtres ou de rechercher autre chose.",
    PLACEHOLDER_SEARCH: "Rechercher",
    FILTERS: {
      TITLE: "Filtres",
    },
  },
  [MICO.VIEWER]: {
    DETAILS: "Détails",
    LANGUAGE: "Langue",
    DURATION: "Durée",
    TARGET_AREAS: "Zone(s) cible(s)",
    EQUIPMENT: "Matériel(s)",
    FLAG_LABEL_ISSUE: {
      LABEL: "Un problème ?",
      VALUE: "Suggérer une correction",
    },
    VIDEO_NOT_FOUND: "Vidéo pas trouvée",
  },
  [MICO.CARD]: {
    START: "Voir vidéo",
  },
  [MICO.FOLLOW_ALONG]: {
    OPEN_MOVE: "OpenMove",
    RESOURCE_TYPE: "Vidéo publique",
    CTA: {
      START: "Commencer maintenant",
      SCHEDULE: "Planifier pour après",
    },
  },
  [MICO.GAMEPLAN]: {},
}

import clsx from "clsx"
import { useMemo } from "react"
import { RiCheckDoubleLine } from "react-icons/ri"
import {
  MovementModality,
  SqualoAdventure,
  SqualoAdventureActivity,
} from "../../generated/graphql"
import isNil from "lodash/isNil"
import { useAdventureContext } from "../../contexts/AdventureContext"
import { usePurchaseContext } from "../../contexts/PurchaseContext"
import { getElementContrastColor } from "../../utils/elementUtils"
import { useTranslation } from "react-i18next"
import { NAME_SPACES } from "../../locales/constants"
import { AdventurePhaseLockedConversionHook } from "../Conversion/AdventurePhaseLockedConversionHook"
import { isAdventurePremium } from "./AdventurePreviewCard"
import { SessionExpectedDuration } from "../../generated/graphql"
import { AdventureActivityCard } from "./AdventureTemplateActivityCard"

export const AdventureTemplatePhase: React.FC<{
  adventureTemplate?: SqualoAdventure
  phaseIndex: number
  isOnlyPhase?: boolean
}> = ({ phaseIndex, adventureTemplate, isOnlyPhase = true }) => {
  const { t } = useTranslation(NAME_SPACES.ADVENTURE)

  const { isPremium } = usePurchaseContext()
  const {
    currentAdventure: adventure,
    currentElement: currentAdventureElement,
  } = useAdventureContext()

  const element = adventureTemplate?.element || currentAdventureElement

  const generateFakeActivities = (count: number) => {
    const activities: SqualoAdventureActivity[] = []

    for (let i = 0; i < count; i++) {
      activities.push({
        id: i.toString(),
        title: `Activity ${i}`,
        description: `Description ${i}`,
        movementModality: [
          MovementModality.Cardio,
          MovementModality.Strength,
          MovementModality.Mobility,
        ][i % 3],
        expectedDuration: SessionExpectedDuration.Medium,
      })
    }

    return activities
  }

  const isCurrentAdventure = useMemo(() => {
    return adventure?.squaloAdventureId === adventureTemplate?.id
  }, [adventure, adventureTemplate])

  const isPhaseCompleted = useMemo(() => {
    if (isNil(adventure) || isNil(adventure.currentPhaseIndex)) {
      return false
    }

    return adventure.currentPhaseIndex > phaseIndex
  }, [adventure?.currentPhaseIndex, phaseIndex])

  const isPhaseLocked = useMemo(() => {
    if (isPremium) {
      return false
    } else {
      if (isCurrentAdventure) {
        return phaseIndex > (adventure?.currentPhaseIndex || 0)
      } else {
        return isAdventurePremium(adventureTemplate as SqualoAdventure)
      }
    }
  }, [adventure, phaseIndex, isPremium, isCurrentAdventure])

  const phase = adventureTemplate?.phases?.[phaseIndex]

  let activities = phase?.activities || []

  if (isPhaseLocked && activities.length === 0) {
    activities = generateFakeActivities(3)
  }

  return (
    <div
      className={clsx(
        "flex flex-col gap-y-4 w-full",
        isPhaseCompleted && "opacity-80",
        !isOnlyPhase && "border-b-1 border-neutral-200 border-opacity-50 py-2"
      )}
    >
      {!isOnlyPhase && (
        <div className="flex flex-col items-start w-full gap-y-1">
          <span className="text-xs font-medium uppercase">
            {t("TEMPLATE.TABS.PHASES.PHASE_N", { index: phaseIndex + 1 })}
          </span>

          <div className="flex flex-row items-center justify-between w-full gap-x-2">
            <span className="text-xl font-semibold text-justify">
              {phase?.title}
            </span>

            {isPhaseCompleted ? (
              <RiCheckDoubleLine className="text-3xl" />
            ) : (
              <></>
            )}
          </div>

          <span className="font-medium text-justify">{phase?.description}</span>
        </div>
      )}

      <div
        className={clsx(
          "grid grid-cols-1 md:grid-cols-2 w-full relative",
          "p-4 gap-4",
          "rounded-lg shadow-inner",
          `bg-${getElementContrastColor(element)} bg-opacity-20`
        )}
      >
        {activities?.map((activity, index) => (
          <AdventureActivityCard
            key={index}
            activity={activity as SqualoAdventureActivity}
          />
        ))}

        {isPhaseLocked ? <AdventurePhaseLockedConversionHook /> : <></>}
      </div>
    </div>
  )
}

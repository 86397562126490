import { useEffect, useMemo, useState } from "react"
import { IonProgressBar } from "@ionic/react"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { MEMBERSHIP, NAME_SPACES } from "../../locales/constants"
import { StaminaLevel } from "./StaminaLevel"
import { useQuery } from "@apollo/client"
import {
  ListRecentMovementSessionsDocument,
  ListRecentMovementSessionsQuery,
} from "../../generated/graphql"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import isNil from "lodash/isNil"
import { differenceInDays } from "date-fns"
import { useLocaleContext } from "../../contexts/LocaleContext"
import { RiInformationLine } from "react-icons/ri"
import { Browser } from "@capacitor/browser"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"

export const StaminaMeter = () => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)
  const TEXT = t(MEMBERSHIP.TODAY, { returnObjects: true })

  const { isReady, today } = useAgendaSchedulingContext()
  const { parseISODate } = useLocaleContext()
  const { captureEvent } = useAnalyticsContext()

  const { data, loading } = useQuery<ListRecentMovementSessionsQuery>(
    ListRecentMovementSessionsDocument,
    {
      skip: !isReady,
      variables: {
        days: 7,
      },
    }
  )

  const [staminaLevel, setStaminaLevel] = useState(StaminaLevel.Inactive)

  const handleClick = async () => {
    captureEvent(AnalyticsEvent.StaminaMeterClicked, {
      staminaLevel,
    })

    await Browser.open({
      url: TEXT.STAMINA_METER.LINK,
      presentationStyle: "popover",
      windowName: "_blank",
    })
  }

  const color = useMemo(() => {
    switch (staminaLevel) {
      case StaminaLevel.Inactive:
        return "neutral"

      case StaminaLevel.Okay:
        return "warning"

      case StaminaLevel.Good:
        return "success"

      case StaminaLevel.Great:
        return "KNOWLEDGE"

      default:
      case StaminaLevel.Excellent:
        return "primary"
    }
  }, [staminaLevel])

  const progress = useMemo(() => {
    switch (staminaLevel) {
      case StaminaLevel.Inactive:
        return 0.2
      case StaminaLevel.Okay:
        return 0.4
      case StaminaLevel.Good:
        return 0.6
      case StaminaLevel.Great:
        return 0.8
      case StaminaLevel.Excellent:
        return 1
    }
  }, [staminaLevel])

  useEffect(() => {
    if (loading) return

    if (isNil(data)) {
      return
    }

    const sessions = data.recentMovementSessions

    const last3DaysSessions = sessions.filter((s) => {
      const date = parseISODate(s.date)

      if (isNil(date)) {
        return false
      }

      return differenceInDays(today.date, date) <= 3
    })

    if (sessions.length === 0) {
      setStaminaLevel(StaminaLevel.Inactive)
    } else if (last3DaysSessions.length === 0) {
      setStaminaLevel(StaminaLevel.Okay)
    } else if (sessions.length < 3) {
      setStaminaLevel(StaminaLevel.Good)
    } else if (sessions.length < 7) {
      setStaminaLevel(StaminaLevel.Great)
    } else {
      setStaminaLevel(StaminaLevel.Excellent)
    }
  }, [data, loading])

  return (
    <button
      className="flex flex-col items-center justify-center w-full bg-white"
      onClick={handleClick}
    >
      <div className="flex flex-col items-start justify-around w-full max-w-lg p-4 mx-auto gap-y-2">
        <div className="flex flex-row items-center justify-between w-full">
          <span className="text-sm font-semibold text-neutral-600 opacity-70">
            {TEXT.STAMINA_METER.LABEL}
          </span>

          <RiInformationLine className="text-xl text-neutral-500 opacity-70" />
        </div>

        <div className="flex flex-col items-start justify-between w-full gap-1">
          <span className={clsx("font-semibold text-lg", `text-${color}-600`)}>
            {TEXT.STAMINA_METER.LEVELS[staminaLevel]}
          </span>

          <IonProgressBar
            style={{
              "--progress-background": "var(--ion-color-medium)",
            }}
            color={color}
            value={progress}
            className="h-4 shadow-inner rounded-2xl"
          />
        </div>
      </div>
    </button>
  )
}

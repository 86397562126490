import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { RiCheckboxCircleLine } from "react-icons/ri"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import { ModalOrchestrationName } from "../../contexts/ModalOrchestrationContext"
import { usePurchaseContext } from "../../contexts/PurchaseContext"

import { MEMBERSHIP, NAME_SPACES } from "../../locales/constants"
import { ConnectGoogleCalendarButton } from "../Auth/Google/ConnectGoogleCalendarButton"
import { GoogleCalendarIcon } from "../Auth/Google/icons"
import { GridFormField } from "../Forms/GridFormField"

const CalendarSettingsBody = () => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)

  const { agenda } = useAgendaSchedulingContext()
  const isCalendarConnected = agenda?.schedulingCalendarConnected

  return (
    <div className="flex flex-col w-full h-full p-4 divide-y gap-y-4 divide-neutral-300">
      <div className="flex flex-col max-w-sm py-4 gap-y-2">
        <div
          className={clsx(
            "flex flex-row items-center flex-grow text-white rounded-md shadow-sm gap-y-4 bg-emerald-500",
            isCalendarConnected ? "block" : "hidden"
          )}
        >
          <div className="flex flex-row items-center flex-grow px-3 py-4 gap-x-2">
            <RiCheckboxCircleLine className="w-8 h-8" />
            <span className="text-white">
              {t("PROFILE.SETTINGS.PRO.CALENDAR.FIELD.VALUES.CONNECTED_TO", {
                calendarName: "Google Calendar",
              })}
            </span>
          </div>
        </div>
        <ConnectGoogleCalendarButton className={clsx("w-full")} />
      </div>
    </div>
  )
}

export const CalendarSettingsField: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)
  const {
    SETTINGS: {
      PRO: { CALENDAR },
    },
  } = t(MEMBERSHIP.PROFILE, { returnObjects: true })

  const { agenda } = useAgendaSchedulingContext()
  const { isPremium } = usePurchaseContext()

  const isCalendarConnected = agenda?.schedulingCalendarConnected

  const formatCalendarSettings = (_val: any) => {
    if (isCalendarConnected) {
      return CALENDAR.FIELD.VALUES.CONNECTED
    }

    return CALENDAR.FIELD.VALUES.NOT_CONNECTED
  }

  const modalName = isPremium
    ? ModalOrchestrationName.CalendarSettings
    : ModalOrchestrationName.GetPremium

  return (
    <GridFormField
      name={"calendar"}
      modalName={modalName}
      label={CALENDAR.FIELD.LABEL}
      Body={CalendarSettingsBody}
      Icon={GoogleCalendarIcon}
      title={CALENDAR.TITLE}
      formatValue={formatCalendarSettings}
      showDismiss
      showSubmitButton={false}
      required
    />
  )
}

import { IonSkeletonText } from "@ionic/react"
import { isNil } from "lodash"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { GetMovementSessionQuery } from "../../generated/graphql"
import { MOVEMENT, NAME_SPACES } from "../../locales/constants"
import { formatStyle } from "../../utils/format"

export const SessionTitle: React.FC<{
  session: GetMovementSessionQuery["movementSession"] | undefined
}> = ({ session }) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)

  if (isNil(session)) {
    return (
      <div className="flex flex-col text-center gap-y-1">
        <span className="text-xl font-bold leading-5 text-white">
          <IonSkeletonText />
        </span>
        <span className="font-medium text-white/80">
          <IonSkeletonText />
        </span>
      </div>
    )
  }

  if (session.followAlong) {
    return (
      <div className="flex flex-col text-center lg:text-left gap-y-1">
        <span className="text-xl font-bold leading-5 text-white">
          {session?.followAlong?.title}
        </span>
        <span className="font-medium text-white">
          {session?.followAlong?.creatorName}
        </span>
      </div>
    )
  }

  return (
    <div className="flex flex-col text-center gap-y-1">
      <span className="text-xl font-bold leading-5 text-white">
        {`${t(`${MOVEMENT.CURRENT_MOVEMENT_SESSION}.TITLE`)} ${formatStyle(
          session.movementStyle
        )}`}
      </span>
    </div>
  )
}

export default SessionTitle

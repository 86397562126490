import clsx from "clsx"
import { ForwardedRef, forwardRef } from "react"

interface PingContainerProps {
  color?: string
  showPing?: boolean
  containerClassName?: string
  className?: string
  ref?: ForwardedRef<any>
}

const PingContainer: React.ForwardRefExoticComponent<
  React.PropsWithChildren<PingContainerProps>
> = forwardRef(function PingContainer(
  {
    color = undefined,
    children,
    showPing = false,
    containerClassName = "",
    className,
    ...props
  }: React.PropsWithChildren<PingContainerProps>,
  ref: ForwardedRef<any>
) {
  color = color || "primary"

  return (
    <div
      className={clsx("relative z-10", containerClassName)}
      ref={ref}
      {...props}
    >
      {children}
      {showPing && (
        <span className={clsx("absolute z-[10000] flex w-3 h-3", className)}>
          <span
            className={clsx(
              "absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping",
              `bg-${color}-400`
            )}
          ></span>
          <span
            className={clsx(
              "relative inline-flex w-3 h-3 rounded-full",
              `bg-${color}-500`
            )}
          ></span>
        </span>
      )}
    </div>
  )
})

export default PingContainer

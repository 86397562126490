import { useIonRouter } from "@ionic/react"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router"
import Content from "../../components/Core/Content"
import Page from "../../components/Core/Page"
import Button from "../../components/Forms/Button"
import { useAnalyticsContext } from "../../contexts/AnalyticsContext"
import { NAME_SPACES, WELCOME } from "../../locales/constants"
import { elementOrder } from "../../utils/elementUtils"
import { ElementCard } from "../../components/Welcome/ElementCard"
import { SqualoAdventureElement } from "../../generated/graphql"
import { useEffect, useMemo, useState } from "react"
import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"
import { sortBy } from "lodash"

const ElementSelectionPage: React.FC<RouteComponentProps> = () => {
  const { t, i18n } = useTranslation(NAME_SPACES.WELCOME)
  const FIRST_ELEMENT = t(WELCOME.ELEMENTS, { returnObjects: true })

  const router = useIonRouter()

  const isInWelcome = router.routeInfo.pathname.includes("welcome")
  const isPublic = router.routeInfo.pathname.includes("public")

  const nextPath = useMemo(() => {
    if (isInWelcome) {
      return "/app/welcome/end"
    } else {
      return router.routeInfo.lastPathname
    }
  }, [isInWelcome])

  const { setPersonProperties } = useAnalyticsContext()

  const { isSessionActive, currentMember, updateMemberProfile } =
    useAuthenticatedClientContext()

  const [elementsSelected, setElementsSelected] = useState<
    SqualoAdventureElement[]
  >(currentMember?.elements || [])

  const handleElementSelected = (element: SqualoAdventureElement) => {
    if (elementsSelected.includes(element)) {
      setElementsSelected(elementsSelected.filter((e) => e !== element))
    } else {
      setElementsSelected([...elementsSelected, element])
    }
  }

  const handleContinue = () => {
    const sortedElements = sortBy(elementsSelected)

    setPersonProperties({
      elementsSelected: sortedElements,
      nElementsSelected: sortedElements.length,
    })

    if (isSessionActive) {
      updateMemberProfile({ elements: sortedElements })
    }
  }

  useEffect(() => {
    setElementsSelected(currentMember?.elements || [])
  }, [currentMember])

  return (
    <Page className="bg-primary" showBackButton backButtonRoot="/app/welcome">
      <Content
        color="primary"
        scrollY={false}
        className="relative flex flex-col items-center justify-center h-full"
      >
        <div
          className={clsx(
            "flex flex-col items-center justify-start max-h-screen max-w-3xl h-full mx-auto gap-y-4",
            "px-4 py-4",
            "pb-32 overflow-y-scroll"
          )}
        >
          <div className="flex flex-col w-full">
            <div className="flex flex-row items-center justify-start w-full my-4 gap-x-2">
              <span className="w-full text-4xl font-semibold tracking-tighter text-left">
                {FIRST_ELEMENT.TITLE}
              </span>
            </div>
            <span className="w-full font-medium text-left">
              {FIRST_ELEMENT.DESCRIPTION}
            </span>
          </div>

          <div
            className={clsx(
              "flex flex-col w-full items-center justify-start",
              "bg-primary-600 rounded-lg shadow-inner"
            )}
          >
            <div className="grid items-center justify-center w-full grid-cols-1 gap-4 p-4 overflow-y-scroll md:grid-cols-2 md:p-8">
              {elementOrder.map((element, index) => (
                <ElementCard
                  key={index}
                  element={element}
                  isSelected={elementsSelected.includes(element)}
                  onClick={handleElementSelected}
                />
              ))}
            </div>
          </div>
        </div>

        {!isPublic && (
          <div
            className={clsx(
              "sticky bottom-0 left-0 right-0 flex flex-col p-4 w-full"
            )}
          >
            <Button
              fill="solid"
              className="w-full max-w-xl mx-auto mb-safe"
              expand="block"
              textColor="primary"
              color="white"
              size="large"
              onClick={handleContinue}
              routerLink={nextPath}
              routerDirection="forward"
              label={i18n.t("COMMON:FORMS.NEXT")}
              hidden={elementsSelected.length === 0}
            />
          </div>
        )}
      </Content>
    </Page>
  )
}

export default ElementSelectionPage

import classNames, { clsx } from "clsx"
import React, { useCallback, useMemo, useState } from "react"
import Carousel from "nuka-carousel"

import { useAgendaSchedulingContext } from "../../../contexts/AgendaSchedulingContext"
import { useLocaleContext } from "../../../contexts/LocaleContext"
import { Weekday } from "../../../generated/graphql"
import { getWeekdayInitial } from "../../../labels"
import { getWeekdayDate, weekdayOrder } from "../../../utils"
import { formatWeekday } from "../../../utils/format"
import isSameDay from "date-fns/isSameDay"
import addWeeks from "date-fns/addWeeks"
import isNil from "lodash/isNil"
import {
  RiArrowDropLeftFill,
  RiArrowDropRightFill,
  RiCheckLine,
} from "react-icons/ri"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../../contexts/AnalyticsContext"

export const AgendaTimelineIndex: React.FC = () => {
  const { formatDate } = useLocaleContext()

  const {
    selectedWeekday,
    selectedWeekSchedule,
    selectedWeekStartDate,
    isSelectedWeekStale: isFetchingSelectedWeek,
    selectWeekday,
    today,
    selectPreviousWeek,
    selectNextWeek,
  } = useAgendaSchedulingContext()
  const { captureEvent } = useAnalyticsContext()

  const previousWeekStartDate = useMemo(
    () => addWeeks(selectedWeekStartDate, -1),
    [selectedWeekStartDate]
  )

  const nextWeekStartDate = useMemo(
    () => addWeeks(selectedWeekStartDate, 1),
    [selectedWeekStartDate]
  )

  const [showHint, setShowHint] = useState(true)

  const buildWeekdayOption = useCallback(
    (baseDate: Date, weekday: Weekday, index: number) => {
      const weekdayDate = getWeekdayDate(baseDate, weekday)

      const isToday = isSameDay(weekdayDate, today.date)

      const firstSession = selectedWeekSchedule[index].sessions[0]

      const isSelected = weekday === selectedWeekday

      const hasCompletedSession = !isNil(firstSession)

      const textColor =
        isToday && !hasCompletedSession ? "text-primary" : `text-${"white"}`

      const handleWeekdayClick = () => {
        captureEvent(AnalyticsEvent.AgendaHomeTimelineIndexWeekdayClicked, {
          weekday,
        })

        if (weekday === selectedWeekday) {
          // if the selected weekday is the same as the current one,
          // we want to unselect it
          selectWeekday(undefined)
          // and select it again shortly after to trigger a scroll
          setTimeout(() => {
            selectWeekday(weekday)
          }, 200)
        } else {
          selectWeekday(weekday)
        }
      }

      return (
        <div
          key={weekday}
          onClick={handleWeekdayClick}
          className={classNames("flex flex-col gap-y-1 select-none")}
        >
          <>
            <div
              className={classNames(
                textColor,
                isToday ? "bg-white" : "bg-primary",
                // hasCompletedSession &&
                //   `border-2 border-${firstSession?.movementModality}`,
                hasCompletedSession && `bg-green-500`,
                isSelected && "ring-white/20 ring-4",
                "-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none aspect-square"
              )}
            >
              <span className="sr-only">{formatWeekday(weekday)}</span>

              <span
                aria-hidden="true"
                className={classNames(
                  "h-8 w-8 rounded-full flex items-center justify-center text-lg font-semibold"
                )}
              >
                {hasCompletedSession ? (
                  <RiCheckLine className="w-7 h-7" />
                ) : (
                  getWeekdayInitial(weekday)
                )}
              </span>
            </div>
            {weekdayDate && (
              <span
                className={classNames(
                  "text-white",
                  "text-sm tracking-tight text-center mt-1"
                )}
              >
                {formatDate(weekdayDate, "d")}
              </span>
            )}
          </>
        </div>
      )
    },
    [
      selectedWeekStartDate,
      selectedWeekday,
      today,
      formatDate,
      selectedWeekSchedule,
    ]
  )

  const slides = useMemo(
    () => [previousWeekStartDate, selectedWeekStartDate, nextWeekStartDate],
    [selectedWeekStartDate]
  )

  const IndexContent = ({ date, ...props }: { date: Date }) => (
    <div
      {...props}
      className="relative flex flex-row items-center w-full py-2 space-x-1 sm:space-x-3 justify-evenly"
    >
      {weekdayOrder.map((weekday, index) =>
        buildWeekdayOption(date, weekday, index)
      )}
    </div>
  )

  if (isFetchingSelectedWeek) {
    return (
      <div className="flex flex-col w-full max-w-lg px-2 py-1 mx-auto">
        <IndexContent date={selectedWeekStartDate} />
      </div>
    )
  }

  return (
    <div className="relative flex flex-col w-full max-w-lg px-2 py-1 mx-auto">
      <Carousel
        slideIndex={1}
        beforeSlide={(currIndex, endIndex) => {
          captureEvent(AnalyticsEvent.AgendaHomeTimelineIndexSwiped, {
            direction: currIndex < endIndex ? "next" : "previous",
          })

          if (currIndex < endIndex) {
            selectNextWeek()
          } else {
            selectPreviousWeek()
          }

          setShowHint(false)
        }}
        swiping={!isFetchingSelectedWeek}
        withoutControls
      >
        {slides.map((slide, index) => (
          <IndexContent key={index} date={slide} />
        ))}
      </Carousel>
      {showHint && (
        <div
          className={clsx(
            "absolute inset-x-0 flex flex-row items-center justify-center -bottom-1 gap-x-0",
            "text-white/80 animate-pulse"
          )}
        >
          <RiArrowDropLeftFill className="w-6 h-6 -mr-1" />
          <div className="w-8 h-1 rounded-md bg-white/80" />
          <RiArrowDropRightFill className="w-6 h-6 -ml-1" />
        </div>
      )}
    </div>
  )
}

import clsx from "clsx"
import { useTranslation } from "react-i18next"
import ConcentricDisks from "../ConcentricDisks"
import { ElementIcon } from "../Core/Icons"
import { SqualoAdventureElement } from "../../generated/graphql"
import { NAME_SPACES } from "../../locales/constants"
import { getElementTextColor } from "../../utils/elementUtils"
import { formatElement } from "../../utils/format"
import { Link } from "react-router-dom"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { useIonRouter } from "@ionic/react"

export const ElementCard: React.FC<{
  element: SqualoAdventureElement
  isSelected: boolean
  onClick: (element: SqualoAdventureElement) => void
}> = ({ element, isSelected, onClick }) => {
  const router = useIonRouter()

  const { t } = useTranslation(NAME_SPACES.WELCOME)

  const { captureEvent } = useAnalyticsContext()

  const TITLE = t(`ELEMENTS.ELEMENTS.${element}.TITLE`) as string

  const isPublic = router.routeInfo.pathname.includes("public")

  const handleClick = (_event: any) => {
    captureEvent(AnalyticsEvent.ElementCardClicked, {
      element,
      isPublic,
    })

    if (isPublic) return

    onClick(element)
  }

  return (
    <Link
      to={isPublic ? "/auth" : "#"}
      target={isPublic ? "_parent" : undefined}
      rel={isPublic ? "noopener noreferrer" : undefined}
      className={clsx(
        "flex-shrink-0",
        "w-full h-32 p-4 rounded-lg shadow-md relative",
        `bg-${element}-500 backdrop backdrop-blur`,
        "overflow-hidden",
        getElementTextColor(element),
        isSelected && "ring-4 ring-white/50"
      )}
      onClick={handleClick}
    >
      <div className="relative flex flex-col items-start justify-between w-full h-full">
        <div
          className={clsx(
            "flex flex-row items-baseline justify-end gap-x-1",
            "text-4xl font-bold",
            "w-full"
          )}
        >
          <ElementIcon
            element={element}
            className="z-10 h-full my-auto"
            contrast
          />
        </div>

        <div className="flex flex-col items-start w-full gap-y-1">
          <span className="h-full text-3xl font-bold tracking-tighter">
            {formatElement(element)}
          </span>

          <span className="text-lg font-semibold text-left">{TITLE}</span>
        </div>
      </div>

      <ConcentricDisks
        className="absolute bottom-0 w-72 h-72 -right-32"
        color={`white`}
        opacity={20}
      />
    </Link>
  )
}

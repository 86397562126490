import { PushNotifications } from "@capacitor/push-notifications"
import {
  Message,
  Papercups,
  CustomerMetadata,
  Config,
} from "@papercups-io/browser"

import { detect } from "detect-browser"

import { isNil } from "lodash"
import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react"
import { DevicePlatform } from "../../generated/graphql"
import { getCurrentEnvironment } from "../../utils"
import { useAgendaSchedulingContext } from "../AgendaSchedulingContext"
import { AnalyticsEvent, useAnalyticsContext } from "../AnalyticsContext"
import { useAuthenticatedClientContext } from "../AuthenticatedClientContext"
import Widget from "./Widget"

export interface OlibaContextState {
  client: Papercups

  customerId?: string
  conversationId?: string

  messages: Message[]
  latestIncomingMessage?: Message
  hasNewMessages: boolean

  presetMessageBody?: string

  isInitialized: boolean
  isOpen: boolean
  isLoaded: boolean
}

export interface OlibaContextActions {
  openOliba: (text?: string) => void
  closeOliba: () => void
  toggle: () => void
}

export const OlibaContext = createContext<
  (OlibaContextState & OlibaContextActions) | undefined
>(undefined)

export const enum OlibaReducerActionType {
  Initialize = "INITIALIZE",
  Open = "OPEN",
  Close = "CLOSE",
  Toggle = "TOGGLE",
  Load = "LOAD",
  ReceiveMessage = "RECEIVE_MESSAGE",
  PresetMessageBodyCleared = "CLEAR_DEFAULT_MESSAGE_ON_OPEN",
}

const olibaReducer = (
  state: OlibaContextState,
  action: any
): OlibaContextState => {
  const { type, payload } = action

  switch (type) {
    case OlibaReducerActionType.Open:
      return {
        ...state,
        isOpen: true,
        hasNewMessages: false,
        presetMessageBody: payload.text,
      }

    case OlibaReducerActionType.PresetMessageBodyCleared:
      return { ...state, presetMessageBody: "" }

    case OlibaReducerActionType.Close:
      return { ...state, isOpen: false }

    case OlibaReducerActionType.Toggle:
      return { ...state, isOpen: !state.isOpen }

    case OlibaReducerActionType.Load:
      return { ...state, isLoaded: true, client: payload }

    case OlibaReducerActionType.ReceiveMessage:
      return {
        ...state,
        hasNewMessages: !state.isOpen,
        messages: [payload, ...state.messages],
        latestIncomingMessage: state.isOpen ? undefined : payload,
      }

    case OlibaReducerActionType.Initialize:
      return {
        ...state,
        isInitialized: true,
        client: payload.client,
        customerId: payload.customerId,
        conversationId: payload.conversationId,
        messages: payload.messages,
        latestIncomingMessage: payload.latestMessage,
        hasNewMessages: payload.hasNewMessages,
      }

    default:
      return state
  }
}

export const useOlibaContext = () => {
  const context = useContext(OlibaContext)

  if (context === undefined) {
    throw new Error(
      "useOlibaContext must be used within a OlibaContext provider"
    )
  }

  return context
}

const baseConfig: Config = {
  accountId: process.env.REACT_APP_OLIBA_TOKEN as string,
  inboxId: process.env.REACT_APP_OLIBA_INBOX_ID as string,
  baseUrl: process.env.REACT_APP_OLIBA_URL as string,
}

const initialOlibaState: OlibaContextState = {
  client: new Papercups(baseConfig),
  isInitialized: false,
  isOpen: false,
  isLoaded: false,
  hasNewMessages: false,
  messages: [],
  presetMessageBody: "",
}

export const OlibaProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(olibaReducer, initialOlibaState) as [
    OlibaContextState,
    Dispatch<{ type: OlibaReducerActionType; payload?: any }>
  ]

  const {
    currentMember,
    currentUser,
    isClientAuthenticated: isClientReady,
    platform,
  } = useAuthenticatedClientContext()
  const { timezone } = useAgendaSchedulingContext()
  const { captureEvent } = useAnalyticsContext()

  const handleChatWidgetOpen = () => {
    captureEvent(AnalyticsEvent.ChatOpened, {})

    dispatch({ type: OlibaReducerActionType.PresetMessageBodyCleared })
  }

  const handleChatWidgetClosed = () => {
    captureEvent(AnalyticsEvent.ChatClosed, {})

    dispatch({ type: OlibaReducerActionType.Close })
  }

  const handleChatWidgetLoaded = (papercups: Papercups) => {
    dispatch({
      type: OlibaReducerActionType.Load,
      payload: papercups,
    })
  }

  const handleMessageReceived = (message: Message) => {
    captureEvent(AnalyticsEvent.ChatMessageReceived, {})

    dispatch({ type: OlibaReducerActionType.ReceiveMessage, payload: message })
  }

  const handleMessageSent = () => {
    captureEvent(AnalyticsEvent.ChatMessageSent, {})
  }

  const openOliba = useCallback(
    (text?: string) => {
      if (state.isOpen) {
        return
      }

      if (platform !== DevicePlatform.Web) {
        PushNotifications.removeAllDeliveredNotifications()
      }

      dispatch({ type: OlibaReducerActionType.Open, payload: { text } })
    },
    [state.isOpen]
  )

  const closeOliba = useCallback(() => {
    if (!state.isOpen) {
      return
    }

    dispatch({ type: OlibaReducerActionType.Close })
  }, [state.isOpen])

  const toggle = () => {
    dispatch({ type: OlibaReducerActionType.Toggle })
  }

  const value = {
    ...state,
    openOliba,
    closeOliba,
    toggle,
  }

  const browser = detect()

  const customerMetadata = {
    name: `${currentMember?.firstName || ""} ${currentMember?.lastName || ""}`,
    email: currentUser?.email,
    external_id: currentUser?.userUuid,
    metadata: {
      movementAgendaUuid: currentUser?.userUuid,
      environment: getCurrentEnvironment(),
      version: process.env.REACT_APP_VERSION,
    },
    host: window.location.host,
    current_url: window.location.href,
    pathname: window.location.pathname,
    time_zone: timezone,
    browser: browser?.name,
    browser_version: browser?.version,
    os: browser?.os,
  } as CustomerMetadata

  const initializeOlibaConversation = async (
    client: Papercups,
    userUuid: string
  ) => {
    let existingCustomerId = await client.findCustomerByExternalId(userUuid)

    if (isNil(existingCustomerId)) {
      const customer = await client.createNewCustomer(customerMetadata)

      existingCustomerId = customer.id

      console.debug("[Oliba] created customer")
    } else {
      await client.updateExistingCustomer(existingCustomerId, customerMetadata)

      console.debug("[Oliba] updated customer", existingCustomerId)
    }

    // cache customer id
    client.cacheCustomerId(existingCustomerId)

    let latestConversation = await client.fetchLatestCustomerConversation(
      existingCustomerId as string
    )

    if (isNil(latestConversation)) {
      latestConversation = await client.createNewConversation(
        existingCustomerId as string
      )

      latestConversation.messages = []

      console.debug("[Oliba] created new conversation", latestConversation)
    }

    const unseenMessages = latestConversation?.messages.filter((m: Message) => {
      return !m.seen_at && m.type === "agent"
    })

    const newClient = client.joinConversationChannel(latestConversation.id)

    dispatch({
      type: OlibaReducerActionType.Initialize,
      payload: {
        client: newClient,
        customerId: existingCustomerId,
        conversationId: latestConversation?.id,
        messages: latestConversation?.messages,
        hasNewMessages: unseenMessages?.length > 0,
        latestMessage:
          unseenMessages?.length > 0 ? unseenMessages[-1] : undefined,
      },
    })
  }

  useEffect(() => {
    if (!isClientReady || isNil(currentUser)) return

    if (!state.isLoaded) return

    if (!state.isInitialized) {
      initializeOlibaConversation(state.client, currentUser.userUuid)
    }
  }, [state.isLoaded, state.isInitialized, currentUser, isClientReady])

  return (
    <OlibaContext.Provider value={value}>
      <>
        {children}
        <Widget
          customerId={state.customerId}
          conversationId={state.conversationId}
          messages={state.messages}
          client={state.client}
          isOpen={state.isOpen}
          onChatClosed={handleChatWidgetClosed}
          onChatLoaded={handleChatWidgetLoaded as any}
          onMessageReceived={handleMessageReceived}
          onMessageSent={handleMessageSent}
          onChatOpened={handleChatWidgetOpen}
          presetMessageBody={state.presetMessageBody}
        />
      </>
    </OlibaContext.Provider>
  )
}

import { isNil } from "lodash"
import * as React from "react"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"

import {
  AgendaDay,
  useAgendaSchedulingContext,
} from "../../../contexts/AgendaSchedulingContext"
import { Time } from "../../../contexts/AgendaSchedulingContext/agendaTimeUtils"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../../contexts/AnalyticsContext"
import { formatTime, useLocaleContext } from "../../../contexts/LocaleContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../../contexts/ModalOrchestrationContext"
import {
  AddMovementAgendaItemMutationVariables,
  BodyPartEnum,
  BodyTree,
  GetMovementAgendaItemQuery,
  ModifyMovementAgendaItemMutationVariables,
  MovementActivity,
  MovementAgendaItemSummaryFragment,
  MovementSession,
  MovementStyle,
  SessionExpectedDuration,
  Weekday,
} from "../../../generated/graphql"
import { MOVEMENT, NAME_SPACES } from "../../../locales/constants"
import { formatStyle } from "../../../utils/format"
import Modal, { ModalContext, ModalProps } from "../../Core/Modal"
import { GridForm, GridFormSection } from "../../Forms/GridForm"
import { buildSessionLabel } from "../Style"
import {
  BodyPartGridFormField,
  BodyPartSelectState,
  convertBodyTreeToBodyPartSelectState,
} from "../Forms/Fields/BodyPartSelect"
import { DurationGridFormField } from "../Forms/Fields/DurationSlider"
import {
  MicoVideoSelectData,
  MicoVideoSelectGridFormField,
} from "../Forms/Fields/MicoVideoSelect"
import { MovementStyleGridFormField } from "../Forms/Fields/MovementStyleSelect"
import { AgendaItemTitleInput } from "../Forms/Fields/MovementTitleInput"
import { ScheduleGridFormField } from "../Forms/Fields/SchedulePicker"
import { TimeGridFormField } from "../Forms/Fields/TimeSlider"
import { getWeekdayDate } from "../../../utils"

export type MovementAgendaItemFormData = {
  title?: string | null
  micoVideo?: MicoVideoSelectData
  movementStyle?: MovementStyle
  movementTargetBodyParts?: BodyPartSelectState
  plannedSchedule: {
    isRecurring: boolean
    weekStartDate?: Date
    weekday: Weekday
  }
  plannedStartTime?: Time
  duration: SessionExpectedDuration
}

export interface MovementAgendaItemFormProps {
  agendaItem?:
    | MovementAgendaItemSummaryFragment
    | GetMovementAgendaItemQuery["movementAgendaItem"]
  activity?: MovementActivity
  followAlong?: MovementSession["followAlong"]
  day?: AgendaDay
  openWithField?: keyof MovementAgendaItemFormData
}

const MovementAgendaItemForm: React.FC<MovementAgendaItemFormProps> = ({
  day,
  agendaItem,
  followAlong,
  activity,
}) => {
  const { name } = React.useContext(ModalContext)

  const formAction = isNil(agendaItem) ? "add" : "modify"

  const { captureEvent } = useAnalyticsContext()
  const { parseISOTime, formatDate } = useLocaleContext()
  const {
    getDay,
    selectedWeekStartDate,
    selectedWeekUuid,
    selectWeekDate,
    addAgendaItem,
    modifyAgendaItem,
    agendaItemActionLoading,
  } = useAgendaSchedulingContext()

  const { toggleLoading, closeModal, updateModalState } =
    useModalOrchestrationContext()

  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.ITEM_PLANNING, { returnObjects: true })

  const buildDefaultValues = (
    agendaItem?: MovementAgendaItemFormProps["agendaItem"],
    followAlong?: MovementAgendaItemFormProps["followAlong"]
  ): MovementAgendaItemFormData => {
    if (!isNil(agendaItem)) {
      return {
        title: agendaItem.title,
        movementStyle: agendaItem.movementStyle,
        movementTargetBodyParts: convertBodyTreeToBodyPartSelectState(
          agendaItem.movementTargetBodyParts as BodyTree | undefined
        ),
        plannedSchedule: {
          weekday: getDay(agendaItem.weekday).weekday,
          weekStartDate: selectedWeekStartDate,
          isRecurring: agendaItem.isRecurring,
        },
        plannedStartTime: parseISOTime(agendaItem?.plannedStartTime),
        duration: agendaItem.expectedDuration,
      }
    } else if (!isNil(followAlong)) {
      return {
        title: `${formatStyle(followAlong.movementStyle)} | ${
          followAlong.creatorName
        }`,
        movementStyle: followAlong.movementStyle,
        movementTargetBodyParts: convertBodyTreeToBodyPartSelectState(
          followAlong.movementTargetBodyParts as BodyTree | undefined
        ),
        plannedSchedule: {
          weekday: getDay(day?.weekday).weekday,
          weekStartDate: selectedWeekStartDate,
          isRecurring: false,
        },
        plannedStartTime: undefined,
        duration: followAlong.expectedDuration,
        micoVideo: followAlong,
      }
    } else if (!isNil(activity)) {
      return {
        title: buildSessionLabel(activity),
        movementStyle: activity.movementStyle,
        plannedSchedule: {
          weekday: getDay(day?.weekday).weekday,
          weekStartDate: selectedWeekStartDate,
          isRecurring: false,
        },
        duration: activity.usualDuration || SessionExpectedDuration.Medium,
      }
    } else {
      return {
        movementStyle: undefined,
        plannedSchedule: {
          weekday: getDay(day?.weekday).weekday,
          weekStartDate: selectedWeekStartDate,
          isRecurring: false,
        },
        duration: SessionExpectedDuration.Medium,
      }
    }
  }

  const mapFormDataToVariables = (
    data: MovementAgendaItemFormData,
    isRecurring: boolean
  ) => {
    let movementTargetBodyParts: BodyPartEnum[] = []

    if (data.movementTargetBodyParts) {
      const bodyPart = (data.movementTargetBodyParts.muscle ||
        data.movementTargetBodyParts.muscleGroup ||
        data.movementTargetBodyParts.region) as unknown as BodyPartEnum

      if (data.movementTargetBodyParts.isFullBody) {
        movementTargetBodyParts = [BodyPartEnum.FullBody]
      } else if (bodyPart) {
        movementTargetBodyParts = [bodyPart]
      }
    }

    const title = buildSessionLabel({
      title: data.title,
      movementStyle: data.movementStyle,
    })

    const instanceDate = formatDate(
      getWeekdayDate(
        data.plannedSchedule.weekStartDate || selectedWeekStartDate,
        data.plannedSchedule.weekday
      ),
      "yyyy-MM-dd"
    )

    const result = {
      title,
      movementTargetBodyParts,
      expectedDuration: data.duration,
      movementStyle: data.movementStyle,
      plannedStartTime: data.plannedStartTime
        ? formatTime(data.plannedStartTime)
        : null,
      squaloVideoUuid: data.micoVideo?.uuid,
      recurringWeekday: isRecurring ? data.plannedSchedule.weekday : null,
      movementWeekUuid: isRecurring ? undefined : selectedWeekUuid,
      instanceDate: isRecurring ? null : instanceDate,
    }

    // add weekday to variables if we are modifying an item
    if (formAction === "modify") {
      return {
        ...result,
        weekday: data.plannedSchedule.weekday,
      }
    }

    return result
  }

  const handleAdd = async (data: MovementAgendaItemFormData) => {
    const variables = mapFormDataToVariables(
      data,
      data.plannedSchedule.isRecurring
    ) as AddMovementAgendaItemMutationVariables

    await addAgendaItem(variables, (result) => {
      captureEvent(AnalyticsEvent.MovementAgendaItemAdded, { ...data })

      if (!isNil(data.plannedSchedule.weekStartDate)) {
        selectWeekDate(data.plannedSchedule.weekStartDate)
      }

      closeModal(name, "added", result.addMovementAgendaItem)
    })
  }

  const handleModify = async (
    data: MovementAgendaItemFormData,
    isRecurring: boolean
  ) => {
    const variables = {
      uuid: agendaItem?.uuid,
      ...mapFormDataToVariables(data, isRecurring),
    } as ModifyMovementAgendaItemMutationVariables

    await modifyAgendaItem(variables, true, (result) => {
      captureEvent(AnalyticsEvent.MovementAgendaItemModified, {
        ...data,
        onlyThisWeek: !isRecurring,
      })

      if (!isNil(data.plannedSchedule.weekStartDate)) {
        selectWeekDate(data.plannedSchedule.weekStartDate)
      }

      closeModal(name, "modified", result.modifyMovementAgendaItem)
    })
  }

  const handleSubmit = useCallback(
    async (data: MovementAgendaItemFormData) => {
      if (formAction === "add") {
        await handleAdd(data)
      } else {
        await handleModify(data, agendaItem?.isRecurring || false)
      }
    },
    [formAction, agendaItem]
  )

  const submitCTA = isNil(agendaItem)
    ? TEXT.ADD_TO_AGENDA
    : TEXT.SAVE_MODIFICATION

  const defaultValues = buildDefaultValues(agendaItem, followAlong)

  const isAdventureActivity = !isNil(agendaItem?.adventure)

  React.useEffect(() => {
    toggleLoading(agendaItemActionLoading)
  }, [agendaItemActionLoading])

  React.useEffect(() => {
    if (isNil(name)) return undefined

    updateModalState(name, {
      title: isNil(agendaItem)
        ? TEXT.TITLE.PLAN_NEW
        : TEXT.TITLE.ADAPT_EXISTING,
    })
  }, [name, agendaItem])

  return (
    <GridForm<MovementAgendaItemFormData>
      onValidSubmit={handleSubmit}
      submitCTA={submitCTA}
      disableSubmit={agendaItemActionLoading}
      defaultValues={defaultValues}
    >
      <div className="col-span-2">
        <AgendaItemTitleInput
          name="title"
          disabled={isAdventureActivity}
          className="text-2xl"
        />
      </div>
      {isNil(followAlong) ? null : (
        <MicoVideoSelectGridFormField name="micoVideo" disabled={true} />
      )}
      <GridFormSection title={TEXT.SECTIONS.MOVEMENT}>
        <MovementStyleGridFormField
          required
          name="movementStyle"
          disabled={!isNil(followAlong) || isAdventureActivity}
          recommend="favorite"
        />
        <BodyPartGridFormField
          name="movementTargetBodyParts"
          disabled={!isNil(followAlong) || isAdventureActivity}
        />
      </GridFormSection>
      <GridFormSection title={TEXT.SECTIONS.SCHEDULE}>
        <ScheduleGridFormField
          name="plannedSchedule"
          hideRecurringToggle={formAction === "modify"}
        />
        <DurationGridFormField
          name="duration"
          type="approximate"
          disabled={isAdventureActivity}
        />
        <TimeGridFormField name="plannedStartTime" window={"future"} />
      </GridFormSection>
    </GridForm>
  )
}

export const MovementAgendaItemFormModal: React.FC<Partial<ModalProps>> = ({
  name = ModalOrchestrationName.MovementAgendaItemForm,
  ...props
}) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const ITEM_PLANNING = t(MOVEMENT.ITEM_PLANNING, { returnObjects: true })

  return (
    <Modal name={name} isSheet {...props}>
      <Modal.Header title={ITEM_PLANNING.TITLE.PLAN_NEW} />

      <Modal.Body>
        {(props: any) => <MovementAgendaItemForm {...props} />}
      </Modal.Body>
    </Modal>
  )
}

export default MovementAgendaItemFormModal

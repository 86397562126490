import clsx from "clsx"
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
} from "react-beautiful-dnd"
import { AgendaTimelineDayItem } from "./AgendaTimelineDayItem"
import { MovementAgendaItemSummaryFragment } from "../../../generated/graphql"
import { useAgendaSchedulingContext } from "../../../contexts/AgendaSchedulingContext"
import Button, { ButtonProps } from "../../Forms/Button"
import { RiInboxLine } from "react-icons/ri"
import PingContainer from "../../Core/Ping"
import { first, take } from "lodash"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../../contexts/ModalOrchestrationContext"

export const AgendaSuggestionInboxToggleButton: React.FC<ButtonProps> = ({
  ...props
}) => {
  const { suggestionInboxItems } = useAgendaSchedulingContext()

  const firstSuggestion = first(suggestionInboxItems)

  return (
    <PingContainer
      className={"top-1 right-1"}
      showPing={suggestionInboxItems.length > 0}
      color={
        firstSuggestion?.adventure?.squaloAdventureElement ||
        firstSuggestion?.movementModality
      }
    >
      <Button
        icon={RiInboxLine}
        iconSlot="icon-only"
        textColor="white"
        fill="clear"
        disabled={suggestionInboxItems.length === 0}
        {...props}
      />
    </PingContainer>
  )
}

interface AgendaSuggestionInboxProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  items?: MovementAgendaItemSummaryFragment[]
}

export const AgendaSuggestionInbox: React.FC<AgendaSuggestionInboxProps> = ({
  className,
  hidden,
}) => {
  const { suggestionInboxItems } = useAgendaSchedulingContext()
  const { openModal } = useModalOrchestrationContext()

  const items = take(suggestionInboxItems, 3)

  const maybeHandleSuggestionClick = (
    item: MovementAgendaItemSummaryFragment
  ) => {
    openModal(ModalOrchestrationName.AgendaSuggestionModal, {
      agendaItemFragment: item,
    })
  }

  return (
    <Droppable droppableId="inbox" direction="vertical">
      {(provided) => {
        return (
          <div
            className={clsx("h-52 bg-transparent sm:px-2 sm:pt-2", className)}
            hidden={hidden}
            ref={provided.innerRef}
          >
            <div
              className={clsx(
                "bg-gradient-to-b from-primary-500 to-primary-400",
                "shadow-md sm:shadow-xl",
                "flex flex-col h-full w-full p-2 select-none gap-y-2 sm:rounded-lg rounded-b-lg"
              )}
            >
              <span className="px-1 font-semibold text-white">Suggestions</span>

              <div
                className={clsx(
                  "flex flex-col py-2 px-2 w-full gap-y-2 shadow-inner bg-primary-600 rounded-lg h-full"
                )}
              >
                <div className="flex flex-col justify-start w-full h-full gap-y-2">
                  {items.map((item, index) => (
                    <Draggable
                      index={index}
                      draggableId={`suggestion-${index}`}
                      key={`suggestion-${index}`}
                    >
                      {(
                        provided: DraggableProvided,
                        snapshot: DraggableStateSnapshot
                      ) => (
                        <AgendaTimelineDayItem
                          provided={provided}
                          snapshot={snapshot}
                          item={item}
                          onClick={() => maybeHandleSuggestionClick(item)}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </Droppable>
  )
}

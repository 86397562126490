import { MOVEMENT, NAME_SPACES } from "../../../../locales/constants"
import { useTranslation } from "react-i18next"
import { MovementWizardCard } from "../MovementWizardCard"

export const MoveRecommendationStartSlide: React.FC<{
  onClick: () => void
}> = ({ onClick }) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.WIZARD, { returnObjects: true })

  return (
    <MovementWizardCard
      primaryButtonProps={{
        label: TEXT.INTRODUCTION.CTAS.TRY,
        className: "bg-primary text-white",
        onClick,
      }}
    >
      <div className="flex flex-col items-start justify-start w-full h-full p-4 gap-y-2">
        <span className="text-xl font-semibold tracking-tighter">
          {TEXT.INTRODUCTION.TITLE}
        </span>
        <span className="text-lg font-medium tracking-tight text-neutral-500">
          {TEXT.INTRODUCTION.DESCRIPTION}
        </span>
      </div>
    </MovementWizardCard>
  )
}

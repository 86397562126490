import { Trans, useTranslation } from "react-i18next"
import * as Sentry from "@sentry/capacitor"
import { useContext, useEffect, useState } from "react"
import { Package } from "@capgo/capacitor-purchases"
import isNil from "lodash/isNil"
import { MEMBERSHIP, NAME_SPACES } from "../../../locales/constants"
import Button from "../../Forms/Button"
import clsx from "clsx"
import Modal, { ModalContext } from "../../Core/Modal"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../../contexts/ModalOrchestrationContext"
import { DevicePlatform } from "../../../generated/graphql"
import {
  PurchaseContextErrors,
  usePurchaseContext,
} from "../../../contexts/PurchaseContext"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../../contexts/AnalyticsContext"
import { buildPravoURL } from "../../../utils/pravo"

import { ReactComponent as FireAvatar } from "../../../assets/icons/avatars/fire.svg"
import { ReactComponent as WaterAvatar } from "../../../assets/icons/avatars/water.svg"
import { ReactComponent as EarthAvatar } from "../../../assets/icons/avatars/earth.svg"
import { ReactComponent as AirAvatar } from "../../../assets/icons/avatars/air.svg"
import { useAuthenticatedClientContext } from "../../../contexts/AuthenticatedClientContext"
import { Capacitor } from "@capacitor/core"
import { useIonRouter } from "@ionic/react"
import { Link } from "../../../utils"

const GetPremiumModalBody: React.FC<any> = ({ className }) => {
  const router = useIonRouter()

  const { t: commonT } = useTranslation(NAME_SPACES.COMMON)
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)
  const TEXT = t(MEMBERSHIP.PREMIUM, { returnObjects: true })

  const { name } = useContext(ModalContext)

  const { availablePackages, purchasePackage } = usePurchaseContext()

  // 12,99 euros
  const premiumPrice = availablePackages[0]?.product.priceString || "12,99 €"

  const { platform } = useAuthenticatedClientContext()
  const { captureEvent } = useAnalyticsContext()
  const { closeModal } = useModalOrchestrationContext()

  const [selectedPackage, setSelectedPackage] = useState<Package | undefined>()

  const handlePurchase = async () => {
    if (isNil(selectedPackage)) {
      return
    }

    await purchasePackage(
      selectedPackage.identifier,
      (purchaserInfo) => {
        captureEvent(AnalyticsEvent.InAppPurchaseCompleted, {
          package: selectedPackage?.identifier,
          latestExpirationDate: purchaserInfo.latestExpirationDate,
        })

        closeModal(name)
      },
      (error) => {
        if (error === PurchaseContextErrors.PurchaseCancelled) {
          captureEvent(AnalyticsEvent.InAppPurchaseCancelled, {
            package: selectedPackage?.identifier,
          })
        } else {
          Sentry.captureMessage(
            `Error purchasing premium subscription: ${error?.message}`,
            "error"
          )
        }
      }
    )
  }

  const handleClose = () => {
    captureEvent(AnalyticsEvent.InAppPurchaseModalClosed)

    closeModal(name, "dismiss")
  }

  const handleSubmit = async () => {
    captureEvent(AnalyticsEvent.InAppPurchaseInitiated, {
      package: selectedPackage?.identifier,
    })

    if (Capacitor.isNativePlatform()) {
      await handlePurchase()
    } else {
      router.push("/app/welcome/download", "forward")

      closeModal(name)
    }
  }

  useEffect(() => {
    if (availablePackages.length > 0) {
      setSelectedPackage(availablePackages[0])
    }
  }, [availablePackages])

  const Bold = ({ children }: any) => (
    <span className="font-bold text-CARDIO-400">{children}</span>
  )

  const buttonLabel = TEXT.TIER_1.CTA

  return (
    <div
      className={clsx(
        "flex flex-col w-full h-full",
        `bg-gradient-to-b from-primary-400 to-primary-500`,
        className
      )}
    >
      <div className="flex flex-col flex-grow px-4 pt-8 pb-32 overflow-x-hidden overflow-y-scroll gap-y-8">
        <div className="flex flex-col items-center text-white gap-y-2">
          <div className="flex flex-row items-center justify-center p-6 rounded-full h-60 w-60 bg-white/20 aspect-block">
            <div className="grid items-center justify-center grid-cols-2 grid-rows-2 gap-4 place-items-center justify-items-stretch">
              <FireAvatar className="w-16 h-16" />
              <EarthAvatar className="w-16 h-16" />
              <WaterAvatar className="w-16 h-16" />
              <AirAvatar className="w-16 h-16" />
            </div>
          </div>
          <div className="flex flex-row items-center justify-center w-full px-4">
            <h1 className="text-2xl font-semibold text-center">
              <Trans
                i18nKey={"PREMIUM.TIER_1.TITLE"}
                t={t}
                components={[<Bold key={0} />]}
              />
            </h1>
          </div>
        </div>
        <div
          className={clsx(
            "flex flex-col gap-y-4 justify-around",
            "bg-white/20 rounded-md shadow-md",
            "divide-y divide-white/20"
          )}
        >
          {TEXT.TIER_1.FEATURES.map((feature, index) => (
            <div
              className={clsx(
                "flex flex-col items-start justify-evenly",
                "ga-y-2",
                "p-4"
              )}
              key={index}
            >
              <div className="flex flex-row items-center justify-between w-full gap-4">
                <span className="text-4xl text-white">{feature.emoji}</span>
                {/* {!feature.isComingSoon && (
                  <Toggle
                    color={MovementModality.Cardio}
                    isEnabled={false}
                    onToggle={async () => await handleToggle(feature.key)}
                  />
                )} */}

                <div className="flex flex-col items-start justify-center w-full">
                  <span className="text-lg font-semibold text-white">
                    {feature.title}
                  </span>

                  <span className="text-sm font-medium text-white">
                    {feature.description}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-start justify-center w-full gap-y-2">
          <span className="text-xl font-semibold text-white">Plans</span>
          <div className="flex flex-col items-start justify-center w-full gap-y-4">
            <span className="text-sm font-semibold text-white uppercase">
              {TEXT.FREE_TRIALS.ONE_WEEK}
            </span>
          </div>
          <div
            className={clsx(
              "flex flex-col gap-y-4 justify-around w-full",
              "bg-white/10 rounded-lg shadow-sm"
            )}
          >
            <div
              className={clsx(
                "flex flex-col items-start justify-evenly",
                "gap-y-2",
                "p-4"
              )}
            >
              <div className="flex flex-row items-center justify-between w-full gap-4">
                <span className="w-full text-2xl font-semibold text-white">
                  {`1 ${TEXT.PERIODS.MONTH}`}
                </span>
                <div className="flex flex-col items-start justify-center flex-shrink-0">
                  <span className="font-medium text-white">
                    {`${selectedPackage?.product.priceString || premiumPrice} ${
                      TEXT.PERIODS.PER_MONTH
                    }`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col py-2 gap-y-2">
          <h3 className="text-xl font-semibold text-white">
            {TEXT.CANCELLATION_POLICY.TITLE}
          </h3>
          <span className="text-lg font-medium text-white">
            {TEXT.CANCELLATION_POLICY.SUBTITLE}
          </span>
        </div>
        {platform == DevicePlatform.Ios && (
          <div className="flex flex-col py-2 gap-y-2">
            <span className="text-sm text-white font-regular">
              <Trans
                t={t}
                i18nKey={"PREMIUM.LEGAL_DISCLAIMER.IOS"}
                values={{
                  purchaseAmountAndPeriod: `${selectedPackage?.product.priceString} ${TEXT.PERIODS.PER_MONTH}`,
                  termsOfService: commonT("TERMS_AND_CONDITIONS"),
                  privacyPolicy: commonT("PRIVACY_POLICY"),
                }}
                components={[
                  <Link
                    key={0}
                    href={buildPravoURL("/docs/terms")}
                    target="_blank"
                    rel="noreferrer"
                  />,
                  <Link
                    key={1}
                    href={buildPravoURL("/docs/privacy")}
                    target="_blank"
                    rel="noreferrer"
                  />,
                ]}
              />
            </span>
          </div>
        )}
        <div className="absolute bottom-0 left-0 right-0 flex-shrink-0 px-4 backdrop-blur-md backdrop-opacity-80 bg-white/0">
          <div className="flex flex-col max-w-3xl mx-auto mb-safe">
            <Button
              fill="solid"
              color="tertiary"
              textColor="white"
              className="w-full shadow-xl"
              size="large"
              label={buttonLabel}
              onClick={handleSubmit}
            />

            <Button
              fill="clear"
              textColor="white"
              className="w-full"
              labelClassName="no-underline"
              label={TEXT.TIER_1.NO_THANKS}
              onClick={handleClose}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export const GetPremiumModal: React.FC<any> = ({ ...props }) => {
  return (
    <Modal
      name={ModalOrchestrationName.GetPremium}
      background="primary"
      fullScreen
      {...props}
    >
      <Modal.Body>
        {(props: any) => <GetPremiumModalBody {...props} />}
      </Modal.Body>
    </Modal>
  )
}

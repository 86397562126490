import { useIonAlert } from "@ionic/react"
import clsx from "clsx"
import { isNil } from "lodash"
import { useTranslation } from "react-i18next"
import {
  RiAddLine,
  RiStarFill,
  RiStarLine,
  RiSubtractLine,
} from "react-icons/ri"
import { MovementIcon } from "../Core/Icons"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import {
  MovementStyle,
  Weekday,
  MovementActivityInput,
} from "../../generated/graphql"
import { getWeekdayInitial } from "../../labels"
import { NAME_SPACES, WELCOME } from "../../locales/constants"
import i18n from "../../locales/i18n"
import { weekdayOrder } from "../../utils"
import { formatStyle, formatWeekday } from "../../utils/format"
import {
  getModalityContrastColor,
  getModalityTextColor,
} from "../../utils/movementUtils"
import useActivityStyle from "../../hooks/useActivityStyle"
import Button from "../Forms/Button"

export const FavoriteActivityCard = ({
  style,
  config,
  setFrequency,
  isPrimary,
  setPrimary,
  setWeekdays,
  onClear,
}: {
  style: MovementStyle
  config: MovementActivityInput
  isPrimary: boolean
  setPrimary: (style: MovementStyle | null) => void
  setFrequency: (style: MovementStyle, frequency: number) => void
  onClear: (style: MovementStyle) => void
  setWeekdays?: (style: MovementStyle, weekdays: Weekday[]) => void
}) => {
  const [showAlert] = useIonAlert()

  const { t } = useTranslation(NAME_SPACES.WELCOME)
  const TEXT = t(WELCOME.ACTIVITIES, { returnObjects: true })

  const { captureEvent } = useAnalyticsContext()

  const { usualWeeklyFrequency: frequency, usualWeekdays: weekdays } = config

  const activityStyle = useActivityStyle(style)
  const activityModality = activityStyle?.modality

  const element = {
    movementStyle: style,
    movementModality: activityStyle?.modality,
  }

  const handleClear = () => {
    showAlert({
      message: TEXT.BUTTONS.CLEAR.CONFIRMATION,
      buttons: [
        {
          text: i18n.t("FORMS.CANCEL"),
          role: "cancel",
        },
        {
          text: i18n.t("FORMS.OKAY"),
          handler: () => {
            captureEvent(
              AnalyticsEvent.WelcomeFavoriteActivitiesActivityCleared,
              {
                style,
              }
            )

            onClear(style)
          },
        },
      ],
    })
  }

  const handleSelectWeekday = (weekday: Weekday) => {
    captureEvent(
      AnalyticsEvent.WelcomeFavoriteActivitiesActivityWeekdayToggled,
      {
        style,
        weekday,
      }
    )

    if (isNil(weekdays)) {
      return setWeekdays?.(style, [weekday])
    }

    if (weekdays.includes(weekday)) {
      setWeekdays?.(
        style,
        weekdays.filter((w) => w !== weekday)
      )
    } else {
      setWeekdays?.(style, [...weekdays, weekday])
    }
  }

  const handleIncrement = () => {
    captureEvent(
      AnalyticsEvent.WelcomeFavoriteActivitiesActivityFrequencyIncreased,
      {
        style,
        frequency: frequency + 1,
      }
    )

    if (frequency < 10) {
      setFrequency(style, frequency + 1)
    }
  }

  const handleDecrement = () => {
    captureEvent(
      AnalyticsEvent.WelcomeFavoriteActivitiesActivityFrequencyDecreased,
      {
        style,
        frequency: frequency - 1,
      }
    )

    if (frequency <= 0) {
      return handleClear()
    } else {
      setFrequency(style, frequency - 1)
    }
  }

  const handleSetPrimary = () => {
    if (isPrimary) {
      setPrimary(null)

      return
    } else {
      captureEvent(
        AnalyticsEvent.WelcomeFavoriteActivitiesActivityPrimaryToggled,
        { style }
      )

      setPrimary(style)
    }
  }

  if (isNil(frequency) || frequency < 0) {
    return null
  }

  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-center w-full gap-y-1",
        "rounded-md shadow-sm",
        `bg-${activityModality} border-${activityModality}-700 border`,
        getModalityTextColor(activityModality)
      )}
    >
      <div className="flex flex-row items-center justify-between w-full px-3 pt-2 gap-y-2">
        <div className="flex flex-row items-center justify-end h-full gap-2">
          <MovementIcon element={element} className="text-2xl" />
          <span className="text-xl font-medium">{formatStyle(style)}</span>
        </div>

        <div className="flex flex-col items-end gap-2">
          <Button
            icon={isPrimary ? RiStarFill : RiStarLine}
            fill="clear"
            color={getModalityContrastColor(activityModality)}
            className={clsx("w-10 h-10 -px-1 -py-1")}
            onClick={handleSetPrimary}
            paddingWidth="thin"
          />
        </div>
      </div>

      <div className="flex flex-row items-center justify-start w-full gap-1 px-3 pb-2">
        <div
          className={clsx(
            "inline-flex rounded-md shadow-sm isolate",
            "bg-white/20",
            getModalityTextColor(activityModality)
          )}
        >
          <button
            type="button"
            className={`relative inline-flex items-center px-2 py-2 ring-1 ring-${activityModality}-700 ring-inset rounded-l-md focus:z-10`}
            onClick={handleDecrement}
          >
            <>
              <span className="sr-only">Decrement</span>

              <RiSubtractLine className="w-5 h-5" aria-hidden="true" />
            </>
          </button>
          <button
            type="button"
            className={`relative flex w-8 flex-shrink-0 items-center px-1 py-2 -ml-px text-sm font-semibold ring-1 ring-${activityModality}-700 ring-inset focus:z-10 `}
          >
            <span className="w-full text-center">{frequency || "< 1"}</span>
          </button>
          <button
            type="button"
            className={`relative inline-flex items-center px-2 py-2 -ml-px ring-1 ring-${activityModality}-700 ring-inset rounded-r-md focus:z-10 disabled:opacity-50`}
            disabled={frequency >= 10}
            onClick={handleIncrement}
          >
            <span className="sr-only">Increment</span>
            <RiAddLine className="w-5 h-5" aria-hidden="true" />
          </button>
        </div>

        <span className="text-xs font-semibold">{TEXT.HINTS.FREQUENCY}</span>
      </div>

      {/* Hidden - set usual weekdays */}
      <div
        className={clsx(
          "flex flex-col items-start justify-center w-full gap-y-2",
          "rounded-b-md bg-opacity-20",
          "p-4",
          `bg-${getModalityContrastColor(activityModality)}`,
          "hidden"
        )}
      >
        <span className="text-sm">
          {t("ACTIVITIES.HINTS.WEEKDAYS", {
            weekdays: formatWeekday(weekdays),
          })}
        </span>
        <div
          className={clsx(
            "flex flex-row items-center justify-between w-full py-3 gap-x-1 max-w-lg mx-auto"
          )}
        >
          {weekdayOrder.map((weekday) => (
            <div
              key={weekday}
              className="flex flex-col items-center justify-center"
            >
              <button
                className={clsx(
                  // isToday ? "bg-white" : "bg-primary",
                  // hasCompletedSession &&
                  //   `border-2 border-${firstSession?.movementModality}`,
                  // isSelected && "ring-white/20 ring-4",
                  weekdays?.includes(weekday) &&
                    `bg-${getModalityContrastColor(
                      activityModality
                    )} text-${activityModality}`,
                  `ring-1 ring-${getModalityContrastColor(
                    activityModality
                  )} ring-inset`,
                  "-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none aspect-square"
                )}
                onClick={() => handleSelectWeekday(weekday)}
              >
                <span className="sr-only">{formatWeekday(weekday)}</span>

                <span
                  aria-hidden="true"
                  className={clsx(
                    "h-6 w-6 rounded-full flex items-center justify-center font-medium"
                  )}
                >
                  {getWeekdayInitial(weekday)}
                </span>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

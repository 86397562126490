import { useTranslation } from "react-i18next"

import Page from "../../components/Core/Page"
import { MICO, NAME_SPACES } from "../../locales/constants"
import TabBar, { TabBarElement } from "../../components/Core/TabBar"
import { useEffect, useState } from "react"
import { SettingsModal } from "../../components/Settings/SettingsModal"
import { IoCompassOutline, IoLibrary } from "react-icons/io5"
import MicoAdventureGalleryTab from "../../components/Mico/MicoAdventureGalleryTab"
import useRouterQueryParams from "../../hooks/useRouterQueryParams"
import MicoVideoGalleryTab from "../../components/Mico/MicoVideoGalleryTab"
import { SportIcon } from "../../components/Core/Icons"

const MicoHomePage: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.MICO)
  const TEXT = t(MICO.HOME, { returnObjects: true })

  const query = useRouterQueryParams()

  const micoTabs = [
    {
      name: "ADVENTURES",
      label: TEXT.TABS.ADVENTURES,
      icon: IoCompassOutline,
    },
    {
      name: "MOVES",
      label: TEXT.TABS.MOVES,
      icon: SportIcon,
      hidden: true,
    },
    {
      name: "VIDEOS",
      label: TEXT.TABS.VIDEOS,
      icon: IoLibrary,
      pathname: "/app/mico/videos",
    },
  ] as TabBarElement[]

  const [activeTab, setActiveTab] =
    useState<TabBarElement["name"]>("ADVENTURES")

  const handleTabChange = (tab: TabBarElement["name"]) => {
    if (query.has("tab")) {
      query.delete("tab")
    }

    query.set("tab", tab)
    setActiveTab(tab)
  }

  useEffect(() => {
    if (query.has("tab")) {
      const tab = query.get("tab")

      if (tab && micoTabs.find((t) => t.name === tab)) {
        setActiveTab(tab as TabBarElement["name"])
      }
    }
  }, [query])

  return (
    <Page className="bg-neutral-100">
      <Page.Header title={TEXT.TITLE} color="primary">
        <TabBar
          tabs={micoTabs}
          activeTab={activeTab}
          onTabChange={handleTabChange}
          className="overflow-x-scroll overflow-y-hidden shadow-lg"
        />
      </Page.Header>

      {activeTab === "ADVENTURES" && <MicoAdventureGalleryTab />}
      {/* {activeTab === "MOVES" && <MicoMovesGalleryTab />} */}
      {activeTab === "VIDEOS" && <MicoVideoGalleryTab />}

      <SettingsModal />
    </Page>
  )
}

export default MicoHomePage

import clsx from "clsx"
import { IconType } from "react-icons/lib"
import { SqualoAdventureElement } from "../../generated/graphql"
import { ButtonIcon } from "../Forms/Button"

const buildTagColorClasses = (color: string) => {
  switch (color) {
    case "primary":
      return "bg-primary text-white border-primary-600 border"
    case "neutral":
      return "bg-neutral-100 text-neutral-600 border border-neutral-300"
    case "white":
      return "bg-white text-neutral-600 border border-neutral-400"

    case SqualoAdventureElement.Air:
      return "bg-gradient-to-r from-AIR-400 to-AIR-500 border-neutral-300 border text-neutral-700"

    case SqualoAdventureElement.Earth:
      return "bg-gradient-to-r from-EARTH-400 to-EARTH-500 border-neutral-300 border text-white"

    case SqualoAdventureElement.Fire:
      return "bg-gradient-to-r from-FIRE-400 to-FIRE-500 border-neutral-300 border text-white"

    case SqualoAdventureElement.Water:
      return "bg-gradient-to-r from-WATER-400 to-WATER-500 border-neutral-300 border text-white"

    case "transparent":
      return "bg-transparent text-white border border-white"

    default:
      return `bg-${color}-400 text-white border-${color} border`
  }
}

export const Tag: React.FC<{
  icon?: string | IconType
  iconSlot?: "start" | "end" | "icon-only"
  iconProps?: any
  iconClassName?: string
  className?: string
  width?: "auto" | number
  color?: string
  opacity?: number
  size?: "small" | "medium" | "large"
}> = ({
  color = "white",
  opacity = 100,
  className,
  children,
  icon,
  iconSlot = "start",
  iconProps,
  iconClassName,
  size = "medium",
  width = "auto",
}) => (
  <div
    className={clsx(
      buildTagColorClasses(color),
      `opacity-${opacity}`,
      "flex flex-row items-center py-1 font-semibold rounded-2xl gap-x-1 backdrop-blur leading-3",
      size === "small" ? "text-sm h-6 px-1" : "h-8 px-3",
      width === "auto" ? "w-auto" : `w-${width}`,
      className
    )}
  >
    {icon && iconSlot === "start" && (
      <ButtonIcon
        icon={icon}
        iconSlot={iconSlot}
        iconProps={iconProps}
        iconClassName={clsx(
          iconClassName,
          "min-w-0 min-h-0",
          size === "small" && "w-5 h-5",
          size === "medium" && "w-6 h-6",
          size === "large" && "w-8 h-8"
        )}
      />
    )}
    {/* {!icon ? (
      <div
        className={clsx(
          "rounded-full aspect-square p-1 h-auto",
          buildDotColorClasses(color),
          size === "small" && "w-3",
          size === "medium" && "w-4 -ml-1 -mr-1",
          size === "large" && "w-5 -ml-2 mr-2"
        )}
      ></div>
    ) : null} */}
    {typeof children === "string" ? (
      <span className="inline-block w-full whitespace-nowrap">{children}</span>
    ) : (
      children
    )}
    {icon && iconSlot === "end" && (
      <ButtonIcon
        icon={icon}
        iconSlot={iconSlot}
        iconProps={iconProps}
        iconClassName={clsx(iconClassName, "w-6 h-6")}
      />
    )}
  </div>
)

export const TagButton: React.FC<{
  startIcon?: string | IconType
  endIcon?: string | IconType
  className?: string
  iconClassName?: string
  color?: string
  opacity?: number
  onClick?: () => void
}> = ({
  startIcon,
  endIcon,
  opacity = 100,
  className,
  iconClassName,
  children,
  onClick,
}) => (
  <button
    onClick={onClick}
    className={clsx(
      // "text-white ring-white ring-2",
      "shadow-lg",
      `opacity-${opacity}`,
      "flex flex-row items-center justify-between py-1 font-semibold rounded-2xl gap-x-1 leading-3 h-8 px-3",
      className
    )}
  >
    <div className="flex flex-row items-center gap-x-1">
      {startIcon && (
        <ButtonIcon icon={startIcon} iconClassName={clsx(iconClassName)} />
      )}
      {typeof children === "string" ? (
        <span className="w-full whitespace-nowrap">{children}</span>
      ) : (
        children
      )}
    </div>

    {endIcon && (
      <ButtonIcon icon={endIcon} iconClassName={clsx(iconClassName)} />
    )}
  </button>
)

export const TagRow: React.FC<{ className?: string }> = ({
  className,
  children,
}) => (
  <div
    className={clsx(
      "flex flex-row gap-x-2 h-full w-full overflow-x-scroll items-center",
      className
    )}
  >
    {children}
  </div>
)

import clsx from "clsx"
import { IoCaretBack, IoCaretForward } from "react-icons/io5"
import { ControlProps } from "nuka-carousel"

export const CarouselPagination: React.FC<ControlProps> = ({
  currentSlide,
  pagingDotsIndices,
  previousSlide,
  nextSlide,
  previousDisabled,
  nextDisabled,
  goToSlide,
  slideCount,
}) => {
  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-row items-center justify-center w-full h-8">
        {!previousDisabled && (
          <IoCaretBack
            className="w-6 h-6 text-neutral-300"
            onClick={previousSlide}
          />
        )}
        {pagingDotsIndices?.map((index) => (
          <div
            key={index}
            className={clsx(
              "w-4 h-4 rounded-full inline-flex justify-self-center mx-1",
              currentSlide === index ? "bg-primary" : "bg-neutral-300",
              Math.abs(index - currentSlide) >= 2 && "hidden",
              "flex-shrink-0"
            )}
            onClick={() => goToSlide(index)}
          />
        ))}
        {!nextDisabled && (
          <IoCaretForward
            className="w-6 h-6 text-neutral-300"
            onClick={nextSlide}
          />
        )}
      </div>
      <span className="text-sm font-semilight text-neutral-400">
        {currentSlide + 1} / {slideCount}
      </span>
    </div>
  )
}

export interface DiscreteCarouselPaginationProps extends ControlProps {
  selectedSlideIndex?: number
}

export const DiscreteCarouselPagination: React.FC<
  DiscreteCarouselPaginationProps
> = ({ currentSlide, pagingDotsIndices, goToSlide }) => {
  const getSize = (index: number) => {
    const diff = Math.abs(index - currentSlide)

    if (diff === 0) {
      return "w-2 h-2"
    } else if (diff === 1) {
      return "w-1.5 h-1.5"
    } else {
      return "w-1 h-1"
    }
  }

  if (!pagingDotsIndices) return null

  if (pagingDotsIndices.length === 1) return null

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-row items-center justify-center w-full h-8">
        {pagingDotsIndices?.map((index) => (
          <div
            key={index}
            className={clsx(
              "rounded-full inline-flex justify-self-center mx-1",
              currentSlide === index ? "bg-primary" : "bg-neutral-300",
              Math.abs(currentSlide - index) >= 4 && "hidden",
              getSize(index),
              "flex-shrink-0"
            )}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </div>
  )
}

import clsx from "clsx"
import { addWeeks, isAfter, isBefore, isSameDay } from "date-fns"
import addDays from "date-fns/addDays"
import { isNil } from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { IoCalendarClearOutline } from "react-icons/io5"
import { RiArrowLeftCircleFill, RiArrowRightCircleFill } from "react-icons/ri"

import { useAgendaSchedulingContext } from "../../../../contexts/AgendaSchedulingContext"
import { useLocaleContext } from "../../../../contexts/LocaleContext"
import { ModalOrchestrationName } from "../../../../contexts/ModalOrchestrationContext"
import { Weekday } from "../../../../generated/graphql"
import { MOVEMENT, NAME_SPACES } from "../../../../locales/constants"
import { getWeekdayDate, weekdayOrder } from "../../../../utils"
import { formatWeekday } from "../../../../utils/format"
import Button from "../../../Forms/Button"
import CardSelect, { CardSelectProps } from "../../../Forms/CardSelect"
import {
  GridFormField,
  GridFormFieldProps,
  RequiredFieldModalBodyProps,
} from "../../../Forms/GridFormField"
import Toggle from "../../../Forms/Toggle"

export interface SchedulePickerState {
  isRecurring?: boolean
  weekStartDate?: Date
  weekday?: Weekday
}

export interface SchedulePickerProps
  extends CardSelectProps<SchedulePickerState>,
    RequiredFieldModalBodyProps<SchedulePickerState> {
  hideRecurringToggle?: boolean
  onlyPast?: boolean
}

const SchedulePicker: React.FC<SchedulePickerProps> = ({
  onlyPast = false,
  hideRecurringToggle = false,
  state,
  setState,
}) => {
  const { formatDate, formatRelativeDate } = useLocaleContext()
  const { today, selectedWeekStartDate } = useAgendaSchedulingContext()

  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const { SCHEDULE } = t(MOVEMENT.FORM, { returnObjects: true })

  const [weekStartDate, setWeekStartDate] = useState<Date>(
    state.weekStartDate || selectedWeekStartDate
  )

  const nextWeek = () => {
    setWeekStartDate((date) => addWeeks(date, 1))
  }

  const previousWeek = () => {
    setWeekStartDate((date) => addWeeks(date, -1))
  }

  const isSelectedWeekInPast = useMemo(() => {
    const previousWeekEndDate = addDays(weekStartDate as Date, -1)

    return isBefore(previousWeekEndDate, today.date)
  }, [weekStartDate])

  const isSelectedWeekInFuture = useMemo(() => {
    const nextWeekStartDate = addWeeks(weekStartDate as Date, 1)

    return isAfter(nextWeekStartDate, today.date)
  }, [weekStartDate])

  const options = useMemo(
    () =>
      weekdayOrder.map((weekday) => {
        const weekdayDate = getWeekdayDate(weekStartDate as Date, weekday)

        const isAvailable =
          isSameDay(weekdayDate, today.date) ||
          (onlyPast
            ? isBefore(weekdayDate, today.date)
            : isAfter(weekdayDate, today.date))

        return {
          value: weekday,
          label: state.isRecurring
            ? formatWeekday(weekday, true)
            : formatRelativeDate(weekdayDate, today.date),
          disabled: !state?.isRecurring && !isAvailable,
          information: state?.isRecurring
            ? undefined
            : formatDate(weekdayDate, "MMMM dd"),
        }
      }),
    [weekStartDate, state.isRecurring]
  )

  useEffect(() => {
    setState({ ...state, weekStartDate })
  }, [weekStartDate])

  const handleSelect = useCallback(
    (weekday: Weekday) => {
      setState({ ...state, weekStartDate, weekday })
    },
    [state, weekStartDate]
  )

  return (
    <div className="flex flex-col w-full h-full">
      {!onlyPast && !hideRecurringToggle && (
        <Toggle
          isEnabled={state?.isRecurring}
          label={SCHEDULE.EVERY_WEEK}
          className={"p-4"}
          onToggle={(isRecurring) =>
            setState({ weekday: state?.weekday, isRecurring })
          }
        />
      )}

      <div
        className={clsx(
          "overflow-y-scroll",
          "p-2 shadow-inner bg-neutral-200/40 rounded-lg"
        )}
      >
        <CardSelect
          options={options}
          onSelect={handleSelect}
          selected={state?.weekday}
          sort={false}
        />

        {!state.isRecurring && (
          <div className="sticky bottom-0 grid w-full grid-cols-2">
            <Button
              color="neutral"
              className="text-neutral-500"
              fill="clear"
              iconClassName="h-10 w-10"
              disabled={!onlyPast && isSelectedWeekInPast}
              onClick={() => previousWeek()}
              icon={RiArrowLeftCircleFill}
            />

            <Button
              color="neutral"
              className="text-neutral-500"
              iconClassName="h-10 w-10"
              fill="clear"
              disabled={onlyPast && isSelectedWeekInFuture}
              onClick={() => nextWeek()}
              icon={RiArrowRightCircleFill}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export interface ScheduleGridFormFieldProps
  extends Omit<
    GridFormFieldProps<SchedulePickerState>,
    "Body" | "label" | "modalName"
  > {
  name: string
  onlyPast?: boolean
  hideRecurringToggle?: boolean
}

export const ScheduleGridFormField: React.FC<ScheduleGridFormFieldProps> = ({
  name,
  onlyPast = false,
  hideRecurringToggle,
  ...props
}) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)

  const { formatRelativeDate } = useLocaleContext()
  const { selectedWeekStartDate } = useAgendaSchedulingContext()

  const { SCHEDULE } = t(MOVEMENT.FORM, {
    returnObjects: true,
  })

  const { TITLE } = onlyPast ? SCHEDULE.PAST : SCHEDULE.FUTURE

  const SmartSchedulePicker = (props: SchedulePickerProps) => (
    <SchedulePicker
      {...props}
      onlyPast={onlyPast}
      hideRecurringToggle={hideRecurringToggle}
    />
  )

  const formatScheduleState = (state?: SchedulePickerState) => {
    if (isNil(state)) {
      return SCHEDULE.ANY_DAY
    }

    const { weekday, isRecurring, weekStartDate } = state

    if (!weekday) {
      return SCHEDULE.ANY_DAY
    }

    const date = getWeekdayDate(weekStartDate || selectedWeekStartDate, weekday)

    const value = isRecurring
      ? formatWeekday(weekday, true)
      : formatRelativeDate(date, new Date())

    return value
  }

  return (
    <GridFormField<SchedulePickerState>
      name={name}
      modalName={ModalOrchestrationName.FormFieldSchedulePicker}
      label={SCHEDULE.LABEL}
      title={TITLE}
      Body={SmartSchedulePicker}
      Icon={IoCalendarClearOutline}
      formatValue={formatScheduleState}
      showDismiss
      {...props}
    />
  )
}

export default SchedulePicker

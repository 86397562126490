import { Weekday } from "../../generated/graphql"
import { CALENDAR } from "../constants"

export default {
  [CALENDAR.RELATIVE]: {
    DATE: {
      lastWeek: "'Last' eeee",
      yesterday: "'Yesterday'",
      today: "'Today'",
      tomorrow: "'Tomorrow'",
      nextWeek: "EEEE",
      other: "EEEE, dd/MM",
    },
    DATETIME: {
      lastWeek: "'Last' EEE 'at' p",
      yesterday: "'Yesterday at' p",
      today: "'Today at' p",
      tomorrow: "'Tmrw. at' p",
      nextWeek: "EEE 'at' p",
      other: "EEE 'at' p",
    },
  },
  [CALENDAR.WEEKDAYS]: {
    [Weekday.Monday]: "Monday",
    [Weekday.Tuesday]: "Tuesday",
    [Weekday.Wednesday]: "Wednesday",
    [Weekday.Thursday]: "Thursday",
    [Weekday.Friday]: "Friday",
    [Weekday.Saturday]: "Saturday",
    [Weekday.Sunday]: "Sunday",
  },
}

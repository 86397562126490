import { useIonActionSheet } from "@ionic/react"
import classNames from "clsx"
import isBefore from "date-fns/isBefore"
import isSameDay from "date-fns/isSameDay"
import { chevronBack, chevronForward } from "ionicons/icons"
import { cloneDeep, isNil } from "lodash"
import React, { useEffect, useState } from "react"
import {
  DragDropContext,
  DragStart,
  DragUpdate,
  DropResult,
  Droppable,
} from "react-beautiful-dnd"
import { useTranslation } from "react-i18next"

import {
  AgendaDay,
  AgendaDaySchedule,
  AgendaWeekSchedule,
  useAgendaSchedulingContext,
} from "../../../contexts/AgendaSchedulingContext"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../../contexts/AnalyticsContext"
import {
  MovementAgendaItemSummaryFragment,
  Weekday,
} from "../../../generated/graphql"
import useToast from "../../../hooks/useToast"
import { MOVEMENT, NAME_SPACES } from "../../../locales/constants"
import { weekdayOrder } from "../../../utils"
import Button from "../../Forms/Button"
import { AgendaTimelineDay } from "./AgendaTimelineDay"
import { Haptics } from "@capacitor/haptics"
import { AgendaSuggestionInbox } from "./AgendaSuggestionInbox"

// Virtuoso's resize observer can this error,
// which is caught by DnD and aborts dragging.
window.addEventListener("error", (e) => {
  if (
    e.message ===
      "ResizeObserver loop completed with undelivered notifications." ||
    e.message === "ResizeObserver loop limit exceeded"
  ) {
    e.stopImmediatePropagation()
  }
})

export const findDayScheduleFromIndex = (
  schedule: AgendaWeekSchedule,
  index: number,
  _draggingDirection = undefined as "up" | "down" | undefined
) => {
  let curOffset = 0

  for (let day = 0; day < schedule.length - 1; day++) {
    const nextOffset = schedule[day + 1].weekScheduleOffset

    if (curOffset <= index && index < nextOffset) {
      return schedule[day]
    }

    curOffset = nextOffset
  }

  if (
    schedule.length > 0 &&
    index <= curOffset + schedule[schedule.length - 1].items.length + 2
  ) {
    return schedule[schedule.length - 1]
  }

  return null
}

export function reorder(items: any[], startIndex: number, endIndex: number) {
  const result = Array.from(items)

  const [removed] = result.splice(startIndex, 1)
  result.splice(Math.max(0, endIndex), 0, removed)

  return result
}

type AgendaTimelineProps = {
  showSuggestionInbox: boolean
  addScheduleElement: (day: AgendaDay) => void
  changeItemWeekday: (
    item: MovementAgendaItemSummaryFragment,
    weekday: Weekday,
    onlyThisWeek: boolean
  ) => Promise<void>
}

const AgendaTimeline: React.FC<AgendaTimelineProps> = ({
  changeItemWeekday,
  addScheduleElement,
  showSuggestionInbox,
}) => {
  const { showInfo } = useToast()
  const [present] = useIonActionSheet()

  const {
    today,
    selectedWeekSchedule: schedule,
    setSelectedWeekSchedule: setSchedule,
    selectPreviousWeek,
    selectNextWeek,
    suggestionInboxItems,
  } = useAgendaSchedulingContext()

  const { captureEvent } = useAnalyticsContext()

  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.AGENDA_HOME, {
    returnObjects: true,
  })

  const [changingSchedule, setChangingSchedule] = useState<boolean>(false)
  const [shouldRevertChange, setShouldRevertChange] = useState<boolean>(false)
  const [scheduleSnapshot, setScheduleSnapshot] = useState<AgendaWeekSchedule>(
    cloneDeep(schedule)
  )

  const changeRecurringItemDay = async (
    item: MovementAgendaItemSummaryFragment,
    day: AgendaDay
  ) => {
    await changeItemWeekday(item, day.weekday, false)
  }

  const maybeChangeItemOccurrenceDay = async (
    item: MovementAgendaItemSummaryFragment,
    day: AgendaDay
  ) => {
    if (!isSameDay(day.date, today.date) && isBefore(day.date, today.date)) {
      showInfo(TEXT.WARNING_PLAN_SESSION_IN_PAST)
      setShouldRevertChange(true)

      return false
    }

    changeItemWeekday(item, day.weekday, true)

    return true
  }

  const saveSchedule = (newSchedule: AgendaWeekSchedule) => {
    if (!shouldRevertChange) {
      setChangingSchedule(false)
      setSchedule(newSchedule)
    }
  }

  const snapshotSchedule = () => {
    setScheduleSnapshot(schedule)
    setChangingSchedule(true)

    return cloneDeep(schedule)
  }

  const handleChangeItemDay = async (
    item: MovementAgendaItemSummaryFragment,
    day: AgendaDay
  ) => {
    if (item.isRecurring) {
      await present({
        header: t("ITEM_PLANNING.RECURRING_ITEM_MESSAGE"),
        buttons: [
          {
            text: t("ITEM_PLANNING.MODIFY_ITEM_OCCURRENCE_MESSAGE"),
            handler: async () => {
              await maybeChangeItemOccurrenceDay(item, day)
            },
          },
          {
            text: t("ITEM_PLANNING.MODIFY_RECURRING_ITEM_MESSAGE"),
            handler: async () => {
              await changeRecurringItemDay(item, day)
            },
          },
          {
            text: t("ITEM_PLANNING.CANCEL"),
            role: "cancel",
            handler: async () => {
              setShouldRevertChange(true)
            },
          },
        ],
      })
    } else {
      await maybeChangeItemOccurrenceDay(item, day)
    }
  }

  const reorderSameDayItems = (
    daySchedule: AgendaDaySchedule,
    sourceIndex: number,
    destinationIndex: number
  ) => {
    if (isNil(daySchedule)) {
      return
    }

    const newSchedule = cloneDeep(schedule)

    const newItems = reorder(
      newSchedule[daySchedule.index].items,
      sourceIndex,
      destinationIndex
    )

    newSchedule[daySchedule.index].items = newItems

    saveSchedule(newSchedule)
  }

  const addItemFromSuggestionInbox = async (
    suggestion: MovementAgendaItemSummaryFragment,
    destDaySchedule: AgendaDaySchedule,
    destDayIndex: number
  ) => {
    const newSchedule = snapshotSchedule()

    const destClone = [...destDaySchedule.items]

    const shouldContinue = await maybeChangeItemOccurrenceDay(
      suggestion,
      destDaySchedule.day
    )

    if (!shouldContinue) {
      return
    }

    destClone.splice(destDayIndex, 0, suggestion)
    newSchedule[destDaySchedule.index].items = destClone

    saveSchedule(newSchedule)
  }

  const addItemToRestDay = async (
    sourceDaySchedule: AgendaDaySchedule,
    destDaySchedule: AgendaDaySchedule,
    sourceIndex: number
  ) => {
    if (destDaySchedule.items.length > 0) {
      console.warn("Trying to add item to non-empty list")
      return
    }

    const newSchedule = snapshotSchedule()

    const [item] = newSchedule[sourceDaySchedule.index].items.splice(
      sourceIndex - sourceDaySchedule.weekScheduleOffset - 1,
      1
    )

    await handleChangeItemDay(item, destDaySchedule.day)

    newSchedule[destDaySchedule.index].items = [item]

    saveSchedule(newSchedule)
  }

  const moveItemToNewDay = async (
    sourceDaySchedule: AgendaDaySchedule,
    destDaySchedule: AgendaDaySchedule,
    sourceDayIndex: number,
    destDayIndex: number
  ) => {
    const sourceClone = [...sourceDaySchedule.items]
    const destClone = [...destDaySchedule.items]

    const [item] = sourceClone.splice(sourceDayIndex, 1)

    const newSchedule = snapshotSchedule()

    await handleChangeItemDay(item, destDaySchedule.day)

    destClone.splice(destDayIndex, 0, item)

    newSchedule[sourceDaySchedule.index].items = sourceClone
    newSchedule[destDaySchedule.index].items = destClone

    saveSchedule(newSchedule)
  }

  const handleDragEndInAgenda = React.useCallback(
    async (result: DropResult) => {
      if (!result.destination) return

      const sourceDaySchedule = findDayScheduleFromIndex(
        schedule,
        result.source.index
      )

      if (!sourceDaySchedule) {
        return
      }

      const sourceDayItemIndex =
        result.source.index - sourceDaySchedule.weekScheduleOffset - 1

      const draggingDirection =
        result.source.index < result.destination.index ? "down" : "up"

      const destDaySchedule = findDayScheduleFromIndex(
        schedule,
        result.destination.index,
        draggingDirection
      )

      if (isNil(destDaySchedule)) {
        return
      }

      const destDayItemIndex =
        result.destination.index - destDaySchedule.weekScheduleOffset - 1

      if (destDaySchedule.items.length === 0) {
        captureEvent(AnalyticsEvent.AgendaItemAddedToRestDay, {
          day: sourceDaySchedule.day.weekday,
        })

        addItemToRestDay(
          sourceDaySchedule,
          destDaySchedule,
          result.source.index
        )
      } else if (
        sourceDaySchedule.day.weekday === destDaySchedule.day.weekday
      ) {
        captureEvent(AnalyticsEvent.AgendaItemReorderedInDay, {
          day: sourceDaySchedule.day.weekday,
          fromIndex: sourceDayItemIndex,
          toIndex: destDayItemIndex,
        })

        reorderSameDayItems(
          destDaySchedule,
          sourceDayItemIndex,
          destDayItemIndex
        )
      } else {
        captureEvent(AnalyticsEvent.AgendaItemMovedToAnotherDay, {
          sourceDay: sourceDaySchedule.day.weekday,
          destDay: destDaySchedule.day.weekday,
        })

        moveItemToNewDay(
          sourceDaySchedule,
          destDaySchedule,
          sourceDayItemIndex,
          Math.max(destDayItemIndex, 0)
        )
      }
    },
    [schedule, setSchedule, captureEvent]
  )

  const handleDragFromInbox = React.useCallback(
    async (result: DropResult) => {
      const inboxItem = suggestionInboxItems[result.source.index]

      if (!inboxItem) return

      if (!result.destination) return

      if (result.destination.droppableId === "inbox") return

      const destDaySchedule = findDayScheduleFromIndex(
        schedule,
        result.destination.index
      )

      if (isNil(destDaySchedule)) return

      captureEvent(AnalyticsEvent.AgendaSuggestionAddedFromInbox, {})

      const destDayItemIndex = isNil(destDaySchedule)
        ? 0
        : result.destination.index - destDaySchedule.weekScheduleOffset - 1

      await addItemFromSuggestionInbox(
        inboxItem,
        destDaySchedule,
        destDayItemIndex
      )
    },
    [suggestionInboxItems, schedule, setSchedule, captureEvent]
  )

  const onDragEnd = React.useCallback(
    async (result) => {
      await Haptics.selectionEnd().catch(() => {
        console.warn("Haptics not supported")
      })

      if (
        isNil(result.destination) ||
        result.source.index === result.destination?.index
      ) {
        return
      }

      if (result.source.droppableId === "inbox") {
        await handleDragFromInbox(result)
      } else {
        await handleDragEndInAgenda(result)
      }
    },
    [schedule, setSchedule]
  )

  const onDragUpdate = async (_initial: DragUpdate) => {
    await Haptics.selectionChanged().catch(() => {
      console.warn("Haptics not supported")
    })
  }

  const onDragStart = async (_initial: DragStart) => {
    await Haptics.selectionStart().catch(() => {
      console.warn("Haptics not supported")
    })
  }

  useEffect(() => {
    if (shouldRevertChange && !changingSchedule) {
      setSchedule(scheduleSnapshot)

      setShouldRevertChange(false)
    }
  }, [changingSchedule, shouldRevertChange, scheduleSnapshot])

  return (
    <div className="relative w-full h-full text-neutral">
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onDragUpdate={onDragUpdate}
      >
        <AgendaSuggestionInbox hidden={!showSuggestionInbox} />
        <Droppable droppableId="droppable" direction="vertical">
          {(provided) => {
            return (
              <>
                <div
                  className={classNames(
                    "overflow-y-auto ion-content-scroll-host divide-y divide-neutral/30",
                    showSuggestionInbox ? "h-3/4 pb-52" : "h-full"
                  )}
                  ref={provided.innerRef}
                >
                  <div className="p-4">
                    <Button
                      label={TEXT.SEE_PREVIOUS_WEEK}
                      icon={chevronBack}
                      iconSlot="start"
                      onClick={selectPreviousWeek}
                      expand="block"
                      fill="outline"
                    />
                  </div>

                  {schedule &&
                    schedule.map((dailySchedule, index) => (
                      <AgendaTimelineDay
                        key={index}
                        weekday={weekdayOrder[index]}
                        weekdaySchedule={dailySchedule}
                        handleAddClick={addScheduleElement}
                      />
                    ))}

                  <div className="p-4">
                    <Button
                      label={TEXT.SEE_NEXT_WEEK}
                      icon={chevronForward}
                      iconSlot="end"
                      onClick={selectNextWeek}
                      expand="block"
                      fill="outline"
                    />
                  </div>
                  {provided.placeholder}
                </div>
              </>
            )
          }}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default AgendaTimeline

import { useTranslation } from "react-i18next"

import Content from "../../components/Core/Content"

import Page from "../../components/Core/Page"
import { COMMON, NAME_SPACES } from "../../locales/constants"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import { OlibaPreview } from "../../components/Support/OlibaPreview"
import { ButtonProps } from "../../components/Forms/Button"
import clsx from "clsx"
import { DailyMoveSection } from "../../components/Today/DailyMove"
import { useLocaleContext } from "../../contexts/LocaleContext"

import { GetPremiumButton } from "../../components/Premium/GetPremiumButton"
import { MovementWizard } from "../../components/Movement/Wizard/MovementWizard"
import { RecommendedAdventureCarousel } from "../../components/Today/RecommendedAdventureCarousel"
import { StaminaMeter } from "../../components/Today/StaminaMeter"

const TodayHomePage: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.COMMON)

  const { today } = useAgendaSchedulingContext()
  const { formatDate } = useLocaleContext()

  const toolbarButtons = [
    {
      Component: GetPremiumButton,
      slot: "start",
    },
    {
      Component: OlibaPreview,
      slot: "end",
    },
  ] as ButtonProps[]

  const title = t(`${COMMON.WEEKDAYS}.${today.weekday}`)

  return (
    <Page>
      <Page.Header
        title={title}
        subtitle={formatDate(today.date, "dd MMMM")}
        toolbarButtons={toolbarButtons}
        color="primary"
      />

      <Content slot="fixed" scrollY={true} scrollX={false}>
        <StaminaMeter />
        <div
          className={clsx(
            "flex flex-col h-full gap-y-4",
            "max-w-lg w-full mx-auto",
            "relative",
            "py-2"
          )}
        >
          <DailyMoveSection />

          <RecommendedAdventureCarousel />

          <MovementWizard />
        </div>
      </Content>
    </Page>
  )
}

export default TodayHomePage

import CircularSlider from "@fseehawer/react-circular-slider"
import classNames from "clsx"
import { findIndex, isEqual } from "lodash"
import isNil from "lodash/isNil"
import React, { useEffect, useState } from "react"
import { IconType } from "react-icons/lib"

export interface SliderProps<T> {
  values: T[]
  onChange: (value: T) => void
  className?: string
  type?: "circular"
  defaultValue?: T
  initialValue?: T | null
  KnobIcon?: IconType
  min?: number
  max?: number
  label?: string
  description?: string
  formatValue?: (value: T) => string
}

export const Slider = <T,>({
  onChange,
  label,
  description,
  values,
  className,
  formatValue,
  initialValue,
  defaultValue,
}: SliderProps<T>) => {
  const [value, setValue] = useState<T>(
    initialValue || defaultValue || values[0]
  )

  const index = findIndex(values, (v) => isEqual(v, value))

  const onSlide = (val: T) => {
    setValue(val)

    onChange(val)
  }

  useEffect(() => {
    if (!isNil(initialValue)) {
      setValue(initialValue)
    }
  }, [initialValue])

  const innerComponent = (
    <div className="absolute top-0 left-0 z-[1] flex flex-col items-center justify-center w-full h-full select-none text-primary">
      <div
        className={classNames(
          "relative flex flex-row items-center justify-center mx-8 mb-4 text-4xl font-semibold",
          className
        )}
      >
        <span className="w-full mx-auto text-center">
          {formatValue?.(value) || value}
        </span>
      </div>
      {label && <div className="text-base font-semibold">{label}</div>}
    </div>
  )

  return (
    <div className="flex flex-col items-center gap-y-8">
      <CircularSlider
        labelColor="#118CB6"
        knobColor="#118CB6"
        progressColorFrom={"#14A9DB"}
        progressColorTo="#118CB6"
        progressSize={24}
        trackColor="#cbd5e1"
        trackSize={24}
        knobSize={48}
        width={300}
        renderLabelValue={innerComponent}
        data={values}
        dataIndex={index}
        onChange={onSlide}
      />
      {description && (
        <span className="h-10 text-center max-w-fit text-neutral">
          {description}
        </span>
      )}
    </div>
  )
}

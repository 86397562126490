import { useIonRouter } from "@ionic/react"
import PostSessionFeedbackModal from "../components/Movement/Modals/PostSessionFeedbackModal"
import { useAuthenticatedClientContext } from "../contexts/AuthenticatedClientContext"

export const FeedbackListener = () => {
  const router = useIonRouter()

  const { client } = useAuthenticatedClientContext()

  const handleClose = () => {
    client.refetchQueries({ include: "active" })

    router.push("/app/hub/home", "forward", "replace")
  }

  return <>{<PostSessionFeedbackModal onClose={handleClose} />}</>
}

import { useEffect, useMemo, useState } from "react"
import { useStopwatch } from "react-timer-hook"
import clsx from "clsx"
import isNil from "lodash/isNil"
import { SessionInterval } from "./MovementSessionModal"

export const formatDuration = ({
  seconds,
  minutes,
  hours,
}: {
  seconds?: number
  minutes?: number
  hours?: number
}) => {
  if (isNil(minutes) && isNil(hours) && !isNil(seconds)) {
    minutes = Math.floor(seconds / 60) || 0
    hours = Math.floor(minutes / 60) || 0
    seconds = seconds % 60
  }

  if (!isNil(hours) && hours > 0) {
    return `${hours?.toString().padStart(2, "0")}h${minutes
      ?.toString()
      .padStart(2, "0")}`
  } else {
    return `${minutes?.toString().padStart(2, "0")}:${seconds
      ?.toString()
      .padStart(2, "0")}`
  }
}

export const MovementSessionInterval = ({
  interval,
}: {
  interval: SessionInterval
}) => {
  const remainingDuration = useMemo(() => {
    if (isNil(interval) || isNil(interval.targetSeconds)) return null

    const totalStopwatchTime = interval.duration

    const timeLeft = interval.targetSeconds - totalStopwatchTime

    if (timeLeft <= 0) {
      return { seconds: 0, minutes: 0, hours: 0 }
    }

    return {
      seconds: timeLeft % 60,
      minutes: Math.floor(timeLeft / 60) % 60,
      hours: Math.floor(timeLeft / 60 / 60),
    }
  }, [interval])

  const formattedDuration = useMemo(() => {
    if (isNil(remainingDuration)) {
      return formatDuration({ seconds: interval.duration })
    } else {
      return formatDuration(remainingDuration)
    }
  }, [remainingDuration, interval.duration])

  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-center p-8 text-center"
        // "rounded-lg shadow-inner"
      )}
    >
      <span className="text-3xl font-bold tracking-tight">
        {interval.name || "Interval"}
      </span>

      <span className={clsx("font-bold tracking-tighter text-8xl")}>
        {!isNil(interval.targetReps)
          ? `${interval.targetReps}x`
          : formattedDuration}
      </span>

      {interval.description && (
        <span
          className={clsx(
            "font-semibold text-xl mt-8 overflow-y-scroll h-40 rounded-lg shadow-inner p-4",
            "bg-neutral-500/10"
          )}
        >
          {interval.description}
        </span>
      )}
    </div>
  )
}

export const useSessionIntervalStopwatch = ({
  handleComplete,
  handleTick,
  autoStart = false,
}: {
  handleComplete: () => void
  handleTick?: (props?: {
    seconds: number
    minutes: number
    hours: number
  }) => void
  autoStart?: boolean
}) => {
  const [interval, setInterval] = useState<SessionInterval>()

  const { isRunning, start, pause, reset, ...currentDuration } = useStopwatch({
    autoStart,
  })

  const currentTime = useMemo(
    () =>
      currentDuration.seconds +
      currentDuration.minutes * 60 +
      currentDuration.hours * 60 * 60,
    [currentDuration]
  )

  const startStopwatch = () => {
    start()
  }

  const resetStopwatch = (
    newInterval: SessionInterval,
    autoStart?: boolean
  ) => {
    setInterval(newInterval)

    autoStart = autoStart || newInterval.autoStart

    reset(undefined, autoStart)
  }

  useEffect(() => {
    if (!isRunning || isNil(interval)) return

    if (currentTime <= 0) return

    if (handleTick) {
      handleTick()
    }

    if (!isNil(interval.targetSeconds)) {
      if (currentTime >= interval.targetSeconds) {
        handleComplete()
      }
    }
  }, [currentTime])

  return {
    isRunning,
    start: startStopwatch,
    pause,
    reset: resetStopwatch,
    currentDuration,
  }
}

import { useQuery } from "@apollo/client"
import classNames from "clsx"
import * as React from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../../contexts/ModalOrchestrationContext"

import {
  GetStyleDocument,
  GetStyleQuery,
  MovementAgendaItemSummaryFragment,
} from "../../../generated/graphql"
import { MEMBERSHIP, NAME_SPACES } from "../../../locales/constants"
import { BehaleIcon } from "../../BehaleIcon"
import Modal, { ModalContext, ModalProps } from "../../Core/Modal"
import Button from "../../Forms/Button"
import { ExpectedDurationPicker } from "../../Movement/Forms/ExpectedDurationPicker"

interface DailyMoveAdaptationModalBodyProps {
  item: MovementAgendaItemSummaryFragment
}

const DailyMoveAdaptationModalBody: React.FC<
  DailyMoveAdaptationModalBodyProps
> = ({ item }) => {
  const history = useHistory()

  const { name } = React.useContext(ModalContext)
  const { closeModal } = useModalOrchestrationContext()

  const { i18n, t } = useTranslation(NAME_SPACES.MEMBERSHIP)
  const TEXT = t(MEMBERSHIP.TODAY, {
    returnObjects: true,
  })

  const { data } = useQuery<GetStyleQuery>(GetStyleDocument, {
    fetchPolicy: "cache-first",
    variables: { style: item.movementStyle },
  })

  const { setValue, register, getValues } = useForm({
    defaultValues: {
      timeAvailable: item.expectedDuration,
    },
  })

  const Header = () => {
    return (
      <div className="flex flex-col items-center w-full mt-8 gap-y-2">
        <BehaleIcon fill="white" variant="yang" />

        <span className="text-3xl font-semibold tracking-tight text-white">
          {TEXT.DAILY_MOVE.PLANNED.MODAL.TITLE}
        </span>
      </div>
    )
  }

  const Description = () => {
    const description = TEXT.DAILY_MOVE.PLANNED.MODAL.SUBTITLE

    return <span className="text-lg">{description}</span>
  }

  const isFollowAlongVideoAvailable =
    item.hasFollowAlong || data?.activityStyle.isFollowAlongVideoAvailable

  const handleSubmit = () => {
    closeModal(name, "submit", getValues())

    const nextPath = isFollowAlongVideoAvailable
      ? `/app/agenda/items/${item.uuid}/session`
      : `/app/hub/agenda/items/${item.uuid}`

    history.push({ pathname: nextPath, state: getValues() })
  }

  return (
    <div
      className={classNames(
        `bg-${item.movementModality}`,
        "text-white text-center",
        "h-full flex flex-col p-8 gap-y-4"
      )}
    >
      <Header />
      <Description />

      <form className="flex flex-col items-center justify-center flex-grow place-self-center gap-y-4">
        <span className="text-xl font-medium">
          {TEXT.DAILY_MOVE.PLANNED.MODAL.AVAILABLE_TIME}
        </span>
        <div className="">
          <ExpectedDurationPicker
            name="timeAvailable"
            hideLabel
            register={register}
            setValue={(data) => {
              setValue("timeAvailable", data)
            }}
            value={getValues().timeAvailable}
            className={classNames(
              "self-center px-4 py-3 text-3xl bg-white rounded-md",
              `text-${item.movementModality}`
            )}
          />
        </div>
      </form>

      <div className="flex flex-col gap-y-1">
        <Button
          label={TEXT.DAILY_MOVE.PLANNED.CTA}
          color="white"
          labelClassName={`text-${item.movementModality}`}
          onClick={handleSubmit}
        />

        <Button
          onClick={() => closeModal(name, "cancel")}
          fill="clear"
          label={i18n.t("COMMON:FORMS.CANCEL")}
          iconSlot="icon-only"
          className="-mt-2 text-sm"
          textColor="white"
        />
      </div>
    </div>
  )
}

export const DailyMoveAdaptationModal: React.FC<Partial<ModalProps>> = ({
  name = ModalOrchestrationName.DailyMoveAdaptation,
  ...props
}) => {
  return (
    <Modal name={name} {...props}>
      <Modal.Body>
        {(props: any) => {
          return <DailyMoveAdaptationModalBody {...props} />
        }}
      </Modal.Body>
    </Modal>
  )
}

export default DailyMoveAdaptationModal

import { useLazyQuery, useMutation } from "@apollo/client"
import {
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonRouter,
} from "@ionic/react"
import * as Sentry from "@sentry/capacitor"
import { checkmarkDoneOutline, chevronBackOutline } from "ionicons/icons"
import isNil from "lodash/isNil"
import * as React from "react"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { RiMoreLine } from "react-icons/ri"
import { RouteComponentProps } from "react-router-dom"
import Content from "../../components/Core/Content"

import Page from "../../components/Core/Page"
import Button from "../../components/Forms/Button"
import { MovementDescriptionTextarea } from "../../components/Movement/Forms/Fields/MovementDescriptionTextarea"
import { MovementSessionFormModal } from "../../components/Movement/Modals/MovementSessionFormModal"
import { MovementIcon } from "../../components/Core/Icons"
import { PastSessionDetailCarousel } from "../../components/Movement/PastSession/PastSessionDetailCarousel"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import {
  DeleteMovementSessionDocument,
  DeleteMovementSessionMutation,
  DeleteMovementSessionMutationVariables,
  GetMovementSessionDocument,
  GetMovementSessionQuery,
  GetMovementSessionQueryVariables,
  MovementModality,
  MovementSession,
  UpdateMovementSessionDescriptionDocument,
  UpdateMovementSessionDescriptionMutation,
  UpdateMovementSessionDescriptionMutationVariables,
} from "../../generated/graphql"
import useToast from "../../hooks/useToast"
import { MOVEMENT, NAME_SPACES } from "../../locales/constants"
import { formatStyle, formatWeekday } from "../../utils/format"
import { SuggestedExternalLinkPreview } from "../../components/Movement/SuggestedExternalLinkPreview"

export type PastSessionPageProps = RouteComponentProps<{ uuid: string }>

const PastSessionPage: React.FC<PastSessionPageProps> = ({ match }) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.PAST_MOVEMENT_SESSION, { returnObjects: true })

  const router = useIonRouter()

  const { showError, showInfo } = useToast()
  const [present] = useIonActionSheet()

  const { isClientAuthenticated } = useAuthenticatedClientContext()
  const { captureEvent } = useAnalyticsContext()
  const { selectWeekday, refetchQueries } = useAgendaSchedulingContext()
  const { openModal, toggleLoading } = useModalOrchestrationContext()

  const [getSession, { data, loading, error }] = useLazyQuery<
    GetMovementSessionQuery,
    GetMovementSessionQueryVariables
  >(GetMovementSessionDocument, {
    variables: {
      uuid: match.params.uuid,
    },
    onCompleted: () => {
      toggleLoading(false)
    },
    fetchPolicy: "cache-and-network",
  })

  const session = data?.movementSession

  const [deleteSession] = useMutation<
    DeleteMovementSessionMutation,
    DeleteMovementSessionMutationVariables
  >(DeleteMovementSessionDocument, {
    onCompleted: (result) => {
      captureEvent(AnalyticsEvent.PastSessionDeleted, {
        uuid: session?.uuid,
      })

      if (result.deleteMovementSession) {
        showInfo(TEXT.ACTIONS.DELETE.RESULT.SUCCESS)
      }

      router.goBack()
    },
    onError: (error) => {
      Sentry.captureException(error)

      showError(TEXT.ACTIONS.DELETE.RESULT.ERROR)
    },
    refetchQueries,
  })

  const [updateDescription, { loading: updatingDescription }] = useMutation<
    UpdateMovementSessionDescriptionMutation,
    UpdateMovementSessionDescriptionMutationVariables
  >(UpdateMovementSessionDescriptionDocument, {
    refetchQueries: [
      {
        query: GetMovementSessionDocument,
        variables: { uuid: match.params.uuid },
      },
    ],
  })

  const handleUpdateDescription = async (description?: string | null) => {
    if (isNil(session)) {
      return
    }

    const variables = {
      uuid: session.uuid,
      description,
    }

    await updateDescription({ variables, onError: (error) => showError(error) })
  }

  const handleDelete = async () => {
    if (isNil(session)) {
      router.canGoBack() && router.goBack()
    }

    await deleteSession({
      variables: {
        uuid: session?.uuid,
      },
    })
  }

  const handleBack = () => {
    selectWeekday(session?.weekday)
    router.push("/app/hub/agenda", "back", "pop")
  }

  const presentActions = () => {
    captureEvent(AnalyticsEvent.PastSessionActionsClicked, {
      uuid: session?.uuid,
    })

    present({
      buttons: [
        {
          text: TEXT.ACTIONS.MODIFY.LABEL,
          handler: () => {
            openModal(ModalOrchestrationName.MovementSessionForm, {
              session: session,
            })
          },
        },
        {
          text: TEXT.ACTIONS.DELETE.LABEL,
          role: "destructive",
          handler: async () => {
            await handleDelete()
          },
        },
        {
          text: TEXT.ACTIONS.CANCEL,
          role: "cancel",
        },
      ],
    })
  }

  useEffect(() => {
    if (!loading && error) {
      showError(error)
      router.goBack()
    }
  }, [loading, error])

  useEffect(() => {
    if (isClientAuthenticated) {
      toggleLoading(true)
      getSession()
    }
  }, [isClientAuthenticated])

  const title = t(`${MOVEMENT.PAST_MOVEMENT_SESSION}.TITLE`, {
    date: formatWeekday(session?.weekday),
  })

  return (
    <Page>
      <IonHeader>
        <IonToolbar color={session?.movementModality || "primary"}>
          <IonTitle>
            <div className="flex flex-row items-center justify-center gap-y-2">
              <span>{title}</span>
            </div>
          </IonTitle>
          <IonButtons slot="start" color="white">
            <Button
              onClick={handleBack}
              icon={chevronBackOutline}
              iconSlot="icon-only"
              fill="clear"
            />
          </IonButtons>
          <IonButtons slot="end" color="white">
            <Button
              icon={RiMoreLine}
              iconSlot="icon-only"
              onClick={presentActions}
              fill="clear"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <Content slot="fixed">
        {session && (
          <div className="flex flex-col h-full">
            <div className="flex flex-col flex-grow bg-neutral-100 text-neutral">
              <div className="flex flex-row items-stretch p-4 pb-2 gap-x-4 md:justify-start">
                <div className="flex items-center justify-center">
                  <MovementIcon element={session} className="w-12 h-12 p-1" />
                </div>
                <div className="flex flex-col items-start justify-start flex-grow leading-tight">
                  <span className="font-semilight text-neutral">
                    {formatWeekday(session.weekday)}
                  </span>
                  <span className="flex-grow text-2xl font-semibold tracking-tighter text-neutral-700">
                    {session.title || formatStyle(session.movementStyle)}
                  </span>
                </div>

                <div className="flex flex-col items-center justify-center">
                  <IonIcon
                    icon={checkmarkDoneOutline}
                    color="success"
                    className="w-8 h-8"
                  />
                </div>
              </div>

              {session &&
                session.movementModality !== MovementModality.Knowledge && (
                  <PastSessionDetailCarousel
                    movementSession={session as MovementSession}
                  />
                )}

              {!isNil(session.movementAgendaItem?.suggestedExternalLink) && (
                <div className="flex flex-row items-center justify-start p-4 gap-x-4">
                  <SuggestedExternalLinkPreview
                    movementModality={session.movementModality}
                    suggestedExternalLink={
                      session.movementAgendaItem?.suggestedExternalLink
                    }
                    className="w-full h-24"
                  />
                </div>
              )}

              <div className="flex flex-col h-48 p-4">
                <MovementDescriptionTextarea
                  initialValue={session.description}
                  onChange={handleUpdateDescription}
                  placeholder={TEXT.DESCRIPTION.PLACEHOLDER}
                  loading={updatingDescription}
                  className="bg-neutral-300/30 text-neutral-700 placeholder-neutral-500"
                />
              </div>
            </div>
          </div>
        )}
      </Content>
      <MovementSessionFormModal />
    </Page>
  )
}

export default PastSessionPage

import {
  LocalNotificationActionId,
  ReminderTemplate,
} from "../../contexts/NotificationContext/notificationTypes"

export default {
  LOCAL: {
    [ReminderTemplate.WeekAheadPreview]: {
      label: "Aperçu de la semaine prochaine",
      title: "✅ Priorise ton bien-être",
      body: "Prends 5 secondes pour confirmer ta première séance de la semaine prochaine 👉",
    },
    [ReminderTemplate.MorningReminder]: {
      label: "Rappel de séance matinale",
      title: "🍃 Séance du jour",
      body: "{{ sessionTitle }} est prévue dans ton agenda de mouvement pour aujourd'hui.",
    },
  },
  ACTIONS: {
    [LocalNotificationActionId.View]: "View",
    [LocalNotificationActionId.Dismiss]: "Dismiss",
  },
}

import clsx from "clsx"
import Button from "../../Forms/Button"
import { useEffect, useMemo, useState } from "react"
import isNil from "lodash/isNil"
import { findIndex, get } from "lodash"
import { MOVEMENT, NAME_SPACES } from "../../../locales/constants"
import { useTranslation } from "react-i18next"
import { QUESTION_OPTIONS } from "./MovementWizard"
import { MovementWizardCard } from "./MovementWizardCard"
import { MovementWizardQuestion } from "./MovementWizardQuestion"
import { IconType } from "react-icons/lib"

export interface MovementWizardSlideProps<T> {
  question: MovementWizardQuestion
  index?: number
  defaultValue?: T
  options?: {
    value: T
    Icon: IconType
    onClick?: () => void
    color?: string
    className?: string
  }[]
  onSelect?: (question: MovementWizardQuestion, value: number) => void
  onSubmit?: (canSkip?: boolean) => void
}

export const MovementWizardSlide = <T,>({
  question,
  onSubmit,
  onSelect,
  children,
  defaultValue,
}: React.PropsWithChildren<MovementWizardSlideProps<T>>) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.WIZARD, { returnObjects: true })

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined
  )

  const options = get(QUESTION_OPTIONS, question)

  const handleOptionClick = (index: number) => {
    if (index === selectedIndex) {
      setSelectedIndex(undefined)
    } else {
      setSelectedIndex(index)
    }

    options?.[index].onClick?.()

    if (!isNil(onSelect)) {
      onSelect(question, options?.[index].value)
    }
  }

  const label = useMemo(() => {
    if (isNil(selectedIndex)) {
      return TEXT.QUESTIONS[question].PROMPT
    } else if (!isNil(options)) {
      return TEXT.QUESTIONS[question].OPTIONS[selectedIndex]
    } else {
      return TEXT.QUESTIONS[question].PROMPT
    }
  }, [selectedIndex, question, options])

  const isAnswerEmpty = useMemo(() => {
    return isNil(selectedIndex) && !isNil(options)
  }, [selectedIndex, options])

  const primaryButtonProps = useMemo(() => {
    return {
      onClick: () => onSubmit?.(isAnswerEmpty),
      label: isAnswerEmpty ? undefined : TEXT.QUESTIONS.CTAS.NEXT,
      className: clsx(
        "rounded-b-md",
        "text-white flex-grow m-0",
        isAnswerEmpty ? "text-neutral-800 bg-white/80" : "bg-primary"
      ),
      color: isAnswerEmpty ? "neutral" : "white",
      hidden: isAnswerEmpty,
    }
  }, [selectedIndex, onSubmit, question])

  useEffect(() => {
    if (!isNil(defaultValue)) {
      const index = findIndex(
        options,
        (option) => option.value === defaultValue
      )

      if (index >= 0) {
        setSelectedIndex(index)
      }
    }
  }, [defaultValue, options])

  return (
    <MovementWizardCard primaryButtonProps={primaryButtonProps}>
      <div className="flex flex-col items-start justify-around w-full h-full gap-y-2">
        <div className="flex flex-col items-start justify-start w-full px-4 pt-2 gap-y-2">
          <span className="text-xl font-semibold tracking-tighter">
            {TEXT.QUESTIONS[question].TITLE}
          </span>

          <span
            className={clsx(
              "font-medium tracking-tight text-lg w-full",
              isNil(selectedIndex)
                ? "text-neutral-500"
                : "text-primary-600 font-bold"
            )}
          >
            {label}
          </span>
        </div>

        {!isNil(options) && (
          <div className="flex flex-row items-start justify-around w-full gap-2 px-4 py-3">
            {options.map((option, index) => (
              <Button
                key={index}
                icon={option.Icon}
                fill="clear"
                color={
                  selectedIndex === index ? "white" : option.color || "primary"
                }
                iconSlot="icon-only"
                paddingWidth="thin"
                className={clsx(
                  "rounded-full aspect-1",
                  `ring-2 ring-${option.color || "primary"}`,
                  "shadow-lg",
                  selectedIndex === index
                    ? `bg-${option.color || "primary"}`
                    : "",
                  option.className
                )}
                onClick={() => handleOptionClick(index)}
              />
            ))}
          </div>
        )}

        {children}
      </div>
    </MovementWizardCard>
  )
}

import { useQuery } from "@apollo/client"
import { addBreadcrumb, captureException } from "@sentry/capacitor"
import { useEffect, useState, useMemo } from "react"
import {
  ListAdventureTemplatesDocument,
  ListAdventureTemplatesQuery,
  ListAdventureTemplatesQueryVariables,
  SqualoAdventureElement,
} from "../../generated/graphql"
import useRouterQueryParams from "../../hooks/useRouterQueryParams"
import Content from "../Core/Content"
import { elementOrder, getElementContrastColor } from "../../utils/elementUtils"
import { AdventurePreviewCard } from "../Adventure/AdventurePreviewCard"
import { ElementIcon } from "../Core/Icons"
import { TagButton, TagRow } from "../Core/Tag"
import { IoCloseCircle } from "react-icons/io5"
import clsx from "clsx"
import Loading from "../Loading"
import { useLocaleContext } from "../../contexts/LocaleContext"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { formatElement } from "../../utils/format"

export interface MicoAdventureGalleryContentProps {
  element?: SqualoAdventureElement
  adventureTemplates: ListAdventureTemplatesQuery["listAdventures"]
}

export const ElementFitlerRow: React.FC<{
  className?: string
  elementsSelected: SqualoAdventureElement[]
  setElementsSelected: (element: SqualoAdventureElement[]) => void
}> = ({ className, elementsSelected, setElementsSelected }) => {
  const { captureEvent } = useAnalyticsContext()

  const isSelected = (element: SqualoAdventureElement) =>
    elementsSelected.includes(element)

  const setElementSelected = (element: SqualoAdventureElement) => {
    captureEvent(AnalyticsEvent.ElementFilterRowElementSelected, {
      element,
      isSelected: !isSelected(element),
    })

    if (isSelected(element)) {
      setElementsSelected(elementsSelected.filter((e) => e !== element))
    } else {
      setElementsSelected([...elementsSelected, element])
    }
  }

  return (
    <TagRow
      className={clsx(
        "flex flex-row max-w-3xl px-4 py-4 mx-auto gap-x-4",
        className
      )}
    >
      {elementOrder.map((element) => (
        <TagButton
          key={element}
          className={clsx(
            "flex flex-row items-center gap-x-2 flex-shrink-0",
            `bg-${element}-500 text-${getElementContrastColor(element)}`,
            !isSelected(element) && `bg-opacity-70 text-opacity-70`
          )}
          endIcon={isSelected(element) ? IoCloseCircle : undefined}
          onClick={() => setElementSelected(element)}
        >
          <ElementIcon element={element} contrast />
          <span>{formatElement(element)}</span>
        </TagButton>
      ))}
    </TagRow>
  )
}

const MicoAdventureGalleryTab: React.FC = () => {
  const query = useRouterQueryParams()
  const element = query.get("element") as SqualoAdventureElement | undefined

  const { isInitialized, language } = useLocaleContext()

  const [elementsSelected, setElementsSelected] = useState<
    SqualoAdventureElement[]
  >([])

  const { data, error, loading } = useQuery<
    ListAdventureTemplatesQuery,
    ListAdventureTemplatesQueryVariables
  >(ListAdventureTemplatesDocument, {
    variables: {
      locale:
        language as unknown as ListAdventureTemplatesQueryVariables["locale"],
    },
    skip: !isInitialized,
    fetchPolicy: "cache-first",
  })

  const adventureTemplates = data?.listAdventures || []

  const elementFilter = elementsSelected
    ? { element: elementsSelected }
    : undefined

  const filteredAdventures = useMemo(
    () =>
      adventureTemplates.filter(
        (adventure) =>
          elementsSelected.length === 0 ||
          elementsSelected.includes(adventure.element)
      ),
    [adventureTemplates, elementFilter]
  )

  useEffect(() => {
    if (error) {
      addBreadcrumb({
        category: "adventure",
        message: "Error while fetching adventures",
        type: "error",
      })

      captureException(error)
    }
  }, [error])

  useEffect(() => {
    if (element) {
      setElementsSelected([element])
    }
  }, [element])

  return (
    <>
      <Content scrollY scrollX={false}>
        <div className="flex w-full bg-primary">
          <ElementFitlerRow
            elementsSelected={elementsSelected}
            setElementsSelected={setElementsSelected}
          />
        </div>
        {loading && <Loading overlay background="neutral-100" />}
        <div className="grid flex-col w-full max-w-3xl grid-cols-1 gap-4 p-4 mx-auto md:grid-cols-2 md:px-8 lg:px-0">
          {filteredAdventures.map((adventure) => (
            <AdventurePreviewCard key={adventure.id} adventure={adventure} />
          ))}
        </div>
      </Content>
    </>
  )
}

export default MicoAdventureGalleryTab

import classNames, { clsx } from "clsx"

import isNil from "lodash/isNil"
import orderBy from "lodash/orderBy"

import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  IoAddOutline,
  IoCalendarOutline,
  IoLinkOutline,
  IoPlayOutline,
} from "react-icons/io5"
import { useHistory } from "react-router"
import { take } from "lodash"

import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import {
  LogMovementSessionMutationVariables,
  MovementAgendaItem,
  MovementAgendaItemSuggestedBy,
  MovementAgendaItemSummaryFragment,
  MovementModality,
} from "../../generated/graphql"
import { MEMBERSHIP, NAME_SPACES } from "../../locales/constants"
import Button from "../Forms/Button"
import { MovementIcon } from "../Core/Icons"
import DailyMoveAdaptationModal from "./Modals/DailyMoveAdaptationModal"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import { RiCheckDoubleLine } from "react-icons/ri"
import { MovementAgendaItemFormModal } from "../Movement/Modals/MovementAgendaItemFormModal"
import useCurrentPhaseActivities from "../../hooks/useCurrentPhaseActivities"
import { useLocaleContext } from "../../contexts/LocaleContext"

import Carousel from "nuka-carousel"
import { DiscreteCarouselPagination } from "../Core/CarouselPagination"
import useActivityStyle from "../../hooks/useActivityStyle"
import {
  getModalityContrastColor,
  isBodyTreeEmpty,
} from "../../utils/movementUtils"
import { MovementActivity } from "../../generated/graphql"
import { buildSessionLabel as buildActivityTitle } from "../Movement/Style"
import { TagButton, TagRow } from "../Core/Tag"
import { DailyMoveCollectionType } from "./DailyMoveCollectionType"
import { useIonRouter } from "@ionic/react"
import { Link } from "react-router-dom"
import { DailyMoveEmptyState } from "./DailyMoveEmptyState"
import { openBrowser } from "../../hooks/useInlineBrowser"
import { MovementAgendaItemFormProps } from "../Movement/Modals/MovementAgendaItemFormModal"
import {
  MovementInformationTargetBodyPartsSpan,
  MovementInformationTimingSpan,
} from "../Movement/MovementInformationDetails"

export const DailyMoveSection: React.FC = () => {
  return (
    <div className="flex flex-col w-full mx-auto gap-y-4">
      <DailyMoveCarousel />

      <DailyMoveAdaptationModal />
      <MovementAgendaItemFormModal />
    </div>
  )
}

export const DailyMoveCarousel: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)
  const TEXT = t(MEMBERSHIP.TODAY, { returnObjects: true })

  const { agenda, today } = useAgendaSchedulingContext()
  const { captureEvent } = useAnalyticsContext()

  const [selectedCollectionIndex, setSelectedCollectionIndex] =
    React.useState<number>(0)

  const favoriteActivities = agenda?.favoriteMovementActivities || []

  const frequentFavoriteActivities = orderBy(
    favoriteActivities,
    "usualWeeklyFrequency",
    "desc"
  )

  const { activities: adventureActivities } = useCurrentPhaseActivities()

  const nextAdventureActivities = take(adventureActivities, 3)

  const handleAfterSlide = (index: number) => {
    console.debug("after slide", index)
  }

  const handleSelectCollection = (index: number) => {
    captureEvent(AnalyticsEvent.DailyMoveCollectionSelected, {
      collection: Object.keys(DailyMoveCollectionType)[index],
    })

    setSelectedCollectionIndex(index)
  }

  const suggestedItems = (agenda?.suggestedItems || []).filter(
    (item) =>
      item.suggestedByType === MovementAgendaItemSuggestedBy.Coach ||
      item.suggestedByType === MovementAgendaItemSuggestedBy.Milou
  )

  const moveCollections = {
    [DailyMoveCollectionType.Planned]: today.items || [],
    [DailyMoveCollectionType.Suggestion]: suggestedItems,
    [DailyMoveCollectionType.Adventure]: nextAdventureActivities,
    [DailyMoveCollectionType.Favorite]: frequentFavoriteActivities,
  }

  const collectionsAvailable = useMemo(() => {
    return Object.keys(moveCollections).filter(
      (key) =>
        moveCollections[key as DailyMoveCollectionType].length > 0 ||
        key === DailyMoveCollectionType.Favorite
    ) as DailyMoveCollectionType[]
  }, [moveCollections])

  return (
    <div className="flex flex-col w-full mx-auto gap-y-2">
      <TagRow className="px-4 py-2">
        {collectionsAvailable.map((collection, index) => (
          <TagButton
            key={collection}
            className={clsx(
              "px-2 bg-white shadow-sm text-neutral-500",
              selectedCollectionIndex === index
                ? "ring-inset ring-1 ring-neutral-500 ring-opacity-50"
                : "opacity-60"
            )}
            onClick={() => handleSelectCollection(index)}
          >
            {TEXT.DAILY_MOVE.TYPES[collection as DailyMoveCollectionType]}
          </TagButton>
        ))}
      </TagRow>
      {collectionsAvailable.map((collection, index) =>
        index === selectedCollectionIndex ? (
          <Carousel
            key={index}
            className={clsx(
              "h-40 pb-8",
              "duration-300 ease-in-out transition motion-reduce:transition-none"
            )}
            renderCenterLeftControls={() => <></>}
            renderCenterRightControls={() => <></>}
            renderBottomCenterControls={DiscreteCarouselPagination}
            autoplay={false}
            wrapAround={false}
            afterSlide={handleAfterSlide}
          >
            {moveCollections[collection].map((move, index) => (
              <DailyMoveButton move={move} index={index} key={index} />
            ))}
            {collection === DailyMoveCollectionType.Favorite && (
              <DailyMoveEmptyState collection={collection} />
            )}
          </Carousel>
        ) : null
      )}
    </div>
  )
}

export const DailyMoveSlide: React.FC = ({ children }) => {
  return (
    <div className="flex flex-col items-center justify-center px-4">
      {children}
    </div>
  )
}

export interface DailyMoveButtonProps {
  index?: number
  move: MovementAgendaItemSummaryFragment | MovementActivity
}

export const DailyMoveButton: React.FC<DailyMoveButtonProps> = ({
  index,
  move,
}) => {
  const router = useIonRouter()

  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)
  const TEXT = t(MEMBERSHIP.TODAY, { returnObjects: true })

  const history = useHistory()

  const { captureEvent } = useAnalyticsContext()
  const { openModal } = useModalOrchestrationContext()
  const { logSession, currentWeekUuid, today } = useAgendaSchedulingContext()
  const { formatDate } = useLocaleContext()

  const isAgendaItem = move.__typename === "MovementAgendaItem"

  const moveTitle = buildActivityTitle(move)
  const moveActivityStyle = useActivityStyle(move.movementStyle)

  const isAdventureActivity = isAgendaItem && !isNil(move.adventure)

  const isFollowAlongBasedItem =
    isAgendaItem && moveActivityStyle?.isFollowAlongVideoAvailable

  const isKnowledgeItem =
    isAgendaItem && move.movementModality === MovementModality.Knowledge

  const isExtraInfoAvailable =
    isAgendaItem &&
    moveActivityStyle?.isTargetBodyPartAvailable &&
    !isBodyTreeEmpty(move.movementTargetBodyParts)

  const editMove = () => {
    let variables: MovementAgendaItemFormProps

    if (isAgendaItem) {
      variables = {
        agendaItem: move as MovementAgendaItem,
      }
    } else {
      variables = {
        activity: move as MovementActivity,
      }
    }

    openModal(ModalOrchestrationName.MovementAgendaItemForm, variables, {
      onClose: (reason?: string) => {
        if (reason === "added" || reason === "modified") {
          router.push("/app/hub/agenda", "forward")
        }
      },
    })
  }

  const handlePrimaryAction = async () => {
    captureEvent(AnalyticsEvent.DailyMovePrimaryActionClicked, {
      index,
      isAgendaItem,
      isKnowledgeItem,
      isFollowAlongBasedItem,
      isAdventureActivity,
    })

    if (isAgendaItem && isFollowAlongBasedItem) {
      history.push(`/app/agenda/items/${move.uuid}/session`)
    } else if (isKnowledgeItem) {
      openBrowser(move.suggestedExternalLink)
    } else if (isAgendaItem) {
      logMove()
    } else {
      editMove()
    }
  }

  const handleSecondaryAction = async () => {
    captureEvent(AnalyticsEvent.DailyMoveSecondaryActionClicked, {
      index,
      isAgendaItem,
      isKnowledgeItem,
      isFollowAlongBasedItem,
      isAdventureActivity,
    })

    if (isKnowledgeItem || !isAgendaItem) {
      logMove()
    } else {
      editMove()
    }
  }

  const logMove = async () => {
    const reportedDate = formatDate(today.date, "yyyy-MM-dd")

    let variables

    if (isAgendaItem) {
      variables = {
        movementWeekUuid: currentWeekUuid,
        movementAgendaItemUuid: move.uuid,
        reportedDate,
      } as LogMovementSessionMutationVariables
    } else {
      variables = {
        reportedDate,
        movementWeekUuid: currentWeekUuid,
        movementStyle: move.movementStyle,
      } as LogMovementSessionMutationVariables
    }

    await logSession(variables)
  }

  const primaryActionLabel = useMemo(() => {
    if (isFollowAlongBasedItem) {
      return TEXT.DAILY_MOVE.CTAS.START
    } else if (isKnowledgeItem) {
      return TEXT.DAILY_MOVE.CTAS.VIEW
    } else if (isAgendaItem) {
      return TEXT.DAILY_MOVE.CTAS.LOG
    } else {
      return TEXT.DAILY_MOVE.CTAS.PLAN
    }
  }, [isAgendaItem, isKnowledgeItem, isFollowAlongBasedItem])

  const PrimaryActionIcon = useMemo(() => {
    if (isFollowAlongBasedItem) {
      return IoPlayOutline
    } else if (isKnowledgeItem) {
      return IoLinkOutline
    } else if (isAgendaItem) {
      return RiCheckDoubleLine
    } else {
      return IoAddOutline
    }
  }, [isAgendaItem, isKnowledgeItem, isFollowAlongBasedItem])

  const SecondaryActionIcon = useMemo(() => {
    if (isKnowledgeItem || !isAgendaItem) {
      return RiCheckDoubleLine
    } else {
      return IoCalendarOutline
    }
  }, [isAgendaItem, isFollowAlongBasedItem, isKnowledgeItem])

  return (
    <div className="flex flex-col items-center justify-center w-full px-4 h-52">
      <div
        className={clsx(
          "flex flex-col w-full h-full rounded-md shadow-lg bg-neutral-200/20"
        )}
      >
        <div
          className={clsx(
            "min-h-32",
            "flex flex-col bg-white h-full rounded-t-md",
            isAgendaItem &&
              !isNil(move.adventure) &&
              `border-t-8 border-${move.adventure.squaloAdventureElement}-500`
          )}
        >
          {isExtraInfoAvailable && (
            <div
              className={clsx(
                "flex flex-row pt-4 pb-1 px-3 overflow-hidden gap-y-4",
                "opacity-80"
              )}
            >
              <MovementInformationTargetBodyPartsSpan
                isTargetBodyPartAvailable={isExtraInfoAvailable}
                className="text-sm text-neutral/90"
                item={move}
              />
            </div>
          )}
          <div
            className={clsx(
              "relative flex flex-col justify-between flex-grow h-full p-4 overflow-hidden",
              isExtraInfoAvailable ? "gap-y-4" : "gap-y-8"
            )}
          >
            <div className="flex flex-row items-start justify-between w-full">
              <div className="flex flex-row items-center text-sm font-semibold tracking-tighter text-neutral/80 gap-x-1">
                {/* <span className="flex flex-row items-center uppercase gap-x-1">
                  {moveTypeLabel}
                </span> */}
              </div>
            </div>

            <div className="flex flex-col justify-between w-full gap-y-1">
              {isAgendaItem && (
                <div className="flex flex-row items-center justify-start -mb-1 gap-x-2">
                  <MovementInformationTimingSpan
                    item={move}
                    className="text-sm text-neutral/80"
                  />
                </div>
              )}

              <Link
                to={isAgendaItem ? `/app/hub/agenda/items/${move.uuid}` : "#"}
                className={clsx(
                  "flex flex-row items-end justify-between gap-x-2",
                  move.movementModality == MovementModality.Breathing
                    ? `text-${getModalityContrastColor(move.movementModality)}`
                    : `text-${move.movementModality}-600`
                )}
              >
                <span
                  className={clsx(
                    "text-xl font-semibold",
                    "line-clamp-2 w-full"
                  )}
                >
                  {moveTitle}
                </span>
                <MovementIcon
                  element={move}
                  bgColor="transparent"
                  className={clsx("flex-shrink-0 w-10 h-10")}
                />
              </Link>
            </div>
          </div>
        </div>

        <div
          className={clsx("rounded-b-md flex flex-row items-stretch w-full")}
        >
          <Button
            icon={PrimaryActionIcon}
            label={primaryActionLabel}
            fill="clear"
            textColor={getModalityContrastColor(move.movementModality)}
            className={classNames(
              "rounded-bl-md rounded-t-none rounded-br-none",
              "flex-grow m-0",
              `bg-${move.movementModality}-600`
            )}
            iconClassName="absolute left-0"
            labelClassName="no-underline"
            onClick={handlePrimaryAction}
          />

          <Button
            icon={SecondaryActionIcon}
            fill="clear"
            textColor={getModalityContrastColor(move.movementModality)}
            className={classNames(
              "rounded-br-md rounded-t-none rounded-bl-none",
              "border-l m-0",
              `bg-${move.movementModality}-400`,
              `border-${getModalityContrastColor(move.movementModality)}/30`
            )}
            iconClassName="left-0"
            iconSlot="icon-only"
            onClick={handleSecondaryAction}
          />
        </div>
      </div>
    </div>
  )
}

import { ADVENTURE } from "../constants"

export default {
  [ADVENTURE.DIFFICULTY]: {
    [0]: "Débutant",
    [1]: "Intermédiaire",
    [2]: "Avancé",
    [3]: "Expert",
    [4]: "Athlète",
  },
  [ADVENTURE.CURRENT]: {
    TIME_LEFT: "{{ value }}",
    PHASES_COMPLETED: "étape",
    SESSIONS_COMPLETED: "séances",
    STATUS: {
      PAUSED: "En pause",
      IN_PROGRESS: "En cours",
    },
    ACTIONS: {
      PAUSE: "Mettre en pause",
      RESUME: "Reprendre l'aventure",
      START_NEXT: "Commencer autre aventure",
    },
    CONVERSION: {
      PHASE_LOCKED: {
        TITLE: "Étape verrouillée",
        SUBTITLE: "Termine l'étape précédente pour débloquer cette étape.",
        CTA: "Débloquer l'étape",
      },
    },
  },
  [ADVENTURE.TYPE]: {
    COMMUNITY: "Gratuit",
    PREMIUM: "Premium",
  },
  START: {
    TITLE: "Commence ton aventure",
  },
  NOT_AVAILABLE_ON_IOS_YET:
    "La séléction d'aventure n'est pas encore disponible.",
  [ADVENTURE.GALLERY]: {
    TITLE: "Aventures",
    TITLE_WITH_ELEMENT: "Aventures - {{ element }}",
    DESCRIPTION:
      "Les aventures sont des programmes sportifs flexibles qui t'aident à atteindre tes objectifs. Elles s'adaptent à ton emploi du temps et à ta progression.",
    ITEM: {
      CTA: "Choisir aventure",
      NARRATIVES: {
        TITLE: "C'est pour qui ?",
      },
      SUCCESS: "Ton aventure commence !",
    },
    SKIP: "Je choisirais plus tard",
  },
  [ADVENTURE.TEMPLATE]: {
    TABS: {
      INFORMATION: {
        LABEL: "Détails",
      },
      PHASES: {
        LABEL: "Étapes",
        PHASE_N: "Étape {{ index }}",
      },
    },
    EARLY_BIRD: {
      PRESET_MESSAGE:
        // "Hello, I'd like to start the early bird adventure: {{ title }}",
        "Hello, je veux commencer l'aventure {{ title }}",
    },
    LOCKED: {
      CTA: "Débloque l'aventure",
      CARD: {
        TITLE: "Aventure verrouillée !",
        SUBTITLE:
          "Abonne-toi à Behale Hero pour débloquer cette expérience unique.",
      },
    },
    UNLOCKED: {
      CTA: "Démarre l'aventure",
    },
    PRODUCTS: {
      TITLE: "Produits",
      CTA: "En savoir plus",
    },
    PHASES: {
      TITLE: "Parcours",
    },
  },
  [ADVENTURE.KICK_OFF]: {
    TITLE: "On prépare ton aventure...",
    SUBTITLE:
      "Prépare-toi à découvrir ce que le bien-être personnalisé peut vraiment faire pour toi...",
    TOAST: {
      SUCCESS: "Ton aventure personalisée t'attend !",
    },
  },
}

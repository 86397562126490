import { useQuery } from "@apollo/client"
import { useIonRouter } from "@ionic/react"
import * as React from "react"
import { useTranslation } from "react-i18next"
import { IoVideocamOutline } from "react-icons/io5"

import {
  GetStyleDocument,
  GetStyleQuery,
  MovementSession,
} from "../../../generated/graphql"
import { MOVEMENT, NAME_SPACES } from "../../../locales/constants"
import {
  formatBodyTree,
  formatDistance,
  formatRelativeRPE,
  formatSessionDuration,
} from "../../../utils/format"
import { DetailBlock, DetailCarousel } from "../DetailCarousel"
import {
  DistanceIcon,
  DurationIcon,
  IntensityIcon,
  TargetBodyPartIcon,
} from "../../Core/Icons"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../../contexts/ModalOrchestrationContext"

export const PastSessionDetailCarousel: React.FC<{
  movementSession: MovementSession
}> = ({ movementSession }) => {
  const router = useIonRouter()

  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.FORM, { returnObjects: true })

  const { openModal } = useModalOrchestrationContext()

  const { data } = useQuery<GetStyleQuery>(GetStyleDocument, {
    variables: { style: movementSession.movementStyle },
  })

  const targetBodyPartsAvailable = data?.activityStyle.isTargetBodyPartAvailable

  const durationShown =
    movementSession.sessionActualDuration || movementSession.reportedDuration

  const rpeShown = movementSession.reportedRatingOfPerceivedExertion

  const distanceShown = movementSession.reportedDistance

  const handleClick = () => {
    openModal(ModalOrchestrationName.MovementSessionForm, {
      session: movementSession,
    })
  }

  return (
    <DetailCarousel className="py-2">
      {movementSession.followAlong && (
        <DetailBlock
          className="min-w-0 w-60"
          Icon={IoVideocamOutline}
          label={TEXT.FOLLOW_ALONG.LABEL}
          value={movementSession.followAlong.title}
          onClick={() => {
            router.push(
              `/app/hub/discover/videos/${movementSession.followAlong?.uuid}`,
              "forward"
            )
          }}
          scrollX={true}
        />
      )}

      {distanceShown && (
        <DetailBlock
          Icon={DistanceIcon}
          label={TEXT.DISTANCE.LABEL}
          value={formatDistance(distanceShown)}
          onClick={handleClick}
        />
      )}

      {durationShown && (
        <DetailBlock
          Icon={DurationIcon}
          label={TEXT.DURATION.LABEL}
          value={formatSessionDuration(durationShown)}
          onClick={handleClick}
        />
      )}

      {rpeShown && (
        <DetailBlock
          Icon={IntensityIcon}
          label={TEXT.RPE.LABEL}
          value={formatRelativeRPE(rpeShown)}
          onClick={handleClick}
        />
      )}

      {targetBodyPartsAvailable && (
        <DetailBlock
          Icon={TargetBodyPartIcon}
          label={TEXT.BODY_PART.LABEL}
          value={formatBodyTree(movementSession.movementTargetBodyParts)}
          onClick={handleClick}
        />
      )}
    </DetailCarousel>
  )
}

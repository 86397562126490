import React, { useEffect, useRef } from "react"
import { ChatBuilder, ScrollToRef, State } from "@papercups-io/chat-builder"
import { Message, Papercups } from "@papercups-io/browser"
import { useTranslation } from "react-i18next"
import { ChildrenProps } from "@papercups-io/chat-builder/dist/components/ChatBuilder"
import TextareaAutosize from "react-textarea-autosize"

import { getCurrentEnvironment } from "../../utils"
import { MEMBERSHIP, NAME_SPACES } from "../../locales/constants"
import { isNil } from "lodash"
import Button from "../../components/Forms/Button"
import { closeOutline, send } from "ionicons/icons"
import clsx from "clsx"
import { useLocaleContext } from "../LocaleContext"
import { timeFromDate } from "../AgendaSchedulingContext/agendaTimeUtils"
import { useAuthenticatedClientContext } from "../AuthenticatedClientContext"
import { DevicePlatform } from "../../generated/graphql"

const CustomerMessage = ({
  message,
  isLastMessage,
  state,
}: {
  message: Message
  state: State
  isLastMessage?: boolean
}) => {
  return (
    <div className="flex flex-col my-2 gap-y-1">
      <div className={`flex justify-end`}>
        <span
          className={`rounded-md py-2 px-3 text-white bg-primary-500 whitespace-pre-wrap max-w-[80%]`}
        >
          {message.body}
        </span>
      </div>
      {isLastMessage && (
        <MessageStatus
          message={message}
          className="text-right"
          isSending={state.isSending}
        />
      )}
    </div>
  )
}

const AgentMessage = ({
  message,
  isLastMessage,
  state,
}: {
  message: Message
  isLastMessage?: boolean
  state: State
}) => {
  const { user } = message

  const name = user?.display_name || user?.full_name || "Coach"
  const photoUrl = user?.profile_photo_url || null

  return (
    <div className="flex flex-col my-2 gap-y-1">
      <div className={`flex flex-row gap-x-2 justify-start items-center`}>
        {!isNil(photoUrl) ? (
          <div
            className="flex items-center justify-center w-8 h-8 mr-2 bg-center bg-cover rounded-full"
            style={{ backgroundImage: `url(${photoUrl})` }}
          ></div>
        ) : (
          <div className="flex items-center justify-center w-8 h-8 mr-2 text-white rounded-full bg-primary-500">
            {name.slice(0, 1).toUpperCase()}
          </div>
        )}
        <span
          className={`rounded py-2 px-3 bg-neutral-200 dark:bg-gray-700 dark:text-white max-w-[80%]`}
        >
          {message.body}
        </span>
      </div>

      {isLastMessage && (
        <MessageStatus
          message={message}
          className="text-left"
          prefix={name}
          isSending={state.isSending}
        />
      )}
    </div>
  )
}

const MessageStatus = ({
  message,
  isSending,
  className,
  prefix,
}: {
  message: Message
  isSending: boolean
  className?: string
  prefix?: string
}) => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)

  const { CHAT } = t(MEMBERSHIP.GUIDANCE, { returnObjects: true })

  const { formatTime } = useLocaleContext()

  let status = CHAT.SENDING

  if (!isSending) {
    if (!isNil(message.sent_at)) {
      status = t("ASSISTANCE.CHAT.SENT_AT", {
        time: formatTime(timeFromDate(new Date(message.sent_at)), "HH:mm"),
      })
    }
  }

  if (prefix) {
    status = `${prefix} | ${status}`
  }

  return (
    <span className={clsx("font-light text-neutral-400", className)}>
      {status}
    </span>
  )
}

const ChatMessages = ({
  state,
  messages = [],
  scrollToRef,
}: {
  state: State
  messages: Message[]
  customerId?: string | null
  scrollToRef: ScrollToRef
}) => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)

  return (
    <div className="px-4 py-3">
      {messages.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full">
          <span className="text-neutral-500">
            {t("ASSISTANCE.CHAT.NO_MESSAGES_SENT")}
          </span>
        </div>
      ) : null}
      {messages.map((message, idx) => {
        const key = message.id || idx
        const isMe = isNil(message.user)

        const isPrevMessageDifferent =
          idx > 0 && messages[idx - 1].user_id !== message.user_id

        const MessageComponent = isMe ? CustomerMessage : AgentMessage

        return (
          <div key={key} className={clsx(isPrevMessageDifferent && "mt-4")}>
            <MessageComponent
              key={key}
              message={message}
              state={state}
              isLastMessage={idx === messages.length - 1}
            />
          </div>
        )
      })}

      {state.isOpen && <div key="scroll-el" ref={scrollToRef} />}
    </div>
  )
}

interface ChatProps extends ChildrenProps {
  presetMessageBody?: string
}

const Chat = ({
  config,
  state,
  onSendMessage,
  scrollToRef,
  onClose,
  presetMessageBody,
}: ChatProps) => {
  const [message, setMessageBody] = React.useState("")
  const { messages = [], customerId } = state

  const { platform } = useAuthenticatedClientContext()

  const handleChangeMessage = (e: any) => setMessageBody(e.target.value)

  const handleSendMessage = () => {
    onSendMessage({ body: message })
    setMessageBody("")
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleSendMessage()
  }

  useEffect(() => {
    if (presetMessageBody) {
      if (typeof presetMessageBody == "string") {
        setMessageBody(presetMessageBody)
      }
    }
  }, [presetMessageBody])

  return (
    <div
      className={clsx(
        "flex flex-row-reverse items-end h-screen w-screen md:p-4",
        "duration-300 ease-in-out transition motion-reduce:transition-none",
        state.isOpen ? "opacity-100 z-[999999]" : "opacity-0 -z-[1000]"
      )}
    >
      <div
        className={clsx(
          "flex flex-col max-w-screen md:max-w-lg h-full md:shadow-2xl md:rounded-lg md:border border-primary-600",
          "bg-primary"
        )}
      >
        <div className="flex flex-col p-2 text-white md:rounded-t-lg bg-primary mt-safe">
          <div className="flex flex-row items-start justify-between p-4">
            <div className="flex flex-col gap-y-2">
              <h2 className="text-2xl font-semibold">{config.title}</h2>
              <span className="">{config.subtitle}</span>
            </div>
            <Button
              onClick={onClose}
              icon={closeOutline}
              iconSlot="icon-only"
              className="-mt-4 -mr-4"
              fill="clear"
              textColor="white"
              paddingWidth="thin"
            />
          </div>
        </div>
        <div className="flex flex-col flex-grow w-screen overflow-y-scroll shadow-inner md:w-auto bg-neutral-100 rounded-t-xl text-neutral-700">
          <ChatMessages
            state={state}
            messages={messages}
            customerId={customerId}
            scrollToRef={scrollToRef}
          />
        </div>
        <div className="md:rounded-b-lg bg-neutral-100 text-neutral-700 border-neutral-300 pb-safe">
          <div className="flex flex-row items-center px-4 py-4 border-t gap-x-2">
            <div className="flex-grow p-2 bg-white border shadow-md rounded-xl">
              <form className="flex flex-row" onSubmit={handleSubmit}>
                <TextareaAutosize
                  minRows={1}
                  maxRows={3}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" &&
                      !e.shiftKey &&
                      platform === DevicePlatform.Web
                    ) {
                      e.preventDefault()
                      handleSendMessage()
                    }
                  }}
                  className="box-border w-full p-2 bg-transparent rounded-lg outline-none dark:text-white"
                  value={message}
                  placeholder={config.newMessagePlaceholder}
                  onChange={handleChangeMessage}
                />
              </form>
            </div>
            <div className="flex items-center w-12 h-12 rounded-full aspect-1 bg-primary">
              <Button
                icon={send}
                textColor="white"
                fill="clear"
                iconSlot="icon-only"
                className="w-full h-full"
                iconClassName="h-6 w-6 flex-shrink-0"
                disabled={!message}
                onClick={handleSendMessage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface OlibaWidgetProps
  extends Omit<React.ComponentProps<typeof ChatBuilder>, "config"> {
  isOpen: boolean
  customerId?: string
  conversationId?: string
  messages?: Message[]
  client?: Papercups
  presetMessageBody?: string
}

const Widget: React.FC<OlibaWidgetProps> = ({
  customerId,
  conversationId,
  messages,
  isOpen,
  onChatClosed,
  presetMessageBody,
  ...props
}) => {
  const builderRef = useRef<ChatBuilder>(null)

  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)
  const { CHAT } = t(MEMBERSHIP.GUIDANCE, { returnObjects: true })

  const config = {
    customerId,
    token: process.env.REACT_APP_OLIBA_TOKEN as string,
    accountId: process.env.REACT_APP_OLIBA_TOKEN as string,
    inboxId: process.env.REACT_APP_OLIBA_INBOX_ID as string,
    baseUrl: process.env.REACT_APP_OLIBA_URL as string,
    title: CHAT.TITLE,
    subtitle: CHAT.SUBTITLE,
    primaryColor: "#118cb6",
    shouldRequireEmail: false,
    newMessagePlaceholder: CHAT.NEW_MESSAGE_PLACEHOLDER,
    showAgentAvailability: false,
    agentAvailableText: CHAT.AGENT_AVAILABLE_TEXT,
    agentUnavailableText: CHAT.AGENT_UNAVAILABLE_TEXT,
    setInitialMessage: [CHAT.INITIAL_MESSAGE],
    hideToggleButton: true,
    isCloseable: true,
  } as React.ComponentProps<typeof ChatBuilder>["config"]

  useEffect(() => {
    if (builderRef.current) {
      builderRef.current.setOpenState(false)
    }

    const timeout = setTimeout(() => {
      if (builderRef.current) {
        builderRef.current.forceUpdate()
      }
    }, 1500)

    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    builderRef.current?.setOpenState(isOpen)
  }, [isOpen])

  useEffect(() => {
    if (
      !isNil(customerId) &&
      builderRef?.current?.state?.customerId !== customerId
    ) {
      builderRef.current?.papercups.setCustomerId(customerId)
    }
  }, [customerId, builderRef.current?.state])

  useEffect(() => {
    if (
      !isNil(conversationId) &&
      builderRef.current?.state.conversationId !== conversationId
    ) {
      builderRef.current?.papercups.setConversationId(conversationId)
    }
  }, [conversationId, builderRef.current?.state])

  useEffect(() => {
    if (
      messages &&
      messages.length > 0 &&
      builderRef.current &&
      builderRef.current.state.messages.length < messages.length
    ) {
      builderRef.current?.papercups.setMessages(messages)
    }
  }, [messages, builderRef.current?.state])

  return (
    <ChatBuilder
      ref={builderRef}
      config={config}
      debug={getCurrentEnvironment() !== "production"}
      scrollIntoViewOptions={{ behavior: "smooth" }}
      isOpenByDefault={false}
      {...props}
    >
      {({
        config,
        state,
        onClose,
        onSendMessage,
        onToggleOpen,
        scrollToRef,
      }) => {
        return (
          <Chat
            config={config}
            state={state}
            onClose={() => {
              onClose()
              onChatClosed?.()
            }}
            onSendMessage={onSendMessage}
            onToggleOpen={onToggleOpen}
            scrollToRef={scrollToRef}
            presetMessageBody={presetMessageBody}
          />
        )
      }}
    </ChatBuilder>
  )
}

export default Widget

import { useCallback, useEffect, useMemo, useState } from "react"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import { IonProgressBar } from "@ionic/react"

import Modal, { CloseButton, ModalProps } from "../Core/Modal"
import Button from "../Forms/Button"
import { pauseOutline, playOutline } from "ionicons/icons"
import { MovementModality, MovementStyle } from "../../generated/graphql"

import clsx from "clsx"
import isNil from "lodash/isNil"
import { getModalityContrastColor } from "../../utils/movementUtils"
import {
  formatDuration,
  MovementSessionInterval,
  useSessionIntervalStopwatch,
} from "./MovementSessionStopwatch"
import Carousel from "nuka-carousel"
import {
  RiCheckDoubleLine,
  RiRestTimeLine,
  RiSkipForwardLine,
} from "react-icons/ri"
import { GridForm, GridFormSection } from "../Forms/GridForm"
import { MovementStyleGridFormField } from "./Forms/Fields/MovementStyleSelect"

export interface GameplanSessionModalBodyProps {
  gameplan: {
    expectedDuration: number
    duration_metric: "time" | "reps"

    movementModality: MovementModality
    movementStyle: MovementStyle

    circuits: {
      name?: string

      type: "warmup" | "activity" | "cooldown"

      movementModality?: MovementModality
      movementStyle?: MovementStyle

      totalDuration?: number
      repeatCount?: number // -1 means infinite

      interMovementRestTime?: number

      movements: {
        name: string

        duration: number
        load?: number

        restTime?: number

        repeatCount?: number // -1 means infinite
      }[]
    }[]
  }
}

export enum SessionIntervalStatus {
  NotStarted = "not_started",
  Ongoing = "ongoing",
  Completed = "completed",
}

export type SessionInterval = {
  status: SessionIntervalStatus
  type: "active" | "rest" | "warmup" | "cooldown"

  name?: string
  description?: string

  targetSeconds?: number
  targetReps?: number

  autoStart: boolean

  duration: number
}

export interface SessionRoutine {
  duration?: number
  intervals: SessionInterval[]
}

export interface SessionSettingsData {
  title?: string
  description?: string
  movementModality: MovementModality
  movementStyle: MovementStyle
  intervalRestTimes: number
  totalDuration: number
  autoStart: boolean
  warmup?: SessionRoutine
  routine: SessionRoutine
  cooldown?: SessionRoutine
}

export const GameplanSettingsForm: React.FC<any> = ({ settings, onClick }) => {
  const { movementModality } = settings

  return (
    <GridForm<SessionSettingsData>
      defaultValues={settings}
      className={`bg-${movementModality} relative overflow-y-scroll`}
    >
      <GridFormSection>
        <MovementStyleGridFormField name="movementStyle" hidden />

        <GridForm.Submit
          label={"Update Settings"}
          fill="clear"
          className={clsx(`bg-${getModalityContrastColor(movementModality)}`)}
          labelClassName={`text-${movementModality} no-underline`}
          onSubmit={onClick}
        />
      </GridFormSection>
    </GridForm>
  )
}

export const GameplanSession: React.FC = () => {
  const { closeModal, openModal } = useModalOrchestrationContext()

  const settings = {
    name: "Box Breathing",
    movementModality: MovementModality.Breathing,
    movementStyle: MovementStyle.Breathwork,
    intervalRestTimes: 4,
    totalDuration: 60,
    autoStart: false,
    loop: true,
    warmup: {
      duration: null,
    },
    gameplan: {
      duration: null,
      intervals: [
        {
          name: "Inhale",
          type: "active",
          targetSeconds: 4,
        },
        {
          name: "Exhale",
          type: "active",
          targetSeconds: 4,
        },
      ] as SessionInterval[],
    },
    cooldown: {
      duration: null,
    },
  }

  const buildInterval = useCallback(
    (props?: Partial<SessionInterval>): SessionInterval => ({
      name: "Active",
      duration: 0,
      status: SessionIntervalStatus.NotStarted,
      type: "active" as SessionInterval["type"],
      autoStart: true,
      ...props,
    }),
    [settings]
  )

  const buildMoveSetIntervals = useCallback(() => {
    const moveSetIntervals = settings.gameplan.intervals.map(buildInterval)

    if (settings.intervalRestTimes > 0) {
      const restIntervals = moveSetIntervals
        .map(() => {
          return buildInterval({
            name: "Hold",
            type: "rest",
            targetSeconds: settings.intervalRestTimes,
          })
        })
        .filter((x) => !isNil(x)) as SessionInterval[]

      return moveSetIntervals.reduce((acc, interval, index) => {
        acc.push(interval)

        if (restIntervals[index]) {
          acc.push(restIntervals[index])
        }

        return acc
      }, [] as SessionInterval[])
    }

    return moveSetIntervals
  }, [settings])

  const hasGameplan = settings.gameplan.intervals.length > 0

  const [isStarted, setIsStarted] = useState(false)
  const [isPaused, setIsPaused] = useState(true)

  const [intervals, setIntervals] = useState(buildMoveSetIntervals())

  const [selectedIntervalIndex, setSelectedIntervalIndex] = useState(0)
  const [currentIntervalIndex, setCurrentIntervalIndex] = useState(0)
  const currentInterval = intervals[currentIntervalIndex]

  const [currentSessionDuration, setCurrentSessionDuration] = useState(0)

  const handleStopwatchTick = useCallback(() => {
    setIsPaused(false)

    setIntervals((intervals) => {
      if (isNil(currentInterval)) {
        return intervals
      }

      return updateInterval(intervals, currentIntervalIndex, {
        duration: currentInterval.duration + 1,
        status: SessionIntervalStatus.Ongoing,
      })
    })

    setCurrentSessionDuration((currentSessionDuration) => {
      return currentSessionDuration + 1
    })

    if (currentSessionDuration >= settings.totalDuration) {
      completeSession()
    }

    console.debug("[GameplanSessionModal] updateCurrentSessionDuration", {
      currentSessionDuration,
    })
  }, [currentInterval])

  const handleStopwatchGoalReached = useCallback(() => {
    console.debug("[GameplanSessionModal] handleComplete")

    startNextInterval()
  }, [currentInterval])

  const intervalStopwatch = useSessionIntervalStopwatch({
    handleTick: handleStopwatchTick,
    handleComplete: handleStopwatchGoalReached,
    autoStart: false,
  })

  const updateInterval = (
    intervals: SessionInterval[],
    index: number,
    props: Partial<SessionInterval>
  ): SessionInterval[] => {
    if (index < 0 || index >= intervals.length) {
      return intervals
    }

    const interval = intervals[index]

    return [
      ...intervals.slice(0, index),
      {
        ...interval,
        ...props,
      },
      ...intervals.slice(index + 1),
    ]
  }

  const addNewInterval = (
    intervals: SessionInterval[],
    props?: Partial<SessionInterval>,
    index?: number
  ) => {
    const newInterval = buildInterval({
      ...props,
    })

    intervalStopwatch.reset(newInterval)

    if (intervals.length === 0) {
      return [newInterval]
    }

    if (isNil(index)) {
      return [...intervals, newInterval]
    } else {
      return [
        ...intervals.slice(0, index),
        newInterval,
        ...intervals.slice(index),
      ]
    }
  }

  const startNextInterval = useCallback(
    (_props?: Partial<SessionInterval>) => {
      setSelectedIntervalIndex((selectedIndex) => selectedIndex + 1)
      setCurrentIntervalIndex((currentIndex) => currentIndex + 1)

      // set interval status to completed
      setIntervals((intervals) =>
        updateInterval(intervals, currentIntervalIndex, {
          status: SessionIntervalStatus.Completed,
        })
      )

      if (hasGameplan && currentIntervalIndex >= intervals.length - 1) {
        if (settings.loop) {
          setIntervals((intervals) => {
            return [...intervals].concat([...buildMoveSetIntervals()])
          })

          return
        } else {
          completeSession()

          return
        }
      }

      // if (!hasGameplan && currentIntervalIndex >= intervals.length - 1) {
      //   newIntervals = addNewInterval(newIntervals, props)
      // }

      // setIntervals(newIntervals)
    },
    [settings, hasGameplan, currentIntervalIndex, intervals]
  )

  const playStopwatch = () => {
    console.debug("[GameplanSessionModal] start stopwatch")

    if (!isStarted) {
      console.debug("starting session")

      startSession()
    }

    intervalStopwatch.start()

    setIsPaused(false)
  }

  const pauseStopwatch = () => {
    console.debug("[GameplanSessionModal] pause stopwatch")

    intervalStopwatch.pause()

    setIsPaused(true)
  }

  const startSession = useCallback(() => {
    setIsStarted(true)
    let intervalProps = { autoStart: true } as SessionInterval

    if (!isNil(settings.warmup.duration) && settings.warmup.duration > 0) {
      intervalProps = {
        ...intervalProps,
        name: "Warm-Up",
        type: "warmup",
        targetSeconds: settings.warmup.duration,
      }
    }

    startNextInterval(intervalProps)

    setCurrentIntervalIndex(0)
    setSelectedIntervalIndex(0)

    if (!hasGameplan) {
      setIntervals((intervals) => addNewInterval(intervals, intervalProps))
    }
  }, [settings])

  const completeSession = () => {
    closeModal(ModalOrchestrationName.MoveStreamSession)

    openModal(ModalOrchestrationName.PostSessionFeedback, {
      title: settings.name,
      movementModality: settings.movementModality,
      movementStyle: settings.movementModality,
    })
  }

  const handleMainClick = useCallback(() => {
    if (isPaused) {
      completeSession()
    } else {
      startNextInterval()
    }
  }, [currentIntervalIndex, isPaused])

  const { movementModality } = settings
  const isInRest = currentInterval?.type === "rest"

  const color = useMemo(() => {
    if (isInRest) {
      return "neutral"
    } else {
      return movementModality
    }
  }, [isInRest, movementModality])

  const contrastColor = useMemo(() => {
    if (isInRest) {
      return "white"
    } else {
      return getModalityContrastColor(movementModality)
    }
  }, [isInRest, movementModality])

  const textColor = useMemo(() => {
    return `text-${contrastColor}`
  }, [contrastColor])

  const currentSessionProgress = useMemo(() => {
    return currentSessionDuration / settings.totalDuration
  }, [currentSessionDuration, settings.totalDuration])

  useEffect(() => {
    if (!isStarted) return

    if (!isNil(currentInterval)) {
      intervalStopwatch.reset(currentInterval)
    }
  }, [currentIntervalIndex, isStarted])

  useEffect(() => {
    console.debug("[MovementSessionModal] intervals", {
      intervals,
      selectedIntervalIndex,
    })
  }, [intervals])

  return (
    <div
      className={clsx(
        isInRest ? "bg-neutral-500" : `bg-${movementModality}`,
        "h-full w-full",
        isStarted && isPaused && "saturate-50 py-safe"
      )}
    >
      <CloseButton color={contrastColor} />
      <div
        className={clsx(
          "flex flex-col items-center justify-around w-full h-full",
          textColor
        )}
      >
        {!isStarted ? (
          <div
            className={clsx(
              "flex flex-col items-center justify-around w-full h-full"
            )}
          >
            <div className="flex flex-col items-center justify-center">
              <span className="text-5xl font-bold tracking-tight">
                {settings.name}
              </span>
              <span className="text-xl font-semibold tracking-tight">
                {formatDuration({ seconds: settings.totalDuration })}
              </span>
            </div>

            <Button
              icon={playOutline}
              fill="clear"
              color={color}
              iconSlot="icon-only"
              paddingWidth="thin"
              className={clsx(
                "rounded-full aspect-1",
                "shadow-xl",
                isPaused && "pl-2",
                "h-20 w-20",
                `bg-${contrastColor}`
              )}
              iconClassName={clsx("text-5xl md:text-6xl", color)}
              onClick={playStopwatch}
            />
          </div>
        ) : (
          <>
            <div className="absolute flex flex-col items-center justify-center w-full gap-y-8 top-8">
              <Carousel
                wrapAround={false}
                cellAlign="center"
                swiping={false}
                slideIndex={selectedIntervalIndex}
                renderCenterLeftControls={() => <></>}
                renderCenterRightControls={() => <></>}
                renderBottomCenterControls={() => <></>}
              >
                {intervals.map((interval, index) => (
                  <MovementSessionInterval key={index} interval={interval} />
                ))}
              </Carousel>
            </div>

            <div className="absolute flex flex-col items-center justify-center w-full py-4 gap-y-8 bottom-4">
              <div className="flex flex-row items-center justify-around w-full text-4xl">
                <Button
                  icon={isPaused ? playOutline : pauseOutline}
                  fill="clear"
                  color={color}
                  iconSlot="icon-only"
                  paddingWidth="thin"
                  className={clsx(
                    "rounded-full aspect-1",
                    "shadow-xl",
                    isPaused && "pl-2",
                    "h-20 w-20",
                    `bg-${contrastColor}`
                  )}
                  iconClassName={clsx("text-5xl md:text-6xl", color)}
                  onClick={isPaused ? playStopwatch : pauseStopwatch}
                />

                <Button
                  icon={
                    isPaused
                      ? RiCheckDoubleLine
                      : isInRest
                      ? RiSkipForwardLine
                      : RiRestTimeLine
                  }
                  fill="clear"
                  color={color}
                  className={clsx(
                    "saturate-100",
                    "shadow-xl",
                    "rounded-full aspect-1 h-20 w-20",
                    `bg-${contrastColor}`
                  )}
                  iconClassName={clsx("text-5xl md:text-6xl")}
                  iconSlot="icon-only"
                  paddingWidth="thin"
                  onClick={handleMainClick}
                  hidden={!isStarted}
                />
              </div>

              <div
                className={clsx(
                  "flex flex-col items-center justify-center w-full gap-y-4",
                  !isStarted && "hidden"
                )}
              >
                <IonProgressBar
                  color={contrastColor}
                  value={currentSessionProgress}
                  className="h-2"
                />

                <div className="flex flex-row items-center justify-between w-full px-2 gap-x-4">
                  <div className="flex flex-col items-start">
                    <span className="font-semibold opacity-80">Elapsed</span>

                    <span className="text-lg font-bold">
                      {formatDuration({ seconds: currentSessionDuration })}
                    </span>
                  </div>

                  <div className="flex flex-col items-end">
                    <span className="font-semibold opacity-80">Remaining</span>

                    <span className="text-lg font-bold">
                      {currentSessionDuration > settings.totalDuration
                        ? 0
                        : formatDuration({
                            seconds:
                              settings.totalDuration - currentSessionDuration,
                          })}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export const GameplanSessionModal: React.FC<Partial<ModalProps>> = ({
  name = ModalOrchestrationName.MoveStreamSession,
  ...props
}) => {
  return (
    <Modal
      name={name}
      background="primary"
      fullScreen
      keepContentsMounted={false}
      {...props}
    >
      <Modal.Body scrollY={false}>
        {(props: any) => <GameplanSession {...props} />}
      </Modal.Body>
    </Modal>
  )
}

export default GameplanSession
